import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import developerService from "../services/developer.service";

export default function DeveloperPage() {
  const [failureMessage, setFailureMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState("")
  const [developerAccounts, setDeveloperAccounts] = useState([])
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');

  useEffect(() => {
    developerService.getUserDeveloperAccount().then(
      response => {
        if (response.success && response.message) {
          setSuccessMessage(response.message);
        }
        setDeveloperAccounts(response.data.developer_accounts);
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )}, [])

    
    const handleCreateDeveloperAccountClick = (event) => {
      setLoading(true);

      developerService.create(name).then(
        response => {
          setLoading(false);
          setDeveloperAccounts(response.data.developer_account);
        },
        error => {
          setLoading(false);
          setFailureMessage(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
          );
        }
      )
    }

    const handleNameChange = (event) => {
      event.preventDefault();
      setName(event.target.value)
    }

  return (
    <>
      {failureMessage && <div className="alert alert-danger" role="alert">{failureMessage}</div>}
      {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
      {!developerAccounts.length && (
        <div>
          Create Developer Account?<br />
          Name: <input type="text" value={name} onChange={handleNameChange} />
          <div className="mb-3">
              <button
                className="btn btn-primary btn-block"
                disabled={loading}
                onClick={handleCreateDeveloperAccountClick}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Yes</span>
              </button>
            </div>
        </div>
      )}
      {developerAccounts.length > 0 && developerAccounts.map((developerAccount) => {
        return (
          <div key={developerAccount.name}>
            <h2>{developerAccount.name}</h2>
            <h4>Applications</h4>
            <ul>
            {developerAccount.YhpApplications &&
              developerAccount.YhpApplications.map((yhpApplication, index) => 
              <li key={index}><Link to={`/developer/applications/${yhpApplication.id}`} >{yhpApplication.name}</Link></li>)}
            </ul>
            <a className="btn btn-primary" href="/developer/applications/add" role="button">
              Add
            </a>
          </div>)}
        )
      }
    </>
  )
}