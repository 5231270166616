import api from '../interceptors/auth.interceptor';

class UserConfigurationService {
  get() {
    return api.get(process.env.REACT_APP_API_URL + `/api/user/configuration`)
  }

  create(data) {
    return api.post(process.env.REACT_APP_API_URL + `/api/user/configuration`, {configuration: data})
  }

  update(groupId, data) {
    return api.put(process.env.REACT_APP_API_URL + `/api/user/configuration`, {
      group_id: groupId,
      configuration: data
    })
  }
}

const userConfigurationService = new UserConfigurationService();
export default userConfigurationService;