import { useState } from 'react';

import Alert from './alert.component';
import EntriesSheet from './entries-sheet.component';

export default function ResultsPage() {

  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });

  const setFailureMessage = (message) => {
    setAlert(alert => ({...alert, 
      show: true,
      alertType: 'danger',
      alertTitle: 'Error',
      alertMessage: message
    }));
  }

  const setSuccessMessage = (message) => {
    setAlert(alert => ({...alert, 
      show: true,
      alertType: 'success',
      alertTitle: 'Success',
      alertMessage: message
    }));
  };

  return (
    <>
      <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />
      <a className="btn btn-primary me-3" href="/results/add" role="button">
        Add
      </a>
      <a className="btn btn-secondary" href="/results/upload_csv" role="button">
        Upload CSV
      </a>
      <a className="btn btn-secondary" href="/results/upload_results_pdf" role="button">
        Upload Results PDF 
      </a>
      <EntriesSheet 
        setErrorMessage={setFailureMessage}
        setSuccessMessage={setSuccessMessage}
      />
    </>
  )
}