import api from '../interceptors/auth.interceptor';

class UserEntriesService {
  getEntriesDefinitions() {
    return api.get(process.env.REACT_APP_API_URL + `/api/user/entries/definitions`);
  }

  getAll() {
    return api.get(process.env.REACT_APP_API_URL + `/api/user/entries`)
  }

  create(data) {
    return api.post(process.env.REACT_APP_API_URL + `/api/user/entries`, {entry: data})
  }

  update(id, data) {
    return api.put(process.env.REACT_APP_API_URL + `/api/user/entries/${id}`, {entry: data})
  }

  uploadCSV(formData) {
    return api.post(process.env.REACT_APP_API_URL + `/api/user/entries/files/csv`, formData,
     {headers: {
      "Content-Type": "multipart/form-data",
    }});
  }

  mergeEntriesByDefinitions(mergeFromDefinitionIds, mergeIntoDefinitionId) {
    return api.post(process.env.REACT_APP_API_URL + `/api/user/entries/merge/definitions`, 
      {merge_from_definition_ids: mergeFromDefinitionIds, 
        merge_into_definition_id: mergeIntoDefinitionId})
  }

  uploadPDF(data) {
    return api.post(process.env.REACT_APP_API_URL + `/api/user/entries/files/pdf`, data,
     {headers: {
      "Content-Type": "multipart/form-data",
    }});
  }
}

const userEntriesService = new UserEntriesService();
export default userEntriesService;