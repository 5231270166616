import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import categoriesService from "../services/categories.service";
import userService from "../services/user.service";
import userCategoriesService from '../services/user-categories.service';
import authService from '../services/auth.service';

export default function CategoriesPage() {
  const [failureMessage, setFailureMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState("")
  const [loading, setLoading] = useState(false);
  const [userCategories, setUserCategories] = useState([])
  const [allCategories, setAllCategories] = useState([])

  const currentUser = authService.getCurrentUser();

  useEffect(() => {
    Promise.all([
      userService.getCategories(),
      categoriesService.getAll()
    ]).then(
      responses => {
        setUserCategories(responses[0].data.categories);
        setAllCategories(responses[1].data.categories)
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )}, [])

  

  const removeCategory = (categoryId) => {
    setFailureMessage("")
    setSuccessMessage("")
    setLoading(true);
    userCategoriesService.remove(categoryId).then(
      response => {
            
            setUserCategories(response.data.categories)
            setLoading(false);
            setSuccessMessage(response.successMessage);
      }, error => {
            setFailureMessage(
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString()
            );
            setLoading(false);
      }
    )
  }

  const deleteCategory = (categoryId) => {
    setFailureMessage("")
    setSuccessMessage("")
    setLoading(true);
    categoriesService.destroy(categoryId).then(
      response => {
        userService.getCategories().then(
          response => {
            
            setUserCategories(response.data.categories)
            setLoading(false);
            setSuccessMessage(response.successMessage);
          },
          error => {
            setFailureMessage(
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString()
            );
            setLoading(false);
          })
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
        setLoading(false);
      }
    )
  }

  const addCategory = (categoryId) => {
    setFailureMessage("")
    setSuccessMessage("")
    setLoading(true);
    userCategoriesService.add(categoryId).then(
      response => {
        setUserCategories(response.data.categories)
        setLoading(false);
        setSuccessMessage(response.message)
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
        setLoading(false);
      }
    )
  }

  return (
    <>
      {failureMessage && <div className="alert alert-danger" role="alert">{failureMessage}</div>}
      {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2">Category</div>
          <div className="col-sm-1">Delete</div>
          <div className="col-sm-1">Installed?</div>
          <div className="col-sm-2">Publisher</div>
        </div>
      {allCategories && userCategories && allCategories.map((category) => {
        const installed = !!(userCategories.find((userCategory) => userCategory.name === category.name))
        return (
        <div className="row">
          <div className="col-sm-2"><Link to={`/categories/${category.id}`} >{category.name}</Link></div>
          
          <div className="col-sm-1">
            {category.publisher_id === currentUser.id ? <i className="bi bi-trash" onClick={(e) => {deleteCategory(category.id)}}></i> : ""}
          </div>
          <div className="col-sm-1">{ installed ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-check" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
  <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
  <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"/>
</svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-x" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M6.146 6.146a.5.5 0 0 1 .708 0L8 7.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 8l1.147 1.146a.5.5 0 0 1-.708.708L8 8.707 6.854 9.854a.5.5 0 0 1-.708-.708L7.293 8 6.146 6.854a.5.5 0 0 1 0-.708"/>
  <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
  <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"/>
</svg>}
          </div>
          
          <div className="col">
            <Link to={`/users/${category.publisher.username}`}>{category.publisher.username}</Link>
          </div>
          <div className="col">
            {installed ? 
              <button type="button" key={`remove-button-${category.name}`} className="btn btn-primary" onClick={() => {removeCategory(category.id)}}>
                Remove {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
              </button> :
              <button type="button" key={`add-button-${category.name}`} className="btn btn-primary" onClick={() => {addCategory(category.id)}}>
                Add {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
              </button>}
              <a href={`/share/category/${category.name}`} className="btn btn-info" key={`share-button-${category.name}`}>
                Share {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
              </a>
          </div>
        </div>
        )
      })}
      <a className="btn btn-secondary" href={`/categories/add`} role="button">Add Category</a>
      </div>
    </>
  )
}