import { useState } from 'react';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";

import applicationService from '../services/application.service';

export default function AddApplicationPage() {
  const [application, setApplication] = useState();
  const [name, setName] = useState('');
  const [rules, setRules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  let form;

  const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };

  const onChangeName = (e) => {
    setName(e.target.value)
  }
  
  const onChangeApplicationRule = (e) => {
    try {
      JSON.parse(e.target.value)
      setErrorMessage('');
    } catch(error) {
      setErrorMessage('JSON is not valid');
    }
    setRules(e.target.value);
  }

  const handleSubmit= (e) => {
    e.preventDefault();

    setErrorMessage("")
    setSuccessMessage("")
    setLoading(true);

    form.validateAll();

    applicationService.create(name, JSON.parse(rules)).then(
      (response) => {
        if (response.data.success && response.data.message) {
          setSuccessMessage(response.data.message)
        }
        setLoading(false);
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setErrorMessage(resMessage);
        });
    }

  return (
    <>
      {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
      {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
          <div>
            <h2>Create Application</h2>
            <Form onSubmit={handleSubmit}
              ref={c => {
               form = c;
              }}>

            <div className="mb-3">
              <label htmlFor="name">Name</label>
              <Input
                type="text"
                className="form-control"
                name="name"
                onChange={onChangeName}
                value={name}
                validations={[required]}
              />
            </div>

            <div className="mb-3">
              {errorMessage && (<div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>)}
              <label htmlFor="rules">Rules</label>
              <textarea
                type="text"
                className="form-control"
                name="rules"
                value={rules}
                onChange={onChangeApplicationRule}
                validations={[required]}
                rows={20}
              />
            </div>

            <div className="mb-3">
              <button
                className="btn btn-primary btn-block"
                disabled={loading}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Submit</span>
              </button>
            </div>

            {errorMessage && (
              <div className="mb-3">
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              </div>
            )}
{successMessage}
            {successMessage && (
              <div className="mb-3">
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              </div>
            )}


            </Form>
          </div>
    </>
  )
}