import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import Alert from './alert.component';

import userService from "../services/user.service";
import userInterventionService from "../services/user-intervention.service";

export default function InterventiosPage() {
  const [userInterventions, setUserInterventions] = useState([])
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });

  let { interventionUuid } = useParams();

  useEffect(() => {
    setLoading(true);

    userInterventionService.getAll().then(
      response => {
        setLoading(false);
        setUserInterventions(response.data.interventions);
      },
      error => {
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertMessage: (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }));
      }
    )}, [interventionUuid])

  const removeIntervention = (interventionUuid) => {
    setAlert(alert => ({...alert, show: false}));
    setLoading(true);
    userService.removeIntervention(interventionUuid).then(
      response => {
        setUserInterventions(response.data.interventions)
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'success',
          alertTitle: 'Success',
          alertMessage: response.message
        }));
      },
      error => {
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertTitle: 'Error',
          alertMessage: (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }));
      }
    )
  }

  const addIntervention = (interventionUuid) => {
    setAlert(alert => ({...alert, show: false}));
    setLoading(true);

    userService.addIntervention(interventionUuid).then(
      response => {
        setUserInterventions(response.data.interventions)
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'success',
          alertTitle: 'Success',
          alertMessage: response.message
        }));
      },
      error => {
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertTitle: 'Error',
          alertMessage: (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }));
      }
    )
  }

  return (
    <>
      <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />
      {loading && (
        <span className="spinner-border spinner-border-sm"></span>
      )}
      <div className="container">
        <div className="row">
          <div className="col-sm-2">Intervention</div>
          <div className="col-sm-2">View</div>
          <div className="col-sm-2">Started</div>
          <div className="col-sm-2">Ended</div>
        </div>
      {userInterventions.length > 0 && userInterventions.map((intervention) => {
        return (
        <div className="row">
          <div className="col-sm-2"><Link to={`/interventions/${intervention.uuid}`} >{intervention.name}</Link></div>
          <div className="col">
            <Link to={`/views/${intervention.view.uuid}`}>{intervention.view.name}</Link>
          </div>
          <div className="col">
            {intervention.started_at}
          </div>
          <div className="col">
            {intervention.ended_at}
          </div>
        </div>
        )
      })}
      <a className="btn btn-secondary" href={`/interventions/add`} role="button">Add Intervention</a>
      </div>
    </>
  )
}