import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ReferenceLine, ReferenceArea  } from 'recharts';
import moment from 'moment';
import { useState, useEffect } from 'react';

import InsightsData from './insights-data.component';

import capitalizeFirstLetter from '../utils/capitalize-first-letter';
import LevelsProcessor from '../utils/levelsProcessor';

export default function TrackingChart({definition, group, title, data, boundary, dateRange}) {

  const [YAxisMin, setYAxisMin] = useState(0);
  const [YAxisMax, setYAxisMax] = useState(100);
  const [localData, setLocalData] = useState([]);

  useEffect(() => {
    let min, max, localData = structuredClone(data);
    for (const datum of localData) {
      datum.value = parseFloat(datum.value);

      if (isNaN(datum.value)) continue;

      if (!min) min = datum.value;
      if (!max) max = datum.value;

      if (min > datum.value) min = datum.value;
      if (min > datum.min) min = datum.min;

      if (max < datum.value) max = datum.value;
      if (max < datum.max) max = datum.max;
    }

    if (boundary && boundary.group_definitions.levels) {
      const levelsProcessor = new LevelsProcessor(boundary.group_definitions.levels);
      levelsProcessor.process();
      min = Math.min(levelsProcessor.min, min);
      max = Math.max(levelsProcessor.max, max);
    }

    let range = max-min;
    if (range === 0) {
      range = max / 2;
    }
    setYAxisMax(parseFloat(max) + (range*1.12));
    setYAxisMin(parseFloat(min) - (range*1.12));
    setLocalData(localData);
  }, [data, boundary, title, dateRange])
  
  const formatXAxis = (tickItem) => { 
    return moment(tickItem).format('MMM Do YY') 
  }

  return (
    <div className="col-md-12 mb-3">
      <div className="row">
        <h4>{capitalizeFirstLetter(title)}</h4>
        <div className="card col-md-6">
          <LineChart width={400} height={400} data={localData}>
            <Line key={title} type="monotone" dataKey="value" stroke="#8884d8" />
            {boundary && boundary.group_definitions ? (
              <>
              {
                boundary.group_definitions.levels ? Object.keys(boundary.group_definitions.levels).map((levelKey, index) => (
                  <ReferenceLine 
                    key={levelKey}
                    label={boundary.group_definitions.levels[levelKey].label || levelKey} 
                    strokeWidth={2} 
                    y={boundary.group_definitions.levels[levelKey].value} 
                    stroke={boundary.group_definitions.levels[levelKey].color || 'Red'} 
                    strokeDasharray="3 3" />
                )) : null
              }
            </>)
            : null
            }
            {
              dateRange ? (
                <>
                <ReferenceArea 
                  x1={dateRange.started_at} 
                  x2={dateRange.ended_at} 
                  y1={YAxisMin} 
                  y2={YAxisMax} 
                  // label="Intervention"
                  stroke="red" fillOpacity={0.2} />
                </>
              ) : null
            }
            <CartesianGrid stroke="#ccc" />
            
            <Tooltip
              labelFormatter={(value, name, props) => {
                return moment(value).format(moment.localeData().longDateFormat('L'));
              }} />
            {/* <Legend /> */}
            <XAxis dataKey="date" tickFormatter={formatXAxis}/>
            <YAxis domain={[YAxisMin, YAxisMax]}
              scale="linear"
              interval="preserveEnd"/>
          </LineChart>
          <div className="card-body">
            <p className="card-text"></p>
          </div>
        </div>
        <div className="card col-md-6">
          <InsightsData definition={definition} group={group} data={localData} boundary={boundary} />
        </div>
      </div>
    </div>
  )
}