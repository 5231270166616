import { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from "react-router-dom";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";

import CategoriesList from "./categories-list.component"

import viewService from '../services/view.service';
import usersService from '../services/users.service';

export default function ViewPage() {

  const navigate = useNavigate();

  const [newViewFlag, setNewViewFlag] = useState(false);
  const [view, setView] = useState();
  const [name, setName] = useState('');
  const [published, setPublished] = useState(false);
  const [categories, setCategories] = useState([]);
  const [readOnly, setReadOnly] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  let { viewUuid } = useParams();
  let { userName } = useParams();

  useEffect(() => {
    if (!viewUuid) {
      if (userName) {
        setReadOnly(true);
        setErrorMessage('Wrong URL. Please use the menu to navigate to the view you want to see.');
        return;
      }
      setView({
        name: '',
        published: false
      });
      setName('');
      setPublished(false);
      setReadOnly(false);
      setNewViewFlag(true);
      return;
    }

    let action;
    if (userName) {
      action = usersService.getView(userName, viewUuid)
    } else {
      action = viewService.get(viewUuid)
    }
    action.then(
      response => {
        const view = response.data.view;
        setView(view);
        setName(view.name);
        setPublished(view.published);
        setCategories(view.categories)
        setReadOnly(userName || view.published || view.publisher_id !== JSON.parse(localStorage.getItem('user')).id);
      },
      error => {
        setErrorMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )}, [viewUuid])

  const handlePublishedClick = (event) => {
    if (readOnly) return false;
    setPublished(!published)
  }

  const onDuplicateClicked = (e) => {
    e.preventDefault();

    setErrorMessage("")
    setSuccessMessage("")
    setLoading(true);

    viewService.duplicateView(viewUuid).then(
      
      (response) => {
        if (response.data.success) {
          setSuccessMessage()
          navigate(`/dashboard/${response.data.view.name}`, {state: {message: response.data.message}});
        }
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setErrorMessage(resMessage);
        });
  }

  const handleNameChange = (event) => {
    event.preventDefault();
    setName(event.target.value)
  }

  const handleSubmit= (e) => {
    e.preventDefault();

    if (!name) {
      setErrorMessage("Name is required");
      return;
    }

    setErrorMessage("")
    setSuccessMessage("")

    setLoading(true);

    let remoteAction;

    if (newViewFlag) {
      const newView = {
        name: name,
        published: published,
        categories: categories
      }
      remoteAction = viewService.create(newView);
    } else {
      remoteAction = viewService.update(name, {categories: categories, published: published});
    }

    remoteAction.then(
      (response) => {
        if (newViewFlag) {
          navigate(`/views/${response.data.view.uuid}`, {state: {message: response.data.message}});
        }
        if (response.data.success && response.data.message) {
          setSuccessMessage(response.data.message)
        }
        const view = response.data.view;
        setView(view);
        setName(view.name);
        setLoading(false);
        setPublished(view.published);
        setCategories(view.categories)
        setReadOnly(view.published || view.publisher_id !== JSON.parse(localStorage.getItem('user')).id);
        setNewViewFlag(false);
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setErrorMessage(resMessage);
        });
  }

  // const onImportDefinitionsClicked = (e) => {
  //   e.preventDefault();

  //   setErrorMessage("")
  //   setSuccessMessage("")
  //   setLoading(true);

  //   viewService.importDefinitionsFromCategories(viewUuid).then(
  //     (response) => {
  //       if (response.data.success) {
  //         setSuccessMessage()
  //       }
  //       setView(response.data.view);

  //     },
  //     error => {
  //       const resMessage =
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //         error.message ||
  //         error.toString();

  //       setLoading(false);
  //       setErrorMessage(resMessage);
  //       });
  // }

  return (
    <>
      {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
      {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
      {readOnly && <div className="alert alert-info">This view is already published and so is in read only mode</div>}
      {view &&
         (
          <div key={view.name} className='col-xs-12 col-sm-12'>
            <h2>{view.name}</h2>
            <Form onSubmit={handleSubmit} className='form-horizontal'>

              <div className="form-view">
                <div className="col-xs-3 text-right">
                  <label htmlFor="name">Name</label>
                </div>
                <div className="col-xs-9">
                  <Input
                    type="text"
                    className="form-control"
                    name="name"
                    value={name}
                    readOnly={readOnly}
                    onChange={handleNameChange}
                  />
                  {view && view.publisher && (<span>by <Link to={`/profiles/${view.publisher.username}`}>{view.publisher.username}</Link></span>)}
                </div>
              </div>


              {!newViewFlag && 
              <>
                <div className="form-view mb-3 mt-3">
                  <h3>Categories</h3>
                  <CategoriesList categories={categories} baseUrl={userName ? `/users/${userName}` : ''} />
                  {!readOnly && <a className="btn btn-secondary" href={`/views/${view.uuid}/categories/`} role="button">Add Category</a>}
                </div>

                <div className="form-check">
                  <input className="form-check-input" 
                    name="published" 
                    type="checkbox" 
                    value={published}
                    onClick={handlePublishedClick} 
                    checked={published}
                    onChanged={handlePublishedClick}
                    readOnly={readOnly} />
                  <label htmlFor="published">Published</label>
                </div>
              </>}

              <div className="btn-toolbar" role="toolbar">
                <div className="btn-view mr-2" role="view">
                  <button
                    className="btn btn-secondary"
                    disabled={loading || newViewFlag}
                    onClick={onDuplicateClicked}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Duplicate</span>
                  </button>
                </div>

                <div className="btn-view mr-2" role="view">
            
                  <button
                    className="btn btn-primary"
                    disabled={loading || readOnly}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Submit</span>
                  </button>
                </div>
              </div>
            </Form>
          </div>)}
    </>
  )
}