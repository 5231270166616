import api from '../interceptors/auth.interceptor';

class UsersAccessRightsService {

  getAllGivenToMe() {
    return api.get(process.env.REACT_APP_API_URL + `/api/access-rights/to-me`);  
  }

  getAllGivenByMe() {
    return api.get(process.env.REACT_APP_API_URL + `/api/access-rights/by-me`);  
  }

  getGivenToMe(userName) {
    return api.get(process.env.REACT_APP_API_URL + `/api/access-rights/to-me/${userName}`);  
  }

  get(tagName) {
    return api.get(process.env.REACT_APP_API_URL + `/api/access-rights/tags/${tagName}`);
  }
  
  create(userName, accessRightsRules, tag) {  
    return api.post(process.env.REACT_APP_API_URL + `/api/access-rights`, {
      user_name: userName,
      access_rights: {
        tag: tag,
        rules: accessRightsRules
      }
    });
  }
}

const usersAccessRightsService = new UsersAccessRightsService();
export default usersAccessRightsService;