import api from '../interceptors/auth.interceptor';

class ApplicationAlertService {
  getAll() {
    return api.get(process.env.REACT_APP_API_URL + '/api/applications/alerts');
  }

  getAllUnread() {
    return api.get(process.env.REACT_APP_API_URL + '/api/applications/alerts/unread');
  }
}

const applicationAlertService = new ApplicationAlertService();
export default applicationAlertService;