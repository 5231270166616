import api from '../interceptors/auth.interceptor';

class UserInterventionService {
  getAll() {
    return api.get(process.env.REACT_APP_API_URL + '/api/user/interventions');
  }

  get(interventionUuid) {
    return api.get(process.env.REACT_APP_API_URL + `/api/user/interventions/${interventionUuid}`);
  }

  create(intervention) {
    return api.post(process.env.REACT_APP_API_URL + '/api/user/interventions', {
      intervention: intervention
    });
  }

  update(interventionUuid, intervention) {
    return api.put(process.env.REACT_APP_API_URL + `/api/user/interventions/${interventionUuid}`, {
      intervention: intervention
    });
  }

}

const userInterventionService = new UserInterventionService();
export default userInterventionService;