import axios from 'axios';
import authHeader from './auth-header';
import api from '../interceptors/auth.interceptor';

class UserApplicationsService {

  getAll() {
    return api.get(process.env.REACT_APP_API_URL + '/api/user/applications');
  }

  add(appId) {
    return api.put(process.env.REACT_APP_API_URL + `/api/user/applications/${appId}`);
  }

  remove(applicationId) {
    // doesn't work for now as the api require name
    throw new Error('Not implemented');
    // return api.delete(process.env.REACT_APP_API_URL + `/api/user/applications/${applicationId}`);
  }
}

const userApplicationsService = new UserApplicationsService();
export default userApplicationsService;