import { useState, useEffect } from 'react';

import userEntriesService from '../services/user-entries-service';

import addTitleToDefinition from '../utils/add-title-to-definition';


export default function DefinitionSelector({defaultTitle = "Choose Markers", 
  setErrorMessage, 
  setSuccessMessage, 
  setLoading=null,
  onDefinitionsPicked, 
  entriesDefinitions = null,
  initialValue = [], 
  allowChooseAll=true,
  allowFilter=false,
  allowOnlySingleSelection=false,
  }
) {
  const [title, setTitle] = useState(defaultTitle);
  const [allDefinitions, setAllDefinitions] = useState([])
  const [filteredDefinitions, setFilteredDefinitions] = useState([]);
  const [chosenDefinitions, setChosenDefinitions] = useState(JSON.parse(JSON.stringify(initialValue)))

  useEffect(() => {
    setTitle(defaultTitle);
  }, [defaultTitle]);

  useEffect(() => {
    if (onDefinitionsPicked) {
      onDefinitionsPicked(chosenDefinitions);
    }
  }, [chosenDefinitions])

  useEffect(() => {
    let action;

    if (entriesDefinitions) {
      action = Promise.resolve({data: {definitions: entriesDefinitions}});
    } else {
      action = userEntriesService.getEntriesDefinitions();
    }

    action.then(
      response => {
        const localAllDefinitions = response.data.definitions.map((definition) => ({...addTitleToDefinition(definition)}));
        setAllDefinitions(localAllDefinitions);
        setFilteredDefinitions(localAllDefinitions);
      },
      error => {
        setErrorMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )
    
  }, [])

  const onCheckedDefinition = (event) => {
    event.preventDefault();
    const definitionId = parseInt(event.target.value);
    const definition = allDefinitions.find((def) => def.id === definitionId);
    if (definition) {
      if (event.target.checked) {
        if (allowOnlySingleSelection) {
          setChosenDefinitions([definition])
        } else {
          setChosenDefinitions([...chosenDefinitions, definition]);
        }
      } else {
        setChosenDefinitions(chosenDefinitions.filter((def) => def.id !== definition.id));
      }
    }
  }

  const isChecked = (definition) => {
    return chosenDefinitions.some((chosenDefinition) => chosenDefinition.id === definition.id);
  }

  const setAutoComplete = (e) => {
    if (allDefinitions.length === 0) return;

    if (setLoading) {
      setLoading(true);
    }

    let text = e.target.value.toLowerCase();
    if (text === '') {
      setFilteredDefinitions(JSON.parse(JSON.stringify(allDefinitions)))
      if (setLoading) {
        setLoading(false);
      }
      return;
    }
    const localFilteredDefinitions = allDefinitions.filter((definition) => definition.name.toLowerCase().includes(text))
      // .reduce((cur, key) => { return Object.assign(cur, { [key]: allDefinitions[key] })}, {});
    setFilteredDefinitions(localFilteredDefinitions || [] );
    if (setLoading) {
      setLoading(false);
    }
  }

  return (
    <div>
      <div className="bg-white border-bottom-0 h4 font-weight-light">{title}</div>
      {allowFilter && 
      <div className="input-group mb-3">
        <input type="text" className="form-control" placeholder="Filter" aria-label="Filter" aria-describedby="Filter Definitions" 
          onChange={setAutoComplete}
          />
      </div>
      }
      {allowChooseAll && 
        <div className="input-group mb-3">
          <input className="form-check-input me-1" type="checkbox" value="" id="chooseAll" onChange={(event) => {
            if (event.target.checked) {
              setChosenDefinitions(filteredDefinitions);
            } else {
              setChosenDefinitions([]);
            }
          }} checked={chosenDefinitions.length === filteredDefinitions.length} />
          Choose All
        </div>
      }
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              
            </th>
            <th></th>
          </tr>
          <tr>
            <th scope="col">Select</th>
            <th scope="col">Definition</th>
          </tr>
        </thead>
        <tbody>
          {filteredDefinitions.length > 0 && filteredDefinitions.map((definition) => (
          <tr key={definition.id}>
            <td className="col-1">
              <input className="form-check-input" key={`select-${definition.id}`} 
                type="checkbox" 
                value={definition.id} 
                id={definition.id} 
                onChange={onCheckedDefinition} 
                checked={isChecked(definition)}
                /> 
            </td>
            <td>
              {definition.title}
            </td>
          </tr>
          ))}
        </tbody>
      </table>
   </div>
  )
}