import api from '../interceptors/auth.interceptor';

class InviteService {
  get(inviteUuid) {
    return api.get(process.env.REACT_APP_API_URL + `/api/invites/${inviteUuid}`)
  }

  getAll() {
    return api.get(process.env.REACT_APP_API_URL + `/api/invites`)
  }

  getAllInvitedMe() {
    return api.get(process.env.REACT_APP_API_URL + `/api/invites/me`)
  }

  getInviteFlags() {
    return api.get(process.env.REACT_APP_API_URL + `/api/invites/flags`)
  }

  create(invitationData) {
    const rules = invitationData.rules;
    for (const rule of Object.keys(rules)) {
      invitationData[rule] = rules[rule];
    }
    return api.post(`${process.env.REACT_APP_API_URL}/api/invites`, {
      invitation: invitationData
    })
  }
}


const inviteService = new InviteService();
export default inviteService;