import api from '../interceptors/auth.interceptor';

class DefinitionEntriesService {
  getAll(definitionsNames) {
    return api.get(process.env.REACT_APP_API_URL + `/api/definitions/entries/`, {params: definitionsNames});
  }

  getAllByName(userName, definitionsNames) {
    return api.get(process.env.REACT_APP_API_URL + `/api/users/${userName}/definitions/entries/`, {params: definitionsNames});
  }
  
}


const definitionEntriesService = new DefinitionEntriesService();
export default definitionEntriesService;