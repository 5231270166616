import ReactGA from 'react-ga';

import React, { Component } from "react";
import { Routes, Route, Link } from "react-router-dom";

import Dropdown from 'react-bootstrap/Dropdown';
import 'bootstrap/dist/js/bootstrap.bundle';
import "bootstrap/dist/css/bootstrap.min.css";
import "./bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import "./App.css";
// import "./css/custom.css";

import authService from "./services/auth.service";
import usersAccessRightsService from "./services/user-access-rights.service";
import { withRouter } from './common/with-router';

import VerifyEmail from "./components/verify-email.component";
import ResetPassword from "./components/reset-password.component";
import Login from "./components/login.component";
import Register from "./components/register.component";
import DeveloperPage from "./components/developer-page.component";
import ResultsPage from "./components/results.component";
import AddResult from "./components/add-result.component";
import UploadResultsCSV from "./components/upload-results-csv.component.js";
import UploadResultsPDF from "./components/upload-results-pdf.component.js";
import ApplicationPage from "./components/application-page.component";
import ApplicationsPage from "./components/applications-page.component.js"
import GroupsPage from "./components/groups-page.component"
import CategoriesPage from "./components/categories-page.component"
import GroupDefinitions from "./components/group-definitions.component"
import CategoryPage from "./components/category-page.component"
import DeveloperApplicationPage from "./components/developer-application-page.component"
import GroupPage from "./components/group-page.component"
import GroupRulePage from './components/group-rule.component';
import ViewCategories from './components/view-categories.component'
import ViewsPage from "./components/views-page.component";
import ViewPage from "./components/view-page.component";
import AllGroupDefinitions from './components/all-group-definitions.component'
import AddApplicationPage from "./components/add-application-page.component"
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin from "./components/board-admin.component";
import UserProfile from './components/user-profile.component';
import InviteUsers from "./components/invite-users.component";
import GiveAccess from "./components/give-access.component";
import AddInvite from "./components/add-invite.component";
import ShareItem from "./components/share-item.component";
import SharePage from "./components/share-page.component";
import PublicSharePage from "./components/public-share-page.component"
import AccessRightsTags from "./components/access-rights-tags.component";
import OtherUser from "./components/other-user.component";
import InterventionPage from "./components/intervention-page.component";
import InterventionsPage from "./components/interventions-page.component";
import MissingTestsPage from "./components/missing-tests-page.component.js"

ReactGA.initialize('G-WNTK71HZG9');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
    this.onImpersonateClicked = this.onImpersonateClicked.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      showImpersonate: false,
      currentUser: undefined,
      impersonateUserData: {},
      accessRightsTags: [],
    };
  }

  componentDidMount() {
    const user = authService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
        showImpersonate: user.roles.includes("ROLE_ADMIN") && user.email === 'lior@sion.co.il' && user.username === 'liorsion',
      });
      usersAccessRightsService.getAllGivenToMe().then(
        response => {
          const accessRights = response.data.access_rights;
          if (accessRights) {
            const tags = accessRights.map((accessRight) => accessRight.tag);
            this.setState({accessRightsTags: tags})
          }
          
        },
        error => {
          this.setState({
            failureMessage:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
          });
        }
      )
    }
  }

  onImpersonateClicked() {
    authService.impersonate(this.state.currentUser.access_token, this.state.impersonateUserData).then(
      response => {
        this.props.router.navigate("/user");
        window.location.reload();
      },
      error => {
        this.setState({
          failureMessage:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
  )}

  logOut() {
    authService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      showImpersonate: false,
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser, showModeratorBoard, showAdminBoard, showImpersonate } = this.state;

    return (
      <div className="container-fluid abg-light bg-gradient">
        {this.state.failureMessage && <div className="alert alert-danger" role="alert">{this.state.failureMessage}</div>}
        <nav className="navbar navbar-expand bg-primary">
          <div className="container-fluid">
            <Link to={"/"} className="navbar-brand" style={{color:'white'}}>
              YourHealthPlatform
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {showModeratorBoard && (
                  <li className="nav-item">
                    <Link to={"/mod"} className="nav-link">
                      Moderator Board
                    </Link>
                  </li>
                )}

                {showAdminBoard && (
                  <li className="nav-item">
                    <Link to={"/admin"} className="nav-link">
                      Admin Board
                    </Link>
                  </li>
                )}

                {showImpersonate && (
                  <li className="nav-item">
                    <input 
                      type="text" 
                      className="form-control" 
                      placeholder="Impersonate User"
                      onChange={(e) => this.setState({impersonateUserData: e.target.value})} 
                    />
                    <button className="nav-link" onClick={this.onImpersonateClicked}>
                      Impersonate
                    </button>
                  </li>
                )}

                {currentUser && (
                  <li className="nav-item">
                    <Link to={"/user"} className="nav-link">
                      You
                    </Link>
                  </li>
                )}
                
                {currentUser && this.state.accessRightsTags.length > 0 && (
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      Other Users
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="my-sm-0">
                      {this.state.accessRightsTags.map((tag, index) => (
                          <Dropdown.Item key={`accessRightsTag-${index}`} eventKey={index} href={`/users/tag/${tag}`}>{tag}</Dropdown.Item>
                        ))
                      }
                      
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </ul>

              <ul className="navbar-nav">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {currentUser && currentUser.username}
                    {!currentUser && "User"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="my-sm-0">
                  {currentUser ? (
                    <>
                    <Dropdown.Item eventKey="1" href="/profile">Profile</Dropdown.Item>
                    <Dropdown.Item eventKey="views" href="/views">My Views</Dropdown.Item>
                    <Dropdown.Item eventKey="interventions" href="/interventions">My Interventions</Dropdown.Item>
                    <Dropdown.Item eventKey="2" href="/groups">My Groups</Dropdown.Item>
                    <Dropdown.Item eventKey="3" href="/applications">My Applications</Dropdown.Item>
                    <Dropdown.Item eventKey="4" href="/categories">My Categories</Dropdown.Item> 
                    <Dropdown.Item eventKey="5" href="/results">My Results</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item eventKey="6" href="/developer">Developer</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item eventKey="7" href="/login" onClick={this.logOut}>Logout</Dropdown.Item>
                    </>
                  ) : (
                    <>
                    <Dropdown.Item eventKey="1" href="/login" onClick={this.logOut}>Login</Dropdown.Item>
                    <Dropdown.Item eventKey="2"  href="/register" onClick={this.logOut}>Sign up</Dropdown.Item>
                    </>
                  )
                  } 
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </div>
          </div>
        </nav>

        <div className="container-fluid mt-3">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/verify-email/:token" element={<VerifyEmail />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/login" element={<Login />} />
            <Route path="/accept-invite/:inviteUuid" element={<Register />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/developer" element={<DeveloperPage />} />
            <Route path="/results/upload_csv" element={<UploadResultsCSV />} />
            <Route path="/results/upload_results_pdf" element={<UploadResultsPDF />} />
            <Route path="/results/add" element={<AddResult />} />
            <Route path="/results" element={<ResultsPage />} />
            <Route path="/user/definitions/:definitionName" element={<BoardUser />} />
            <Route path="/user/views/:viewUuid" element={<BoardUser />} />
            <Route path="/user" element={<BoardUser />} />
            <Route path="/invites/add" element={<AddInvite />} />
            <Route path="/give-access" element={<GiveAccess />} />
            <Route path="/invite-users" element={<InviteUsers />} />
            <Route path="/mod" element={<BoardModerator />} />
            <Route path="/admin" element={<BoardAdmin />} />
            <Route path="/developer/applications/add" element={<AddApplicationPage />} />
            <Route path="/developer/applications/:applicationId" element={<DeveloperApplicationPage />} />
            <Route path="/applications/:applicationId" element={<ApplicationPage />} />
            <Route path="/applications" element={<ApplicationsPage />} />
            <Route path="/share/:itemType/:itemName/" element={<ShareItem />} />
            <Route path="/shares/:shareUuid" element={<SharePage />} />
            <Route path="/public/:shareUuid" element={<PublicSharePage />} />
            <Route path="/groups/:groupId/rules/new" element={<GroupRulePage />} />
            <Route path="/groups/:groupId/rules/:ruleId" element={<GroupRulePage />} />
            <Route path="/groups/add" element={<GroupPage />} />
            <Route path="/groups/:groupUuid/definitions/:groupDefinitionId" element={<GroupDefinitions />} />
            <Route path="/groups/:groupUuid/definitions/add" element={<GroupDefinitions />} />
            <Route path="/groups/:groupUuid/categories/add" element={<CategoryPage />} />
            <Route path="/groups/:groupUuid/definitions/" element={<AllGroupDefinitions />} />
            <Route path="/groups/:groupUuid" element={<GroupPage />} />
            <Route path="/groups" element={<GroupsPage />} />
            <Route path="/views/add" element={<ViewPage />} />
            <Route path="/views/:viewUuid" element={<ViewPage />} />
            <Route path="/views/:viewUuid/categories/add" element={<CategoryPage />} />
            <Route path="/views/:viewUuid/categories/" element={<ViewCategories />} />
            <Route path="/views" element={<ViewsPage />} />
            <Route path="/categories/add" element={<CategoryPage />} />
            <Route path="/categories/:categoryId" element={<CategoryPage />} />
            <Route path="/categories" element={<CategoriesPage />} />
            <Route path="/profiles/:userName" element={<UserProfile />} />
            <Route path="/users/tag/:tag" element={<AccessRightsTags />} />
            <Route path="/users/:userName/categories/:categoryId" element={<CategoryPage />} />
            <Route path="/users/:userName/groups/:groupName" element={<GroupPage />} />
            <Route path="/users/:userName" element={<OtherUser />} />
            <Route path="/interventions/add" element={<InterventionPage />} />
            <Route path="/interventions/:interventionUuid" element={<InterventionPage />} />
            <Route path="/interventions" element={<InterventionsPage />} />
            <Route path="/tests/missing" element={<MissingTestsPage />} />
          </Routes>
        </div>
      </div>
    );
  }
}

export default withRouter(App);