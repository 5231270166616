import moment from "moment"

import { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';

import DefinitionPicker from "./definition-picker.component"
import Alert from './alert.component.js';

import entryService from '../services/entries.service';
import defintionsService from '../services/definitions.service';

export default function AddResult() {
  const [entryValue, setEntryValue] = useState('');
  const [dateTaken, setDateTaken] = useState('');
  const [loading, setLoading] = useState(false);
  const [entryDefinition, setEntryDefinition] = useState({});
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });


  const onChangeDate = e => {
    e.preventDefault();
    const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
    setDateTaken(newDate);
  };

  const onChangeEntryValue = e => {
    e.preventDefault();
    setEntryValue(e.target.value);
  }

  const handleSubmit= (e) => {
    e.preventDefault();

    if (!entryDefinition.measurement_unit_type_name) {
      setAlert(alert => ({...alert, 
        show: true,
        alertType: 'danger',
        alertTitle: 'Error',
        alertMessage: 'Missing measurement unit type'
      }));
      return;
    }

    if (!entryDefinition.definition_name) {
      setAlert(alert => ({...alert, 
        show: true,
        alertType: 'danger',
        alertTitle: 'Error',
        alertMessage: 'Missing marker name'
      }));
      return;
    }

    if (entryValue === '' || entryValue.replace(/[\n\r]+/g, '') === '') {
      setAlert(alert => ({...alert, 
        show: true,
        alertType: 'danger',
        alertTitle: 'Error',
        alertMessage: 'Missing value'
      }));
      return;
    }

    setAlert(alert => ({...alert, show: false}));
    setLoading(true);

    const definitionsData = {
      name: entryDefinition.definition_name,
      measurement_unit_types: [{name: entryDefinition.measurement_unit_type_name}]
    }

    defintionsService.create(definitionsData).then(
      response => {
        const definitionResult = response.data;
        entryService.addEntry(definitionResult.definition, entryDefinition.measurement_unit_type_name, dateTaken, entryValue).then(
          response => {
            if (response.data.success && response.data.message) {
              setAlert(alert => ({...alert, 
                show: true,
                alertType: 'success',
                alertTitle: 'Success',
                alertMessage: response.data.message
              }));
            } else {
              setAlert(alert => ({...alert, 
                show: true,
                alertType: 'success',
                alertTitle: 'Success',
                alertMessage: 'Added'
              }));
            }
            setEntryValue('');
            setEntryDefinition({measurement_unit_type_name: '', definition_name: ''});
            setLoading(false);
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
    
            setLoading(false);
            setAlert(alert => ({...alert, 
              show: true,
              alertType: 'danger',
              alertTitle: 'Error',
              alertMessage: resMessage
            }));
        }, error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setAlert(alert => ({...alert, 
            show: true,
            alertType: 'danger',
            alertTitle: 'Error',
            alertMessage: resMessage
          }));
        })
      }, error => {
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertTitle: 'Error',
          alertMessage: error.data.message
        }));
      })
    }

  useEffect(() => {
    setDateTaken(moment().format("YYYY-MM-DD"))
  }, [])

  const setFailureMessage = (message) => {
    setAlert(alert => ({...alert, 
      show: true,
      alertType: 'danger',
      alertTitle: 'Error',
      alertMessage: message
    }));
  }

  const setSuccessMessage = (message) => {
    setAlert(alert => ({...alert, 
      show: true,
      alertType: 'success',
      alertTitle: 'Success',
      alertMessage: message
    }));
  };
  
  return (
    <>
      <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />
      <div>
        <h2>Add Entry</h2>
          <Form>
            <Form.Group>
              <label htmlFor="date_taken">For</label>
              <input
                type="date"
                className="form-control"
                name="date_taken"
                value={dateTaken}
                onChange={onChangeDate}
              />
            </Form.Group>

            <Form.Group>
              <DefinitionPicker 
                setErrorMessage={setFailureMessage} 
                setSuccessMessage={setSuccessMessage} 
                onDefinitionPicked={(newDefinition) => setEntryDefinition(previousEntryDefinition => ({...previousEntryDefinition, definition_name: newDefinition.name, definition_id: newDefinition.id}))}
                onMeasurementUnitTypePicked={(newName) => setEntryDefinition(previousEntryDefinition => ({...previousEntryDefinition, measurement_unit_type_name: newName}))}
                definitionValue={entryDefinition.definition_name}
                unitMeasurementValue={entryDefinition.measurement_unit_type_name}
                  />
            </Form.Group>

            <Form.Group>
              <label htmlFor="value">Value</label>
              <textarea
                type="input"
                className="form-control"
                name="value"
                value={entryValue}
                onChange={onChangeEntryValue}
              />
            </Form.Group>

            <Form.Group>
              <button
                className="btn btn-primary btn-block"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Submit</span>
              </button>
            </Form.Group>
          </Form>
      </div>
    </>
  )
}