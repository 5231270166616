import React, { useState, useEffect } from "react";

import Alert from './alert.component.js';

import userGroupsService from "../services/user-groups.service";
import groupService from "../services/group.service";
import userApplicationsService from "../services/user-applications.service";
import applicationService from "../services/application.service";
import viewsService from "../services/view.service";
import applicationAlertService from "../services/application-alerts.service.js";

export default function Home() {
  const [userGroups, setUserGroups] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [userApplications, setUserApplications] = useState([]);
  const [allApplications, setAllApplications] = useState([]);
  const [searchDefinition, setSearchDefinition] = useState('');
  const [listViews, setListViews] = useState([]);
  const [applicationAlerts, setApplicationAlerts] = useState([]);
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });

  useEffect(() => {
    groupService.getAll().then(
      response => {
        setAllGroups(response.data.groups);
      },
      error => {
        setFailureMessage(
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString()
          );
      })
  }, []);

  useEffect(() => {
    userGroupsService.getAll().then(
      response => {
        setUserGroups(response.data.groups);
      },
      error => {
        setFailureMessage(
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString()
          );
      }
    );
  }, []);

  useEffect(() => {
    applicationService.getAll().then(
      response => {
        setAllApplications(response.data.applications);
      },
      error => {
        setFailureMessage(
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
            );
        });
  }, []);

  useEffect(() => {
    userApplicationsService.getAll().then(
      response => {
        setUserApplications(response.data.applications);
      },
      error => {
        setFailureMessage(
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString()
          );
      }
    );
  }, []);

  useEffect(() => {
    applicationAlertService.getAllUnread().then(
      response => {
        for (let alert of response.data.application_alerts) {
          if (alert.definition) {
            alert.link = `/user?definition=${alert.definition.id}`;
          }
          if (alert.data.group_id) {
            alert.link=`${alert.link}&group=${alert.data.group_id}`;
          }
        }
        setApplicationAlerts(response.data.application_alerts);
      },
      error => {
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertTitle: 'Error',
          alertMessage: (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }));
      }
  )}, []);

  const getUnused = (all, used) => {
    const usedIds = used.map(item => item.id);
    const unused = all.filter(item => !usedIds.includes(item.id));

    return unused;
  }

  const setFailureMessage = (message) => {
    setAlert(alert => ({...alert, 
      show: true,
      alertType: 'danger',
      alertTitle: 'Error',
      alertMessage: message
    }));
  }

  const setSuccessMessage = (message) => {
    setAlert(alert => ({...alert, 
      show: true,
      alertType: 'success',
      alertTitle: 'Success',
      alertMessage: message
    }));
  };

  const searchDefinitionClicked = (e) => {
    e.preventDefault();
    setSuccessMessage('');
    setFailureMessage('');

    viewsService.searchByDefinitionName(searchDefinition).then(
      response => {
        setSuccessMessage(response.message);
        setListViews(response.data.views);
      },
      error => {
        setFailureMessage(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        );
      }
    );

  }

  return (
    <>
      <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />
      <div className="row mb-2">
        <div className="col-sm-12 alert alert-info" role="alert">
          Go <a href="/tests/missing" className="link-info">here</a> for all of your missing tests and required re-tests!
        </div>
      </div>
      {applicationAlerts.length > 0 && (
        <div className="row mb-2">
          <h4 className="alert-heading">
            You have {applicationAlerts.length} unread alerts:
          </h4>
          <div className="col-sm-12 alert alert-info" role="alert">
            <ul>
            {
              applicationAlerts.map(alert => (
                <li key={`alert-${alert.uuid}`} className="alert-dismissible">
                  {alert.definition ? (
                    <>
                    <b>{alert.definition ? alert.definition.name : ''}:</b>
                    <a href={alert.link ? alert.link : ''} className="alert-link">{alert.text}</a>
                    </>
                  ) : (alert.text)
                  }
                  
                </li>
              ))
            }
            </ul>
          </div>
        </div>
      )}
      <div className="row  mb-2 col-sm-12 height d-flex justify-content-center align-items-center">
        <div className="d-flex">
          <input 
            className="form-control me-2" 
            type="search" 
            placeholder="Search sample name" 
            aria-label="Search" 
            onChange={(e) => setSearchDefinition(e.target.value)}
            value={searchDefinition}
            />
          <button className="btn btn-outline-success" onClick={searchDefinitionClicked}>Search</button>
        </div>
        {listViews.length > 0 && (
          <div>
            <h3>Search Results</h3>
            <ul>
              {
                listViews.map(view => (
                  <li key={`view-${view.uuid}`}>
                    <a href={`/user/views/${view.uuid}`}>{view.name}</a>
                  </li>
                ))
              }
            </ul>
          </div>
        
        )}
      </div>
      <div className="row mb-2">
        <div className="col-sm-6">
          <div className="card bg-primary me-2">
            <div className="card-body">
              <h5 className="card-title">Groups</h5>
              <h6 className="card-subtitle mb-2 text-muted">Compare your results to other groups of people</h6>
              <div className="card-text">Popular groups you don't currently use:
                <ul>
                  {
                    getUnused(allGroups, userGroups).map(group => (
                      <li key={`group-{group.uuid}`}>
                        <a href={`/groups/${group.uuid}`}>{group.name}</a>
                      </li>
                    ))
                  }
                </ul>
              </div>
              <a href="/groups?sort=popular" className="card-link">{allGroups.length} Popular groups</a>
              <a href="/groups" className="card-link">You are using {userGroups.length} groups</a>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card bg-primary me-2">
            <div className="card-body">
              <h5 className="card-title">Applications</h5>
              <h6 className="card-subtitle mb-2 text-muted">Popular applications</h6>
              <div className="card-text">
                Popular applications you don't currently use:
                <ul>
                  {
                    getUnused(allApplications, userApplications).map(application => (
                      <li key={`application-${application.id}`}>
                        <a href={`/applications/${application.id}`}>{application.name}</a>
                      </li>
                    ))
                  }
                </ul>
              </div>
              <a href="/applications" className="card-link">{allApplications.length} Popular applications</a>
              <a href="/applications" className="card-link">You are using {userApplications.length} applications</a>
            </div>
          </div>
        </div>
      </div>
      <div className="row  mb-2 col-sm-12 height d-flex justify-content-center align-items-center">
        <div className="col-sm-12">
          <div>
            <p>For now, here is some info about defintions:</p>
            <ul>
              <li key="first">Result - a single results from any marker you want</li>
              <li key="two">Definition - The definition of what a result means</li>
              <li key="three">Measurement Unit Type - how do we measure this specific definition</li>
              <li key="four">Boundary - for each Definition/Measurement Unit Type - what is are the upper and lower limits if any for a group of users</li>
              <li key="five">Category - a list of definitions under some logical category</li>
              <li key="six">Group - a list of categories and boundaries</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}