import api from '../interceptors/auth.interceptor';

class MissingTestsService {
  getMissingTests(viewId) {
    return api.get(process.env.REACT_APP_API_URL + `/api/tests/definitions/missing/${viewId}`);
  }

  getRequiredRetests() {
    return api.get(process.env.REACT_APP_API_URL + `/api/tests/definitions/retest`);
  
  }
}

const missingTestsService = new MissingTestsService();
export default missingTestsService;