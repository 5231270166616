import { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import Alert from "./alert.component"

import groupService from '../services/group.service';

export default function GroupRulePage() {
  const navigate = useNavigate();

  const [group, setGroup] = useState({});
  const [rule, setRule] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });

  let { groupId } = useParams();
  let { ruleId } = useParams();

  const hideMessage = () => {
    setAlert(alert => ({...alert, 
      show: false,
    }));
  }

  const setFailureMessage = (message) => {
    if (!message) {
      hideMessage();
    } else {
      setAlert(alert => ({...alert, 
        show: true,
        alertType: 'danger',
        alertTitle: 'Error',
        alertMessage: message
      }));
    }
  }

  const setSuccessMessage = (message) => {
    if (!message) {
      hideMessage();
    } else {
      setAlert(alert => ({...alert, 
        show: true,
        alertType: 'success',
        alertTitle: 'Success',
        alertMessage: message
      }));
    }
  };

  useEffect(() => {
    groupService.getById(groupId).then(
      response => {
        const group = response.data.group;
        setGroup(group);
        if (ruleId) {
          const rule = (group.group_definitions_rules || []).find(rule => rule.id === ruleId);
          if (rule) {
            setRule(rule);
          } else {
            setFailureMessage('Rule not found');
          }
        }
      }, error => {   
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setFailureMessage(resMessage);
      })
    }, [groupId]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (!group) {
      setFailureMessage('Group not found');
      return;
    }

    setLoading(true);

    let action;

    if (!rule.id) {
      action = groupService.createRule(group.uuid, {name: rule.name, formula: rule.formula});
    } else {
      action = groupService.updateRule(group.uuid, {name: rule.name, formula: rule.formula});
    }

   action.then(
      response => {
        const rule = response.data.rule
        if (response.data.success && response.data.message) {
          setSuccessMessage(response.data.message)
        }
        setLoading(false);
        if (rule.id !== ruleId) {
          navigate(`/groups/${group.id}/rules/${rule.id}`);
        }
      }, error => {
        setLoading(false);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setFailureMessage(resMessage);
      }
    );
  }

  const onNameChange = (e) => {
    setRule({...rule, name: e.target.value});
  } 

  const onFormulaChange = (e) => {
    setRule({...rule, formula: e.target.value});
  } 

  return (
    <>
      {loading && (
        <span className="spinner-border spinner-border-sm"></span>
      )}
      <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />
      <h3>New Group Rule For {group ? group.name : 'unknown group'}</h3>
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <label htmlFor="name">Name</label>
          <input 
            type="text" 
            className="form-control" 
            id="name" 
            aria-describedby="name" 
            value={rule.name}
            onChange={onNameChange} />
        </div>
        <div className="mb-3">
          <label htmlFor="formula">Formula</label>
          <textarea 
            className="form-control" 
            id="formula" 
            aria-describedby="formula" 
            onChange={onFormulaChange}
            value={rule.formula}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          {loading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          Submit
        </button>

      </form>
    </>
  );
}