import { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";

import shareService from '../services/share.service';
import capitalizeFirstLetter from '../utils/capitalize-first-letter';

export default function SharePage() {

  const [failureMessage, setFailureMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState("")
  const [share, setShare] = useState();
  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [showSignup, setShowSignup] = useState(false);

  let { shareUuid } = useParams();

  useEffect(() => {
    if (!shareUuid) {
      return;
    }
    shareService.get(shareUuid).then(
      response => {
        if (response.data.success) {
          const share = response.data.share;
          setShare(share);
          setItem(share.item);
          setSuccessMessage(response.message);
        } else {
          setFailureMessage(response.data.message)
        }
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )}, [shareUuid])

  const handleAccept = (event) => {
    event.preventDefault();

    setFailureMessage("")
    setSuccessMessage("")
    setLoading(true);

    shareService.accept(shareUuid).then(
      response => {
        setLoading(false);
        setSuccessMessage(response.data.message);
      },
      error => {
        if (error.response.status === 403) {
          setShowSignup(true);
        } else {
          setFailureMessage(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
          );
        }
        setLoading(false);
      }
    )
  }

  const handleReject = (event) => {
    event.preventDefault();

    setFailureMessage("")
    setSuccessMessage("")
    setLoading(true);

    shareService.reject(shareUuid).then(
      response => {
        setLoading(false);
        setSuccessMessage(response.data.message);
      },
      error => {
        if (error.response.status === 403) {
          setShowSignup(true);
        } else {
          setFailureMessage(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
          );

        }
        setLoading(false);
      }
    )
  }
  return (
    <>
      {failureMessage && <div className="alert alert-danger" role="alert">{failureMessage}</div>}
      {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
      {share &&
         (
          <div key={share.uuid}>
            <h2>Shared by {share.sharing_user.username}</h2>
            <h3>Shared {item && share && `${capitalizeFirstLetter(share.shared_item_type)} Item: ${item.name}`}</h3>
            <div className="btn-group" role="group" aria-label="Basic example">
              <button type="button" className="btn btn-primary" onClick={handleAccept}>Accept</button>
              <button type="button" className="btn btn-secondary" onClick={handleReject}>Reject</button>
              {showSignup && <Link to="/register" className="btn btn-secondary">Sign up</Link>}
            </div>
          </div>
         )}
    </>
  )
}