import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import DefinitionAdderWithChoice from "./definition-adder-with-choice.component"

import groupDefinitionsService from "../services/group-definitions.service";

import addTitleToDefinition from "../utils/add-title-to-definition"

export default function AllGroupDefinitions() {
  const [failureMessage, setFailureMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState("")
  const [group, setGroup] = useState({});
  const [groupDefinitions, setGroupDefinitions] = useState([])
  const [loading, setLoading] = useState(false);
  const [showAddDefinition, setShowAddDefinition] = useState(false);
  const [allLevelsLabels, setAllLevelsLabels] = useState([]);

  let { groupUuid } = useParams();

  useEffect(() => {
    groupDefinitionsService.getAll(groupUuid).then(
      response => {
        const group = response.data.group;
        setGroup(group);
        const definitions = response.data.definitions;
        for (const definition of definitions) {
          addTitleToDefinition(definition)
        }
        setGroupDefinitions(definitions);
        const allLevelsLabels = definitions.map((definition) => 
          Object.keys(definition.group_definitions.levels).map((levelKey)=> definition.group_definitions.levels[levelKey].label)
        ).flat().reduce((unique, label) => {
          if (!unique.some((obj) => obj === label)) {
            unique.push(label);
          }
          return unique;
        }, []);
        setAllLevelsLabels(allLevelsLabels)
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )}, [groupUuid])

  const onShowDefinitionClicked = () => {
    if (showAddDefinition) {
      onAddDefinitionClicked(false);
    } else {
      setShowAddDefinition(true)
    }
  }

  const onAddDefinitionClicked = (showAddDefinitionValue=true) => {
    setSuccessMessage('');
    setFailureMessage('');
    setLoading(true);
    groupDefinitionsService.createBulk(groupUuid, groupDefinitions).then(
      response => {
        setSuccessMessage(response.data.message);
        setLoading(false);
        setShowAddDefinition(showAddDefinitionValue);
      },
      error => {
        setLoading(false);
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )
  }

  return (
    <>
      {failureMessage && <div className="alert alert-danger" role="alert">{failureMessage}</div>}
      {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
      <h2>{group.name}</h2>

      {showAddDefinition && (
        <DefinitionAdderWithChoice
        setErrorMessage={setFailureMessage} 
        setSuccessMessage={setSuccessMessage} 
        onDefinitionsPicked={(newDefinitions) => {
          for (const definition of newDefinitions) {
            if (!definition.group_definitions) {
              definition.group_definitions = {};
            }
          }
          setGroupDefinitions(newDefinitions)
        }}
        // definitionValue={groupDefinition.definition_name}
        // unitMeasurementValue={groupDefinition.measurement_unit_type_name}
        allowFilter={true}
          />
      )}
      <div className="btn-group" role="group" aria-label="Basic example">
        <button className="btn btn-primary" onClick={onShowDefinitionClicked}>
          {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
          Add Definition
        </button>
      </div>
      
      <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Definition</th>
              <th scope="col">Retest Period</th>
              <th scope="col">Rule</th>
              {
                allLevelsLabels.map((label) => (
                  <td>{label}</td>
                ))
              }
            </tr>
          </thead>
          <tbody>
        {
          groupDefinitions && groupDefinitions.map((definition, index) => {
            let definitionRow = (
              <tr key={definition.id}>
                <td>
                  <a className="btn btn-secondary" href={`/groups/${groupUuid}/definitions/${definition.id}`} role="button">{index}</a>
                  </td>
                <td>{definition.title}</td>
                <td>{definition.group_definitions.retest_period_in_days}</td>
                <td></td>
                {
                  allLevelsLabels.map((levelLabel) => {
                    const groupDefinitionLevel = definition.group_definitions.levels ? 
                      Object.keys(definition.group_definitions.levels).find((key) => definition.group_definitions.levels[key].label === levelLabel) :
                      null
                    return (
                      <td>{groupDefinitionLevel ? definition.group_definitions.levels[groupDefinitionLevel].value : ''}</td>
                    )
                  })
                }
              </tr>
            )

            if (definition.group_definitions && definition.group_definitions.boundaries_by_rules) {
              const boundariesByRulesRow = (
                Object.keys(definition.group_definitions.boundaries_by_rules).map((ruleName) => (
                  <tr key={`${definition.id} - ${ruleName}`}>
                    <td>{index}</td>
                    <td>{definition.title}</td>
                    <td>{definition.group_definitions.boundaries_by_rules[ruleName].retest_period_in_days}</td>
                    <td>{ruleName}</td>
                    { definition.group_definitions.boundaries_by_rules[ruleName].levels &&
                      Object.keys(definition.group_definitions.boundaries_by_rules[ruleName].levels).map((key) => (
                        <td>{definition.group_definitions.boundaries_by_rules[ruleName].levels[key].value}</td>
                      ))
                    }
                  </tr>
                ))
              )

              definitionRow = (<>{definitionRow}{boundariesByRulesRow}</>)
            }
          
            return definitionRow
          })
        }
          </tbody>
        </table>
        <a className="btn btn-secondary" href={`/groups/${groupUuid}/definitions/add`} role="button">
          {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                Add boundaries
        </a>
    </>
  )
}