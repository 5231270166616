import { useEffect, useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

function Alert({variant, title, text, position='top-end', autohide = false, initialShow = false}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(initialShow);
  }, [initialShow]);

  return (
    <>
      {/* {[
        'Primary',
        'Secondary',
        'Success',
        'Danger',
        'Warning',
        'Info',
        'Light',
        'Dark',
      ].map((variant, idx) => ( */}
      <ToastContainer
          className="p-3"
          position={position}
          style={{ zIndex: 1 }}
        >
        <Toast
          className="d-inline-block m-1"
          bg={variant ? variant.toLowerCase() : 'danger'}
          key='alert'
          onClose={() => setShow(false)} 
          show={show} 
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            {title && <strong className="me-auto">{title}</strong>}
            <small>{text}</small>
          </Toast.Header>
          <Toast.Body className={variant === 'Dark' && 'text-white'}>
            {text}
          </Toast.Body>
        </Toast>
        </ToastContainer>
    </>
  );
}


export default Alert;
