import api from '../interceptors/auth.interceptor';

class UserGroupsService {

  getAll() {
    return api.get(process.env.REACT_APP_API_URL + '/api/user/groups');
  }

  add(groupUuid) {
    return api.put(process.env.REACT_APP_API_URL + `/api/user/groups/${groupUuid}`);
  }

  remove(groupUuid) {
    return api.delete(process.env.REACT_APP_API_URL + `/api/user/groups/${groupUuid}`);
  }
}

const userGroupsService = new UserGroupsService();
export default userGroupsService;