import api from '../interceptors/auth.interceptor';

class MeasurementUnitTypesService {
  getNames() {
    return api.get(process.env.REACT_APP_API_URL + `/api/measurement_unit_types`)
  }
}


const measurementUnitTypesService = new MeasurementUnitTypesService();
export default measurementUnitTypesService;