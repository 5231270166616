import api from '../interceptors/auth.interceptor';

class GroupInsightsService {
  get(group, definition, insightType) {
    return api.get(process.env.REACT_APP_API_URL + `/api/groups/${group.id}/insights/${definition.id}/${insightType}`)
  }

  getAll(group, definition) {
    return api.get(process.env.REACT_APP_API_URL + `/api/groups/${group.id}/insights/${definition.id}`)
  }

  getById(group, insightId)  {
    return api.get(process.env.REACT_APP_API_URL + `/api/groups/${group.id}/insights/ids/${insightId}`)
  }

  create(group, insights) {
    return api.post(process.env.REACT_APP_API_URL + `/api/groups/${group.id}/insights`, {
      insights: insights
    });
  }

  
}

const groupInsightsService = new GroupInsightsService();
export default groupInsightsService;