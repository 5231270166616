import moment from "moment"

import { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from "react-router-dom";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import capitalizeFirstLetter from '../utils/capitalize-first-letter';

import userInterventionsService from '../services/user-intervention.service';
import viewService from '../services/view.service';

export default function InterventionPage() {

  const navigate = useNavigate();

  const [newInterventionFlag, setNewInterventionFlag] = useState(false);
  const [intervention, setIntervention] = useState();
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [views, setViews] = useState([])
  const [chosenView, setChosenView] = useState()

  let { interventionUuid } = useParams();

  useEffect(() => {
    if (!interventionUuid) {
      setIntervention({
        name: '',
        view: {},
      });
      setName('');
      setNewInterventionFlag(true);
      return;
    }

    userInterventionsService.get(interventionUuid).then(
      response => {
        const localIntervention = response.data.intervention;
        setIntervention(localIntervention);
        setName(localIntervention.name);
      },
      error => {
        setErrorMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )}, [interventionUuid])

  useEffect(() => {
    viewService.getAll().then(
      response => {
        const views = response.data.views;
        setViews(views);
        setChosenView(views[0]);
      },
      error => {
        setErrorMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )
  }, [])

  const handleNameChange = (event) => {
    event.preventDefault();
    setName(event.target.value)
  }

  const handleSubmit= (e) => {
    e.preventDefault();

    if (!name) {
      setErrorMessage("Name is required");
      return;
    }

    setErrorMessage("")
    setSuccessMessage("")

    setLoading(true);

    let remoteAction;

    if (newInterventionFlag) {
      const newIntervention = {
        name: name,
        view_id: chosenView.id
      }
      remoteAction = userInterventionsService.create(newIntervention);
    } else {
      remoteAction = userInterventionsService.update(intervention.uuid, intervention);
    }

    remoteAction.then(
      (response) => {
        const localIntervention = response.data.intervention;
        if (newInterventionFlag) {
          navigate(`/interventions/${localIntervention.uuid}`, {state: {message: response.data.message}});
        }
        if (response.data.success && response.data.message) {
          setSuccessMessage(response.data.message)
        }
        setIntervention(localIntervention);
        setName(localIntervention.name);
        setLoading(false);
        setNewInterventionFlag(false);
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setErrorMessage(resMessage);
        });
  }

  return (
    <>
      {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
      {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
      {intervention &&
         (
          <div key={intervention.name} className='col-xs-12 col-sm-12'>
            <h2>{intervention.name}</h2>
            <Form onSubmit={handleSubmit} className='form-horizontal'>

              <div className="form-view">
                <div className="col-xs-3 text-right">
                  <label htmlFor="name">Name</label>
                </div>
                <div className="col-xs-9">
                  <Input
                    type="text"
                    className="form-control"
                    name="name"
                    value={name}
                    onChange={handleNameChange}
                  />
                  {intervention && intervention.publisher && (<span>by <Link to={`/profiles/${intervention.publisher.username}`}>{intervention.publisher.username}</Link></span>)}
                </div>
              </div>

              <div className='form-view mb-3'>
                <div className="col-xs-3 text-right mb-3">
                  <label htmlFor='view_name'>View Name</label>
                </div>
                <div className="btn-group btn-group-sm mb-3" role="group" aria-label="choose view name">
                  {views && views.map((view, index) => (
                    <> 
                      <input 
                        type="radio" 
                        key={`view-${view.id}`} 
                        className="btn-check" 
                        name="btnradio" 
                        id={`view-${view.id}`} 
                        autoComplete="off" 
                        checked={chosenView.uuid === view.uuid}
                        onClick={(e) => { setChosenView(view); setIntervention({...intervention, view_id: view.id})}}
                      />
                      <label className="btn btn-outline-primary" htmlFor={`view-${view.id}`}>{capitalizeFirstLetter(view.name)}</label>
                    </>
                  ))}
                </div>
              </div>

              <div className='form-view mb-3'>
                <label htmlFor="startDate">Start</label>
                <input 
                    className="form-control"
                    name="startDate"
                    type="date" 
                    id="started_at" 
                    value={moment(new Date(intervention.started_at)).format('YYYY-MM-DD')} 
                    onChange={(e) => setIntervention({...intervention, started_at: e.target.value})} 
                  />
              </div>

              <div className='form-view mb-3'>
                <label htmlFor="endDate">End</label>
                <input 
                    className="form-control"
                    name="endDate"
                    type="date" 
                    id="started_at" 
                    value={moment(new Date(intervention.ended_at)).format('YYYY-MM-DD')}
                    onChange={(e) => setIntervention({...intervention, ended_at: e.target.value})} 
                  />
              </div>


              <div className="form-view mb-3">

                <div className="btn-view mb-3" role="view">
            
                  <button
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Submit</span>
                  </button>
                </div>
              </div>
            </Form>
          </div>)}
    </>
  )
}