
import moment from "moment"

import { useState, useEffect } from 'react';
import TrackingChart from './tracking-chart.component';
import DefinitionPicker from "./definition-picker.component"
import DefinitionSelector from "./definition-selector.component"
import Alert from "./alert.component"

import categoryEntriesService from "../services/category-entries.service";
import definitionEntriesService from "../services/definition-entries.service";
import authService  from "../services/auth.service";
import defintionsService from "../services/definitions.service";
import shareService from "../services/share.service";

import addTitleToDefinition from "../utils/add-title-to-definition"

export default function MultipleTrackingCharts({title, group, configurations, category, chosenDefinitionId, boundaries, userName, shareUuid, dateRange}) {
  const [loading, setLoading] = useState(false)
  const [graphData, setGraphData] = useState({});
  const [definitionsMap, setDefinitionsMap] = useState({});
  const [displayUpdateDefinitionPicker, setDisplayUpdateDefinitionPicker] = useState('');
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });
  const [updatedDefinitionsRequest, setUpdatedDefinitionsRequest] = useState([]);
  const [sharedLink, setSharedLink] = useState('')

  const onErrorMessage = (message) => {
    setAlert(alert => ({...alert, 
      show: true,
      alertType: 'danger',
      alertTitle: 'Error',
      alertMessage: message
    }));
  }

  const onSuccessMessage = (message) => {
    setAlert(alert => ({...alert, 
      show: true,
      alertType: 'success',
      alertTitle: 'Success',
      alertMessage: message
    }));
  }

  useEffect(() => {
    if (!category.definitions.length) return;
    let dataRequest;

    setLoading(true);

    if (category.id) {
      if (userName) {
        dataRequest = categoryEntriesService.getAllByUserName(userName, category.id)
          .then((response) => response.data.category.definitions)
      } else if (shareUuid) {
        dataRequest = categoryEntriesService.getAllByShareUuid(shareUuid, category.id)
          .then((response) => response.data.category.definitions)
      } else {
        dataRequest = categoryEntriesService.getAll(category.id)
          .then((response) => response.data.category.definitions)
      }
    } else {
      const definitionNames = category.definitions.map((definition) => definition.name)
      if (userName) {
        dataRequest = definitionEntriesService.getAllByName(userName, {definitions_names: definitionNames})
          .then((response) => response.data.definitions)
      } else {
        dataRequest = definitionEntriesService.getAll({definitions_names: definitionNames})
          .then((response) => response.data.definitions)
      }
    }

    dataRequest.then(
      definitions => {
        let localGraphData = {};
        let localDefinitionsMap = {};
        definitions.forEach((definition) => {
          let indexName;
          if (definition.rule_name) {
            indexName = definition.rule_name;
          } else {
            indexName = definition.id
          }

          if (!localGraphData[indexName]) {
            localGraphData[indexName] = [];
          }

          if (!localDefinitionsMap[indexName]) {
            localDefinitionsMap[indexName] = definition;
            addTitleToDefinition(localDefinitionsMap[indexName])
          }

          if (dateRange && (dateRange.started_at || dateRange.ended_at)) {
            if (dateRange.started_at)  {
              definition.Entries.push({date_taken: dateRange.started_at, values: []})
            }
            if (dateRange.ended_at)  {
              definition.Entries.push({date_taken: dateRange.ended_at, values: []})
            }
          }

          definition.Entries.sort((a, b) => {
            return moment(a.date_taken).isAfter(b.date_taken) ? -1 : 1;
          } ).reverse();
          for (const entry of definition.Entries) {
            const currentData = {name: indexName, date: entry.date_taken}
            currentData.value = entry.values.length ? entry.values[0].value : '';
            localGraphData[indexName].push(currentData);
          }
        })

        setGraphData(localGraphData);
        setDefinitionsMap(localDefinitionsMap);
        setLoading(false);
      },
      error => {
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertMessage: (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }));
      }
    );
  }, [category.definitions, category.id, userName, shareUuid, dateRange]);

  const isRuleTrue = (rule) => {
    const localRule = JSON.parse(JSON.stringify(rule));
    let formula = localRule.formula;

    let safetyLength;
    const currentUser = authService.getCurrentUser();
    
    while (formula.includes('$') && (safetyLength === undefined || safetyLength !== formula.length)) {
      const variableStartPosition = formula.indexOf('$');
      const variablesNames = formula.substr(variableStartPosition+1).match(/^[a-zA-Z0-9_.]*/g)[0].split('.');
      let variableValue; 
      let parentData;
      switch (variablesNames[0].toLowerCase()) {
        case 'groupconfiguration':
          parentData = configurations.groupConfiguration.configuration;
          break;
        case 'profile':
          parentData = currentUser;
          break;
        case 'configuration':
          parentData = currentUser.confguration;
          break;
        default:
          console.log('unknown parent data')
          parentData = {};
      }

      for (const variableName of variablesNames) {
        if (variableName.toLowerCase() === variablesNames[0].toLowerCase()) continue;
        parentData = parentData[variableName]
      }
      variableValue = parentData;
      
      safetyLength = formula.length;
      formula = formula.replace(`$${variablesNames.join('.')}`, variableValue);
    }

    if (formula.toLowerCase() === 'false' || formula === '0') return false;
    
    return !!formula;
  }

  const findValueRule = (boundariesByRules) => {
    if (!configurations) return;
    for (const ruleName of Object.keys(boundariesByRules)) {
      const rule = group.group_definitions_rules.find((groupDefinitionRule) => groupDefinitionRule.name === ruleName);

      if (!rule) {
        continue;
      }

      if (isRuleTrue(rule)) {
        return rule;
      }
    }
  }
  
  const listTrackingChartByCategory = (category) => {
    let returnTrackingCharts = [];
    let graphDataKeys = Object.keys(graphData).map((key) => parseInt(key));

    let categoryDefinitionIds;
    if (chosenDefinitionId) {
      if (category.definitions.findIndex((definition) => definition.id === chosenDefinitionId) === -1) {
        return [];
      } else {
        categoryDefinitionIds = [chosenDefinitionId]
      }
    } else {
      categoryDefinitionIds = category.definitions.map((definition) => definition.id);
    }
    const categoryGraphDataKeys = graphDataKeys.filter((graphDataKey) => 
      categoryDefinitionIds.includes(graphDataKey) && graphData[graphDataKey].length > 0)
    returnTrackingCharts.push(...(categoryGraphDataKeys.map((key) => {
      const boundary = boundaries ? boundaries.find((boundary)=>boundary.id === key) : undefined;
      if (boundary && boundary.group_definitions.boundaries_by_rules) {
        const appliedRule = findValueRule(boundary.group_definitions.boundaries_by_rules);
        if (appliedRule) {
          const ruleBoundaries = boundary.group_definitions.boundaries_by_rules[appliedRule.name].levels;
          const localBoundaries = {...boundary.group_definitions.levels, ...ruleBoundaries};
          boundary.group_definitions.levels = localBoundaries;
        }
      }
      return (<TrackingChart 
        title={definitionsMap[key].title} 
        group={group} 
        definition={definitionsMap[key]} 
        data={graphData[key]} 
        boundary={boundary} 
        key={key} 
        dateRange={dateRange}
      />)
    })))
    graphDataKeys = graphDataKeys.filter((graphDataKey) => !categoryGraphDataKeys.includes(graphDataKey));

    const emptyDataKeys = categoryDefinitionIds.filter((categoryDefinitionName) => !graphData[categoryDefinitionName] || !graphData[categoryDefinitionName].length)
    graphDataKeys = graphDataKeys.filter((graphDataKey) => graphData[graphDataKey].length);

    if (!chosenDefinitionId) {
      returnTrackingCharts.push(...(graphDataKeys.map((key) => 
        (<TrackingChart 
          title={definitionsMap[key].title} 
          group={group} 
          data={graphData[key]} 
          key={key} 
          dateRange={dateRange}
        />))))
    }

    if (chosenDefinitionId && emptyDataKeys.length > 0) {
      returnTrackingCharts.push(<div className='row' key='empty_data'>
        <div className="row">
          <div className="col-6" id="list-tab" role="tablist">
            <h4>The following definitions were needed for this category but you are missing them.</h4> 
            <span>Press on each if you believe you actually have this data under a different name</span>
            <div className="list-group">
              {emptyDataKeys.map((key) => 
                (
                  <button className='list-group-item list-group-item-action' key={key} 
                  onClick={(e) => setDisplayUpdateDefinitionPicker(key)}
                  >
                      Missing data on {definitionsMap[key].title}
                  </button>
                ))}
            </div>
          </div>
          <div className="col-6">
            <div id="nav-tabContent">
                { 
                displayUpdateDefinitionPicker && (<>
                  <DefinitionPicker 
                    defaultTitle={`Add Marker Alterinative to ${definitionsMap[displayUpdateDefinitionPicker].title}`}
                    setErrorMessage={onErrorMessage} 
                    setSuccessMessage={onSuccessMessage} 
                    onDefinitionPicked={onUpdateDefinitionRequest}
                    onMeasurementUnitTypePicked={onUpdateDefinitionMeasurementUnitTypeRequest}
                  />
                  <button className="btn btn-primary" onClick={(e) => requestMerge(e, displayUpdateDefinitionPicker)}>Submit Request</button>
                </>
                )
                } 
                { displayUpdateDefinitionPicker && 
                  <DefinitionSelector
                  setErrorMessage={onErrorMessage} 
                  setSuccessMessage={onSuccessMessage}
                  onDefinitionsPicked={onUpdateDefinitionsSelected}
                  allowFilter={true}
                />
                }
            </div>
          </div>
        </div>
      </div>)
    }
    return returnTrackingCharts;
  }

  const onUpdateDefinitionRequest = (newDefinition) => {
    const localDefinition = [JSON.parse(JSON.stringify(newDefinition))];
    setUpdatedDefinitionsRequest(localDefinition)
  }

  const onUpdateDefinitionMeasurementUnitTypeRequest = (measurementUnitTypeName) => {
    const localDefinition = [JSON.parse(JSON.stringify(updatedDefinitionsRequest))];
    localDefinition[0].measurementUnitType = {
      name: measurementUnitTypeName
    }
    setUpdatedDefinitionsRequest(localDefinition)
  }

  const onUpdateDefinitionsSelected = (definitionsSelected) => {
    setUpdatedDefinitionsRequest(JSON.parse(JSON.stringify(definitionsSelected)))
  }

  const requestMerge = (e, definitionId) => {
    e.preventDefault();

    const definition = category.definitions.find((definition) => definition.id === definitionId);
    const currentUser = authService.getCurrentUser();

    let measurementUnitTypesMatch;

    if (!definition.measurement_unit_types || definition.measurement_unit_types.length === 0) {
      measurementUnitTypesMatch = !updatedDefinitionsRequest.measurement_unit_types || updatedDefinitionsRequest.measurement_unit_types.length === 0;
    } else {
      if (!updatedDefinitionsRequest.measurement_unit_types || updatedDefinitionsRequest.measurement_unit_types.length === 0) {
        measurementUnitTypesMatch = false;
      } else {
        measurementUnitTypesMatch = definition.measurement_unit_types[0].name === updatedDefinitionsRequest.measurement_unit_types[0].name;
      }
    }

    if (definition.name === updatedDefinitionsRequest.name && measurementUnitTypesMatch) {
          setAlert(alert => ({...alert, 
            show: true,
            alertType: 'danger',
            alertTitle: 'Error',
            alertMessage: 'You did not change anything'
          }));
        }

    if (currentUser && currentUser.username === 'liorsion') {
      setLoading(true);
      defintionsService.merge(definition, updatedDefinitionsRequest).then(
        response => {
          setAlert({
            show: true,
            alertType: 'success',
            alertTitle: 'Success',
            message: "Definition was updated"
          });
          setDisplayUpdateDefinitionPicker('');
          setLoading(false);
        },
        error => {
          setLoading(false);
          setAlert(alert => ({...alert, 
            show: true,
            alertType: 'danger',
            alertTitle: 'Error',
            alertMessage: (error.response &&
              error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
          }));
        }
      );
    } else {
      setAlert(alert => ({...alert, 
        show: true,
        alertType: 'danger',
        alertMessage: 'For now you are not allowed to use this functionalliy. Please email support@yourhealthplatform.online for more details'
      }));
    }
  }

  const shareExternally = () => {
    setLoading(true);
    shareService.create('category', null, null, category.id, null, true).then(
      response => {
        setAlert({
          show: true,
          alertType: 'success',
          alertTitle: 'Success',
          message: "Category was shared successfully."
        });
        setLoading(false);
        setSharedLink(response.data.link);
      },
      error => {
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertTitle: 'Error',
          alertMessage: (error.response &&
            error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        }));
      }
    );
  }

  return (
    <>
    {loading && (
      <span className="spinner-border spinner-border-sm"></span>
    )}
    <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />
    <div className="row mb-3">
      <div className="col">
        <h3 className="text-primary">{title ? title : 'Title'}</h3>
        <button type="button" className="btn btn-outline-primary btn-sm me-4" onClick={shareExternally}>Share externally</button>
        <span>
          {sharedLink}
          {sharedLink && <i 
            className="bi bi-clipboard"
            style={{cursor: 'pointer'}}
            onClick={() => {navigator.clipboard.writeText(sharedLink)}}>
          </i>}
        </span>
      </div>
    </div>
    <div className="row mb-3">
    {
      Object.keys(graphData).length > 0 && 
      listTrackingChartByCategory(category)
    }
    </div>
    </>
  )
}