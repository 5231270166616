import { useState } from 'react';

import DefinitionSelector from './definition-selector.component';
import DefinitionPicker from './definition-picker.component';

export default function DefinitionAdderWithChoice({
  defaultTitle = "Add Marker", 
  allDefinitionsToPickFrom, 
  setErrorMessage, 
  setSuccessMessage, 
  onDefinitionsPicked,
  allowOnlySingleSelection = false,
  setLoading=null,
  entriesDefinitions = null,
  initialValue = [], 
  allowChooseAll=true,
  allowFilter=false,
}) {

  const [showUsedDefinitions, setShowUsedDefinitions] = useState('addCustomSingleDefinition')


  return (
    <>
        <div className="form-check form-switch">
          <input className="form-check-input" type="checkbox" id="useKnownDefinitions" 
            onChange={(e) => {
              if (showUsedDefinitions === 'useKnownDefinitions') {
                setShowUsedDefinitions('addCustomSingleDefinition')
              } else {
                setShowUsedDefinitions('useKnownDefinitions')
              }
            }} />
          <label className="form-check-label" htmlFor="useKnownDefinitions">Import definitions I already use</label>
        </div>
        {showUsedDefinitions === 'useKnownDefinitions' && (
          <DefinitionSelector
            defaultTitle={defaultTitle}
            setErrorMessage={setErrorMessage} 
            setSuccessMessage={setSuccessMessage}
            onDefinitionsPicked={(definitions) => onDefinitionsPicked ? onDefinitionsPicked(definitions) : null}
            allowFilter={allowFilter}
            allowChooseAll={allowChooseAll}
            setLoading={setLoading}
            allowOnlySingleSelection={allowOnlySingleSelection}
            entriesDefinitions={entriesDefinitions}
            initialValue={initialValue}
            
          />
        )}
        {showUsedDefinitions === 'addCustomSingleDefinition' && (
          <DefinitionPicker 
            defaultTitle={ defaultTitle}
            setLoading={setLoading}
            setErrorMessage={setErrorMessage} 
            setSuccessMessage={setSuccessMessage} 
            onDefinitionPicked={(definition) => onDefinitionsPicked ? onDefinitionsPicked([definition]) : null}
            definitionValue={initialValue && initialValue[0] ? initialValue[0].name : null}
            unitMeasurementValue={initialValue && initialValue[0] && initialValue[0].measurement_unit_type ? initialValue[0].measurement_unit_type.name : null}
            allDefinitionsToPickFrom={allDefinitionsToPickFrom}
          />
        )}
      </>
  )
}
