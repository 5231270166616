import axios from 'axios';
import { redirect } from "react-router-dom";

const api = axios.create({
  baseURL: '/api',
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.access_token) {
      // for Node.js Express back-end
      config.headers['x-access-token'] = user.access_token ;
      // config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.code && error.code === 'ERR_NETWORK') {
      throw error;
    }

    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const user = JSON.parse(localStorage.getItem('user'));
        const refreshToken = user.refresh_token;
        if (!refreshToken) throw error;
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/refresh`, { refresh_token: refreshToken });
        const { refresh_token, access_token } = response.data;

        user.access_token = access_token;
        user.refresh_token = refresh_token
        localStorage.setItem('user', JSON.stringify(user));

        // Retry the original request with the new token
        originalRequest.headers['x-access-token'] = access_token;
        return axios(originalRequest);
      } catch (error) {
        localStorage.removeItem('user');
        localStorage.removeItem('chosenGroup')
        localStorage.removeItem('chosenView')
        redirect("/login");
      }
    }

    if (error.response.status === 403) {
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);


export default api
