export default class LevelsProcessor {
  constructor(levels) {
    this.levels = levels;
    this.min = null;
    this.max = null;
  }

  process() {
    for (const levelKey of Object.keys(this.levels)) {
      const level = this.levels[levelKey];
      if (level && level.value) {
        switch (level.comparator) {
          case '<':
          case 'less':
          case '<=':
          case 'less or equal':
          case 'le':
            if (!this.min || this.min > level.value) {
              this.min = level.value;
            }
            break;
          case '>':
          case 'more':
          case 'bigger':
          case '>=':
          case 'more or equal':
          case 'greater or equal':
          case 'ge':
            if (!this.max || this.max < level.value) {
              this.max = level.value;
            }
            break;
          case '=':
          case 'equal':
          case '!=':
          case 'not equal':
            throw new Error('Not implemented');
            // if (this.max < level.value) {
            //   max = level.value;
            // } 
            // if (min > level.value) {
            //   min = level.value;
            // }
            break;
          default:
            console.error('Unknown comparator: ' + level.comparator);
            break;
        }
      }
    };
  }
}