import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import MarkdownEditor from '@uiw/react-markdown-editor';

import DeveloperService from '../services/developer.service';
import Alert from './alert.component';
import DefinitionSelector from './definition-selector.component';
import DefinitionPicker from './definition-picker.component';
import DefinitionAdderWithChoice from './definition-adder-with-choice.component';

export default function DeveloperApplicationPage() {
  const [application, setApplication] = useState({
    name: '' // so input will be controlled
  });
  const [activeTabKey, setActiveTabKey] = useState('json_configuration');
  const [rulesAsString, setRulesAsString] = useState([]);
  const [showUsedDefinitions, setShowUsedDefinitions] = useState('');
  const [showDefinitionsForFirstNumber, setShowDefinitionsForFirstNumber] = useState(false);
  const [showDefinitionsForSecondNumber, setShowDefinitionsForSecondNumber] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });

  let { applicationId } = useParams();

  const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };

  const setErrorMessage = (message) => {
    if (!message) {
      setAlert(alert => ({...alert,
        show: false}));
    } else {
      setAlert(alert => ({...alert, 
        show: true,
        alertType: 'danger',
        alertTitle: 'Error',
        alertMessage: message
      }));
    }
  }

  const setSuccessMessage = (message) => {
    if (!message) {
      setAlert(alert => ({...alert,
        show: false}));
    } else {
      setAlert(alert => ({...alert, 
        show: true,
        alertType: 'success',
        alertTitle: 'Success',
        alertMessage: message
      }));
    }
  };

  useEffect(() => {
    if (!application.rules) return;
    setRulesAsString(JSON.stringify(application.rules, null, 2) );
  }, [application.rules])
  
  const onChangeApplicationRule = (e) => {
    try {
      JSON.parse(e.target.value)
      setAlert(alert => ({...alert, 
        show: false
      }));
    } catch(error) {
      setAlert(alert => ({...alert, 
        show: true,
        alertType: 'danger',
        alertMessage: 'JSON is not valid'
      }));
    }
    setRulesAsString(e.target.value);
  }

  const handleSubmit= (e) => {
    e.preventDefault();

    setAlert(alert => ({...alert, 
      show: false,
    }));

    let rulesData 
    
    try {
      rulesData= JSON.parse(rulesAsString);
    } catch(error) {
      setAlert(alert => ({...alert, 
        show: true,
        alertType: 'danger',
        alertMessage: 'JSON is mulformed'
      }));
      return;
    }

    setLoading(true);

    DeveloperService.updateApplication(applicationId, {...application, rules: rulesData}).then(
      (response) => {
        if (response.data.success && response.data.message) {
          setAlert(alert => ({...alert, 
            show: true,
            alertType: 'success',
            alertMessage: response.data.message,
            alertTitle: 'Success'
          }));
        }
        setApplication(response.data.application);
        setLoading(false);
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertMessage: resMessage,
          alertTitle: 'Error'
        }));
      });
    }

  useEffect(() => {
    DeveloperService.getDeveloperApplications(applicationId).then(
      response => {
        const yhpApplication = response.data.applications[0];
        setApplication(yhpApplication);
        setRulesAsString(JSON.stringify(yhpApplication.rules, null, 2) );
      },
      error => {
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertMessage: (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString(),
          alertTitle: 'Error'
        }));
      }
    )}, [applicationId])

  return (
    <>
      <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />
      <h2>{application.name}</h2>
      <div className="mb-3">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          className="form-control"
          name="name"
          value={application.name}
          validations={[required]}
          onChange={(e) => setApplication(yhpApplication => ({...yhpApplication, name: e.target.value}))}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="description">Description</label>
        <MarkdownEditor
          type="text"
          className="form-control"
          key={`description-for-${application.name}`}
          name={`description-for-${application.name}`}
          value={application.description}
          onChange={(value) => setApplication(yhpApplication => ({...yhpApplication, description: value}))}
        />
      </div>
      {application.id && (
        <Tabs
            id="application-configuration-tabs"
            defaultActiveKey={activeTabKey}
            activeKey={activeTabKey}
            className="mb-3"
            onSelect={(e) => {
              if (e === 'new_rule') {
                const newName = `New Rule ${application.rules.length}`;
                setApplication(application => ({...application, rules: application.rules.concat({
                  name: newName, 
                  base_group_definition: {},
                  value: '',
                  group_values_by: 'date',
                  calculate: {
                    first_number: {
                      definition: {}
                    },
                    second_number: {
                      definition: {}
                    },
                  }
                })}))

                setActiveTabKey(newName)
              }
              else {
                setActiveTabKey(e)
              }
            }}
            fill>
              
          <Tab eventKey='json_configuration' title='Raw' key='json_configuration'>
          <div key={application.name}>
            
            <form onSubmit={handleSubmit}>

              <div className="mb-3">
                <label htmlFor="rules">Rules</label>
                <textarea
                  type="text"
                  className="form-control"
                  name="rules"
                  value={rulesAsString}
                  onChange={onChangeApplicationRule}
                  validations={[required]}
                  rows={20}
                />
              </div>

              <div className="form-check">
                <input className="form-check-input" 
                  name="published" 
                  type="checkbox" 
                value={application.published_at ? 'on' : ''}
                checked={application.published_at ? true : false}
                onClick={(e) => setApplication(yhpApplication => ({...yhpApplication, published_at: yhpApplication.published_at ? null : new Date()}))}  
                />
                <label htmlFor="published">Published</label>
              </div>

              <div className="mb-3">
                <button
                  className="btn btn-primary btn-block"
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Submit</span>
                </button>
              </div>
            </form>
          </div>
          </Tab>
          {application.rules.map((rule) => (
            <Tab eventKey={rule.name} title={rule.name} key={rule.name}>
              <div className="input-group mb-3">
                <label htmlFor={`${rule.name}-name`}>Name</label>
                <input 
                  type="text" 
                  className="form-control" 
                  value={rule.name} 
                  aria-label="Rule Name" 
                  aria-describedby="Rule Name" 
                  onChange={(e) => setApplication(application => ({...application, rules: 
                    application.rules.map(r => r.name === rule.name ? {...r, name: e.target.value} : r)
                  }))}
                />
              </div>
              <div className="mb-3">
                <label htmlFor={`${rule.name}-group_definitions`}>
                  <h5>Group Definitions</h5>
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  name={`${rule.name}-group_definitions`}
                  value={rule.group_definitions}
                  validations={[required]}
                  rows={10}
                />
              </div>
              <div className="mb-3">
                <label htmlFor={`${rule.name}-group_values_by`}>
                  <h5>Group By</h5>
                </label>
                <div className="form-check form-check-inline">
                  <input 
                    className="form-check-input" type="radio" 
                    value="date"
                    name={`${rule.name}-group_values_by`} 
                    id={`${rule.name}-group_values_by`}  
                    checked={rule.group_values_by === 'date'}
                    onChange={(e) => setApplication(application => ({...application, rules: 
                      application.rules.map(r => r.name === rule.name ? {...r, group_values_by: e.target.value} : r)
                    }))}
                  />
                  <label className="form-check-label" htmlFor={`${rule.name}-group_values_by`}>Date</label>
                </div>
              </div>
              <div className='mb-3'>
                <label htmlFor={`${rule.name}-definition`}>
                  <h5>Result Definition</h5>
                </label>
                {rule.definition ? rule.definition.name : ''} ({rule.measurement_unit_type ? rule.measurement_unit_type.name : ''})
                <button className="btn btn-secondary" onClick={(e) => {
                  if (showUsedDefinitions) {
                    setShowUsedDefinitions('')
                  } else {
                    setShowUsedDefinitions('addCustomSingleDefinition')
                  }}}>Edit</button>
                {showUsedDefinitions && (
                  <>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="useKnownDefinitions" 
                        onChange={(e) => {
                          if (showUsedDefinitions === 'useKnownDefinitions') {
                            setShowUsedDefinitions('addCustomSingleDefinition')
                          } else {
                            setShowUsedDefinitions('useKnownDefinitions')
                          }
                        }} />
                      <label className="form-check-label" htmlFor="useKnownDefinitions">Import definitions I already use</label>
                    </div>
                    {showUsedDefinitions === 'useKnownDefinitions' && (
                    <DefinitionSelector
                      setErrorMessage={setErrorMessage} 
                      setSuccessMessage={setSuccessMessage}
                      onDefinitionsPicked={(definitions) => {
                        setApplication(application => ({...application, rules: 
                          application.rules.map(r => r.name === rule.name ? {...r, definition: definitions[0]} : r)
                        }))
                      }}
                      allowFilter={true}
                      allowOnlySingleSelection={true}
                    />)}
                    {showUsedDefinitions === 'addCustomSingleDefinition' && (
                      <DefinitionPicker 
                          setErrorMessage={setErrorMessage} 
                          setSuccessMessage={setSuccessMessage} 
                          onDefinitionPicked={(newDefinition) => 
                            setApplication(application => ({...application, rules: 
                              application.rules.map(r => r.name === rule.name ? {...r, definition: newDefinition} : r)
                            }))
                          }
                          onMeasurementUnitTypePicked={(measurementUnitTypeName) => {
                            setApplication(application => ({...application, rules: 
                              application.rules.map(r => r.name === rule.name ? {...r, measurement_unit_type: {name: measurementUnitTypeName}} : r)
                            }))
                          }}
                          definitionValue={rule.definition ? rule.definition.name : null}
                          unitMeasurementValue={rule.measurement_unit_type ? rule.measurement_unit_type.name : null}
                        />
                    )}
                   </>
                )}
              </div>
              <div className='mb-3'>
                <label htmlFor={`${rule.name}-calculate`}>
                  <h5>Calculate</h5>
                </label>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio"
                    name={`${rule.name}-value`} id={`${rule.name}-value`}
                    checked={rule.value === 'ratio'}
                    value="ratio"
                    onChange={(e) => setApplication(application => ({...application, rules: 
                      application.rules.map(r => r.name === rule.name ? {...r, value: e.target.value} : r)
                    }))}
                   />
                  <label className="form-check-label" htmlFor={`${rule.name}-value`}>Ratio</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name={`${rule.name}-value`} id="value-subtract" 
                    checked={rule.value === 'subtract'}
                    value="subtract" 
                    onChange={(e) => setApplication(application => ({...application, rules: 
                      application.rules.map(r => r.name === rule.name ? {...r, value: e.target.value} : r)
                    }))}/>
                  <label className="form-check-label" htmlFor="value-subtract">Subtract</label>
                </div>
                <div className="card-group">
                  {rule.calculate && (
                    <>
                  <div className="card bg-primary">
                    <div className="card card-body">
                        {rule.calculate.first_number ? 
                          rule.calculate.first_number.definition.name : 
                          rule.calculate.numerator.definition.name}
                        <button 
                          className="btn btn-secondary" 
                          onClick={(e) => setShowDefinitionsForFirstNumber(!showDefinitionsForFirstNumber)}>
                          Edit Definition
                        </button>
                {showDefinitionsForFirstNumber && (
                  <DefinitionAdderWithChoice
                    setErrorMessage={setErrorMessage} 
                    setSuccessMessage={setSuccessMessage}
                    onDefinitionsPicked={(definitions) => {
                      setApplication(application => ({...application, rules: 
                        application.rules.map(r => r.name === rule.name ? 
                          {...r, calculate: {...r.calculate, 
                            first_number: {...r.calculate.first_number, 
                              definition: definitions[0]
                            }
                           }} : r)
                      }))
                    }}
                    allowFilter={true}
                    allowOnlySingleSelection={true}
                    initialValue={rule.calculate && rule.calculate.first_number ? [rule.calculate.first_number.definition] : []}

                  />
                )}
                    </div>
                  </div>
                  <div className="card bg-primary">
                    <div className="card card-body">
                      {rule.calculate.second_number ? rule.calculate.second_number.definition.name : rule.calculate.denominator.definition.name}
                      <button 
                          className="btn btn-secondary" 
                          onClick={(e) => setShowDefinitionsForSecondNumber(!showDefinitionsForSecondNumber)}>
                          Edit Definition
                        </button>
                          {showDefinitionsForSecondNumber && (
                            <DefinitionAdderWithChoice
                              setErrorMessage={setErrorMessage} 
                              setSuccessMessage={setSuccessMessage}
                              onDefinitionsPicked={(definitions) => {
                                setApplication(application => ({...application, rules: 
                                  application.rules.map(r => r.name === rule.name ? 
                                    {...r, calculate: {...r.calculate, 
                                      second_number: {...r.calculate.second_number, 
                                        definition: definitions[0]
                                      }
                                     }} : r)
                                }))
                              }}
                              allowFilter={true}
                              allowOnlySingleSelection={true}
                              initialValue={rule.calculate && rule.calculate.second_number ? [rule.calculate.second_number.definition] : []}
                            />
                          )}
                    </div>
                  </div>
                  </>
                  )}
                </div>
              </div>
            </Tab>
          ))}
          <Tab eventKey='new_rule' title='+ New Rule' key='new_rule' /> 

      </Tabs>
      )}
    </>
  )
}