import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}/api/auth/`;

class AuthService {
  updatePassword(token, password) {
    return axios.post(`${API_URL}update_password`, { 
      token: token,
      password: password
    });
  }
  
  requestPasswordReset(username) {
    return axios.post(`${API_URL}reset_password`, { 
      username: username,
    });
  }

  resendVerification(username, password) {
    return axios.post(`${API_URL}resend-verification-email`, { 
      username,
      password 
    });
  };

  login(username, password) {
    return axios
      .post(API_URL + "signin", {
        username,
        password
      })
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  impersonate(accessToken, userData) {
    return axios
      .post(API_URL + "impersonate", {
        userData,
      }, { headers: {'x-access-token': accessToken }})
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem('chosenGroup')
      localStorage.removeItem('chosenView')
  }

  verifyEmail(token) {
    return axios.post(API_URL + "verify-email", {
      token: token
    });
  }

  register(username, email, password, inviteUuid) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password,
      inviteUuid
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
}

const authService = new AuthService();
export default authService;