
import moment from "moment"

import React, { useState } from "react";

import { Navigate, Link } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Alert  from "./alert.component";

import AuthService from "../services/auth.service";
import usersService from "../services/users.service";
import inviteService from "../services/invite.service";
import userConfigurationService from '../services/user-configuration.service.js';

export default function Profile()  {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  }); 
  const [redirect, setRedirect] = useState(null);
  const [userReady, setUserReady] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [users, setUsers] = useState([]);
  const [invitingUsers, setInvitingUsers] = useState([]);
  const [rules, setRules] = useState({});
  const [groups, setGroups] = useState({});
  const [configurations, setConfigurations] = useState({});

  const loadConfigurationFromResponse = (configurations) => {
    const userConfiguration = configurations.user_configuration.configuration;
    const groupsWithConfigurations = configurations.groups_with_configurations;

    const localConfigurations = {
      0: userConfiguration
    };

    const localGroups = {
      // 0: {
      //   name: 'YHP Base'
      // }
    }

    let localRules = {
      // 0: configurations.user_configuration_rules
    };

    for (const groupWithConfiguration of [{
      id: 0, 
      name: 'YHP Base', 
      user_configuration_rules: configurations.user_configuration_rules,
      user_group_configuration: configurations.user_configuration
    }, ...groupsWithConfigurations]) {
      localGroups[groupWithConfiguration.id] = groupWithConfiguration;
      localRules[groupWithConfiguration.id] = groupWithConfiguration.user_configuration_rules;
      for (const field of Object.keys(groupWithConfiguration.user_configuration_rules)) {
        if (!localConfigurations[groupWithConfiguration.id]) {
          localConfigurations[groupWithConfiguration.id] = {};
        }
        if (!groupWithConfiguration.user_configuration_rules[field].default_value) {
          switch (groupWithConfiguration.user_configuration_rules[field].type) {
            case 'checkbox':
            case 'boolean':
              groupWithConfiguration.user_configuration_rules[field].default_value = false
              break;
            default:
              groupWithConfiguration.user_configuration_rules[field].default_value = '';
              break;
          }
        }
        localConfigurations[groupWithConfiguration.id][field] = groupWithConfiguration.user_group_configuration ? 
          groupWithConfiguration.user_group_configuration.configuration[field] :
          groupWithConfiguration.user_configuration_rules[field].default_value;
      }
    }
    setGroups(localGroups);
    setRules(localRules);
    setConfigurations(localConfigurations);
  }

  useState(() => {
    const localCurrentUser = AuthService.getCurrentUser();

    if (!localCurrentUser) setRedirect("/home");
    setCurrentUser(localCurrentUser);
    setUserReady(true)

    setLoading(true);

    Promise.all([
      inviteService.getAllInvitedMe(),
      usersService.getUsersWithAccess(),
      userConfigurationService.get()
    ]).then(
      responses => {
        loadConfigurationFromResponse(responses[2].data);
        
        if (responses[1].data.success && responses[1].data.message) {
          setAlert({alert: {...alert, 
            show: true,
            alertType: 'success',
            alertMessage: responses[1].data.message
          }});
        }
        setUsers(responses[1].data.users)
        setInvitingUsers(responses[0].data.invites)
        
        setLoading(false);
      },
      error => {
        setLoading(false);
        setAlert({alert: {...alert, 
          show: true,
          alertType: 'danger',
          alertTitle: 'Error',
          alertMessage: (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }});
      }
    )
  }, []);

  const calculate = (rule, depedencyName, dependencyValue) => {
    const chain = rule.match(/[a-zA-Z0-9_$(),"]*./gm)
    if (!chain) {
      return calculateRule(rule, depedencyName, dependencyValue);
    } else {
      const retValue = chain.reduce((previousValue, command) => {
        const onlyCommand = command.substring(0, command.indexOf('('));
        let values = calculateRule(command, depedencyName, dependencyValue);
        values = values.map((value) => value === '' ? null : value).flat();
        if (previousValue) {
          if (previousValue[onlyCommand] === undefined) {
            return previousValue;
          } else {
            return previousValue[onlyCommand](...values);
          }
        } else {
          return values[0];
        }
      },null);
      return retValue;
    }
    
  }

  const calculateRule = (rule, depedencyName, dependencyValue) => {
    const retValues = [];
    const commands = rule.match(/^[a-zA-Z0-9_]*/g)
    if (commands && commands[0]) {
      let command = commands[0];
      if (rule[command.length] === '(') {

        let bracketsValues = rule.match(/\(([^()]*)\)/g);
        if (bracketsValues) {
          for (const bracketsValue of bracketsValues) {
            let localBracketsValues = bracketsValue.replace(/[()]/g, '').split(',');
            for (const localBracketsValue of localBracketsValues) {
              let retValue = calculateRule(localBracketsValue, depedencyName, dependencyValue);
              
              if (command === 'time') {
                retValues.push(moment(...retValue));
              } else {
                retValues.push(...retValue);
              }
            }
          }
        }
        
        return retValues;
      } else {
        return [rule];
      }
    } else if (rule[0] === '$') {
      const index = rule.indexOf('$');
      let endIndex;
      const endIndexData = rule.substr(index + 1).match(/[^a-z_]/i);
      if (endIndexData !== null) {
        endIndex = index + endIndexData.index + 1;
      } else {
        endIndex = rule.length;
      }
      const variable = rule.substring(index + 1, endIndex);
      switch (variable) {
        case 'current_date':
          return [moment().toISOString()];
        default:
          if (variable === depedencyName) {
            return [dependencyValue];
          }
          break;
      }
    } else {
      return [rule];
    }

  }

  const checkDependencies = (groupIndex, ruleIndex, changingValueRule) => {
    return (e) => {
      e.preventDefault();
      const newConfigurations = {...configurations[groupIndex]}
      
      let newValue;
      if (rules[groupIndex][ruleIndex].type === 'checkbox' || rules[groupIndex][ruleIndex].type === 'boolean') {
        newValue = e.target.checked;
      } else {
        newValue = e.target.value;
      }
      newConfigurations[ruleIndex] = newValue;

      for (const localRuleIndex of Object.keys(rules[groupIndex])) {
        const localRule = rules[groupIndex][localRuleIndex];
        if (localRule.calculate_on_change && localRule.calculate_on_change.includes(ruleIndex)) {
          newValue = calculate(localRule.formula, ruleIndex, e.target.value);
          newConfigurations[localRuleIndex] = newValue;
        }
      }
      setConfigurations({...configurations, [groupIndex]: newConfigurations});
    }
  }

  const submitConfiguration = (groupIndex) => {
    return (e) => {
      e.preventDefault();

      if (!configurations[groupIndex]) {
        setAlert({alert: {...alert,
          show: true,
          alertType: 'danger',
          alertTitle: 'Error',
          alertMessage: 'Configuration not found'
        }});
        return;
      }

      setLoading(true);

      userConfigurationService.update(groupIndex, configurations[groupIndex]).then(
        response => {
          setAlert({alert: {...alert, 
            show: true,
            alertType: 'success',
            alertTitle: 'Success',
            alertMessage: response.data.message
          }});
          loadConfigurationFromResponse(response.data);
          setLoading(false);
        }, error => {
          setLoading(false);
          setAlert({alert: {...alert, 
            show: true,
            alertType: 'danger',
            alertTitle: 'Error',
            alertMessage: (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
          }});
        }
    )}
  }

  const displayTabs = () => {
    return Object.keys(rules).map((groupIndex) => (
      <Tab eventKey={`configuration-group-${groupIndex}`} title={`${groups[groupIndex].name} Configuration`} key={`configuration-group-${groupIndex}`}>
        <form onSubmit={submitConfiguration(groupIndex)} className="">

          {Object.keys(rules[groupIndex]).map((ruleIndex) => {
            const rule = rules[groupIndex][ruleIndex];
            let inputItem;
            switch (rule.type) {
              case 'boolean': 
              case 'checkbox':
                inputItem = (
                <div className="form-check form-switch">
                  <input 
                    className="form-check-input" 
                    type="checkbox" 
                    id={`group-${groupIndex}-rule-${ruleIndex}`} 
                    checked={configurations[groupIndex][ruleIndex] ? true : false}
                    // onClick={checkDependencies(groupIndex, ruleIndex, rule)} 
                    onChange={checkDependencies(groupIndex, ruleIndex, rule)} 
                    // onChange={(e) => {
                    //   setConfigurations({...configurations, 
                    //     [groupIndex]: {...configurations[groupIndex], [ruleIndex]: !configurations[groupIndex][ruleIndex]}})}}
                  />
                  <label className="form-check-label" htmlFor={`group-${groupIndex}-rule-${ruleIndex}`}>{rule.name || ruleIndex}</label>
                </div>)
                break;
              default: {
                inputItem = (
                  <>
                  <label className="col-sm-2" htmlFor={`group-${groupIndex}-rule-${ruleIndex}`}>{rule.name || ruleIndex}</label>
                  <div className="col-sm-10">
                    <input 
                        type={rule.type}
                        className="form-control"
                        id={`group-${groupIndex}-rule-${ruleIndex}`}
                        value={configurations[groupIndex][ruleIndex]}
                        readOnly={rule.calculate}
                        onChange={checkDependencies(groupIndex, ruleIndex, rule)}
                      />
                  </div>
                </>
                )
              }
            }
            return (
              <div className="row mb-3" key={`group-${groupIndex}-rule-${ruleIndex}`}>
                  
                    {inputItem}
                  
              </div>
            )
          })}
            <button
              type="submit"
              className="col-sm-2 btn btn-default btn-primary"
              disabled={loading}
            >
              Update
            </button>
        </form>
      </Tab>
      ))
  }

  return (
    <>
      {loading && (
        <span className="spinner-border spinner-border-sm"></span>
      )}
      {redirect && <Navigate to={redirect} />}
      {!redirect && (
      <>
      <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />
      <div className="container-fluid">
          <Tabs
            id="profile-tabs"
            defaultActiveKey="profile"
            className="mb-3"
            fill
          >
            <Tab eventKey='profile' title='Profile' key='profile'>
              {userReady &&
                <div>
                  <header className="jumbotron">
                    <h3>
                      <strong>{currentUser.username}</strong> Profile
                    </h3>
                  </header>
                  
                  <p>
                    <strong>Id:</strong>{" "}
                    {currentUser.id}
                  </p>
                  <p>
                    <strong>Email:</strong>{" "}
                    {currentUser.email}
                  </p>

                  <strong>Roles:</strong>
                  <ul>
                    {currentUser.roles &&
                      currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
                  </ul>
                      
                  <div className="row">
                    <strong>Users with access to you:</strong>
                    {users.length > 0 && users.map((user, index) => <a href={`/users/${user.username}`} key={user.username}>{user.username}</a>)}
                    {users.length === 0 && (<span>No users have access to you</span>)}
                  </div>

                  <div className="row">

                    <strong>Users who invited you:</strong>
                    {invitingUsers && invitingUsers.length > 0 && invitingUsers.map((user, index) => 
                      <a href={`/users/${user.username}`} key={`inviting-user-${user.username}`}>{user.username}</a>
                      )}
                    {(!invitingUsers || invitingUsers.length === 0) && (<span>No users invited you</span>)}
                  </div>
                  <div className="row">
                    <div className="col-sm-2">
                      <Link 
                        to="/give-access"
                        className="btn btn-primary btn-block"
                        disabled={loading}
                        role="button"
                      >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                        <span>Give more access</span>
                      </Link>
                    </div>
                    <div className="col-sm-2">
                      <Link 
                        to="/invite-users"
                        className="btn btn-secondary btn-green"
                        disabled={loading}
                        role="button"
                      >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                        <span>Invite New Users</span>
                      </Link>
                    </div>

                    
                  </div>
                </div>
                }
            </Tab>

            {Object.keys(groups).length > 0 && Object.keys(rules).length > 0 && displayTabs()}
          
          </Tabs>
        </div>
        </>
      )}
    </>
  );
}
