import moment from 'moment';

import { useState, useEffect } from 'react';

import inviteService from "../services/invite.service";

export default function InviteUsers() {
  const [failureMessage, setFailureMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState("")
  const [existingInvites, setExistingInvites] = useState([])

  useEffect(() => {
    inviteService.getAll().then(
      response => {
        setExistingInvites(response.data.invites);
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )}, [])

  return (
    <>
      {failureMessage && <div className="alert alert-danger" role="alert">{failureMessage}</div>}
      {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
      <a className="btn btn-primary" href="/invites/add" role="button">
        Invite a new user
      </a>
      <h3>Existing Invititations</h3>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-2">Created At</div>
              <div className="col-sm-1">Received?</div>
              <div className="col-sm-2">Email</div>
              <div className="col-sm-1">Action</div>
            </div>
            {existingInvites && existingInvites.map((existingInvitation) => {
              return (
                <div className="row">
                  <div className="col-sm-2">
                    {moment(existingInvitation.created_at).toLocaleString()}
                  </div>
                  <div className="col-sm-1">
                    {existingInvitation.invite_received ? 'Yes' : 'No'}
                  </div>
                  <div className="col-sm-2">
                    {existingInvitation.invited_email}
                  </div>
                  <div className="col-sm-1">
                    <button className="btn btn-primary" onClick={() => {}} disabled={existingInvitation.invite_received}>Retry</button>
                  </div>
                </div>
                )
              })}
            {existingInvites && existingInvites.length === 0 && <div className="row">No existing invitations</div>}
      </div>
    </>
  )
}