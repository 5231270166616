import { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from "react-router-dom";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";

import DefinionSelector from "./definition-selector.component"
import Alert from "./alert.component"

import groupService from '../services/group.service';
import usersService from '../services/users.service';
import groupDefinitionsService from '../services/group-definitions.service';

import addTitleToDefinition from "../utils/add-title-to-definition"
export default function GroupPage() {
  const navigate = useNavigate();

  const [newGroupFlag, setNewGroupFlag] = useState(false);
  const [group, setGroup] = useState({});
  const [name, setName] = useState('');
  const [published, setPublished] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [alreadyPublished, setAlreadyPublished] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showUsedDefinitions, setShowUsedDefinitions] = useState(false)
  const [definitionsPicked, setDefinitionsPicked] = useState([])
  const [localRules, setLocalRules] = useState({})
  const [allLevelsLabels, setAllLevelsLabels] = useState([]);
  const [allNewLevels, setAllNewLevels] = useState([]);
  const [newLevel, setNewLevel] = useState({
    name: '',
    label: '',
    comparator: '>',
    color: 'red'
  });
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });

  let { groupUuid } = useParams();
  let { userName } = useParams();

  useEffect(() => {
    if (!group.id) return;
    if (!group.definitions) return;
    const allLevelsLabels = group.definitions.map((definition) => 
        Object.keys(definition.group_definitions.levels).map((levelKey)=> definition.group_definitions.levels[levelKey].label)
      ).flat()
      .reduce((unique, label) => {
        if (!unique.some((obj) => obj === label)) {
          unique.push(label);
        }
        return unique;
      }, []);
    setAllLevelsLabels(allLevelsLabels)
  }, [group]);

  useEffect(() => {
    if (!groupUuid) {
      if (userName) {
        setAlreadyPublished(true);
        setFailureMessage('Wrong URL. Please use the menu to navigate to the group you want to see.');
        return;
      }
      setGroup({
        name: '',
        published: false
      });
      setName('');
      setPublished(false);
      setAlreadyPublished(false);
      setReadOnly(false);
      setNewGroupFlag(true);
      return;
    }

    let action;
    if (userName) {
      action = usersService.getGroup(userName, groupUuid)
    } else {
      action = groupService.get(groupUuid)
    }
    action.then(
      response => {
        const group = response.data.group;

        for (const definition of group.definitions) {
          addTitleToDefinition(definition)
        }
        setGroup(group);
        setName(group.name);
        setPublished(group.published_at !== null);
        setDefinitionsPicked(group.definitions);
        setLocalRules(JSON.stringify(group.user_configuration_rules, null, 2))
        setAlreadyPublished(userName || group.published || group.publisher_id !== JSON.parse(localStorage.getItem('user')).id);
        setReadOnly(group.publisher_id !== JSON.parse(localStorage.getItem('user')).id);
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )}, [groupUuid, userName])

  
    const hideMessage = () => {
      setAlert(alert => ({...alert, 
        show: false,
      }));
    }
  
    const setFailureMessage = (message) => {
      if (!message) {
        hideMessage();
      } else {
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertTitle: 'Error',
          alertMessage: message
        }));
      }
    }
  
    const setSuccessMessage = (message) => {
      if (!message) {
        hideMessage();
      } else {
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'success',
          alertTitle: 'Success',
          alertMessage: message
        }));
      }
    };
  const handlePublishedClick = (event) => {
    if (alreadyPublished) return false;
    setPublished(!published)
  }

  const onDeleteClicked = (e) => {
    e.preventDefault();


    if (!window.confirm('Delete the group?')) {
      return;
    }
    
    hideMessage();
    setLoading(true);

    groupService.delete(group.id).then(
      
      (response) => {
        if (response.data.success) {
          hideMessage()
          navigate(`/groups`, {state: {message: response.data.message}});
        }
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setFailureMessage(resMessage);
        });
  }

  const onDuplicateClicked = (e) => {
    e.preventDefault();

    hideMessage();
    setLoading(true);

    groupService.duplicateGroup(groupUuid).then(
      
      (response) => {
        if (response.data.success) {
          hideMessage()
          navigate(`/dashboard/${response.data.group.uuid}`, {state: {message: response.data.message}});
        }
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setFailureMessage(resMessage);
        });
  }

  const onLocalRulesChanged = (e) => {
    e.preventDefault();
    try {
      JSON.parse(e.target.value);
      // if no exception
      setGroup({...group, user_configuration_rules: JSON.parse(e.target.value)})
      hideMessage();
    } catch(error) {
      setFailureMessage('JSON is not valid');
    }
    setLocalRules(e.target.value);
  }

  const handleNameChange = (event) => {
    event.preventDefault();
    setName(event.target.value)
  }

  const handleSubmit= (e) => {
    e.preventDefault();

    if (!name) {
      setFailureMessage("Name is required");
      return;
    }

    hideMessage()

    setLoading(true);

    let remoteAction;

    if (newGroupFlag) {
      const newGroup = {
        name: name,
        published: published,
        user_configuration_rules: group.user_configuration_rules
      }
      remoteAction = groupService.create(newGroup);
    } else {
      remoteAction = groupService.update(group.uuid, {published: published, user_configuration_rules: group.user_configuration_rules});
    }

    let boundaryAction;
        if (allNewLevels.length > 0) {
          boundaryAction = groupDefinitionsService.updateLevels(group.uuid, allNewLevels);
        } else {
          boundaryAction = Promise.resolve();
        }
        boundaryAction.then(
          () => {
            remoteAction.then(
              (response) => {
                if (newGroupFlag) {
                  navigate(`/groups/${response.data.group.uuid}`, {state: {message: response.data.message}});
                }
                if (response.data.success && response.data.message) {
                  setSuccessMessage(response.data.message)
                }
                const updatedGroup = response.data.group;
                setGroup(updatedGroup);
                setName(updatedGroup.name);
                setLoading(false);
                setPublished(updatedGroup.published);
                setReadOnly(updatedGroup.publisher_id !== JSON.parse(localStorage.getItem('user')).id);
                setAlreadyPublished(updatedGroup.published || updatedGroup.publisher_id !== JSON.parse(localStorage.getItem('user')).id);
                setNewGroupFlag(false);
              }, error => {
                const resMessage =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();

                setLoading(false);
                setFailureMessage(resMessage);
              })
          }, error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            setLoading(false);
            setFailureMessage(resMessage);
          });
    
  }

  const handleUseKnownDefinitionsChange = (event) => {
    setShowUsedDefinitions(event.target.checked);
  }

  const handleDefinitionPicked = (pickedDefinitions) => {
    setDefinitionsPicked(pickedDefinitions);
  }

  const onImportDefinitionsClicked = (e) => {
    e.preventDefault();
    hideMessage()
    setLoading(true);

    groupService.bulkCreateInitialBounderies(group.id, definitionsPicked).then(
      (response) => {
        if (response.data.success && response.data.message) {
          setSuccessMessage(response.data.message)
        }
        setLoading(false);
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setFailureMessage(resMessage);
        });
  }

  const addRuleClicked = (e) => {
    e.preventDefault();
    navigate(`/groups/${group.id}/rules/new`);
  };

  const addBoundaryClicked = (e) => {
    e.preventDefault();
    setAllNewLevels([...allNewLevels, newLevel]);
    setAllLevelsLabels([...allLevelsLabels, newLevel.name]);
    const newGroup = {...group}
    newGroup.definitions = newGroup.definitions.map((definition) => {
      if (!definition.group_definitions) {
        definition.group_definitions = {};
      }
      if (!definition.group_definitions.levels) {
        definition.group_definitions.levels = {};
      }
      definition.group_definitions.levels[newLevel.name] = {label: newLevel.label, comparator: newLevel.comparator, color: newLevel.color}
      return definition;
    });
    setGroup(newGroup)
    setNewLevel({
      name: '',
      label: '',
      comparator: '>',
      color: 'red'
    })
  }

  return (
    <>
      <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />
      {!readOnly && alreadyPublished && <div className="alert alert-info">This group is already published so be careful when editing</div>}
      {readOnly && <div className="alert alert-info">This group was published by someone else, you can not change it</div>}
      {Object.keys(group).length > 0 &&
         (
          <div key={group.uuid} className='col-xs-12 col-sm-12'>
            <h2>{group.name}</h2>
            <Form onSubmit={handleSubmit} className='form-horizontal'>

              <div className="mb-3">
                <div className="col-xs-3 text-right">
                  <label htmlFor="name">Name</label>
                </div>
                <div className="col-xs-9">
                  <Input
                    type="text"
                    className="form-control"
                    name="name"
                    value={name}
                    readOnly={alreadyPublished || readOnly}
                    onChange={handleNameChange}
                  />
                  {group && group.publisher && (<span>by <Link to={`/profiles/${group.publisher.username}`}>{group.publisher.username}</Link></span>)}
                </div>
              </div>


              {!newGroupFlag && 
              <>
                <div className='mb-3'>
                  <h4>Rules</h4>
                  <ul className="list-group">
                  { group && group.group_definitions_rules &&
                    group.group_definitions_rules.map((groupDefinitionRule) => 
                        <li className="list-group-item mb-2" key={groupDefinitionRule.name}>
                          <a href={`/groups/${group.id}/rules/${groupDefinitionRule.id}`}>{groupDefinitionRule.name}</a>
                        </li>

                      )
                  }
                  </ul>
                  {!readOnly && <button
                    onClick={addRuleClicked}>
                      <i className="bi bi-clipboard2-plus-fill"></i> Add Rule
                  </button>
                  }
                </div>
                <div className='mb-3'>
                  <h4>Boundaries Names</h4>
                  <ul className="list-group">
                  { allLevelsLabels && allLevelsLabels.length > 0 &&
                    allLevelsLabels.map((label, index) => 
                        <li className="list-group-item mb-2" key={`${label}_${index}`}>
                          <span>{label}</span>
                        </li>

                      )
                  }
                  </ul>
                  {!readOnly && 
                  <div className="input-group mb-3">
                    <input 
                      type="text" 
                      className="form-control" 
                      placeholder="New boundary name" 
                      aria-label="Add boundary name" 
                      aria-describedby="button-add-boundary-name"
                      value={newLevel.name}
                      onChange={(e) => setNewLevel({...newLevel, name: e.target.value})}

                    >

                    </input>
                    <input 
                      type="text" 
                      className="form-control" 
                      placeholder="New boundary label" 
                      aria-label="Add boundary label" 
                      aria-describedby="button-add-boundary-label"
                      value={newLevel.label}
                      onChange={(e) => setNewLevel({...newLevel, label: e.target.value})}
                      >
                    </input>
                    <select 
                      className="form-select" 
                      aria-label="New boundary select comparator"
                      value={newLevel.comparator}
                      defaultValue=">"
                      onChange={(e) => setNewLevel({...newLevel, comparator: e.target.value})}
                      >
                      <option value=">">Greater Than ({'>'})</option>
                      <option value=">=">Greater Than Or Equal ({'>='})</option>
                      <option value="<">Less Than ({'<'})</option>
                      <option value="<=">Less Than Or Equal ({'<='})</option>
                      <option value="=">Equal ({'='})</option>
                      <option value="!=">Not Equal ({'!='})</option>
                    </select>
                    <select 
                      className="form-select" 
                      aria-label="New boundary select color"
                      value={newLevel.color}
                      defaultValue="Red"
                      onChange={(e) => setNewLevel({...newLevel, color: e.target.value})}
                      >
                      <option selected value="red">Red</option>
                      <option value="orange">Orange</option>
                      <option value="green">Green</option>
                      <option value="blue">Blue</option>
                    </select>
                    <button
                      className="btn btn-outline-secondary" 
                      type="button" 
                      id="button-add-boundary-name"
                      onClick={addBoundaryClicked}>
                        <i className="bi bi-clipboard2-plus-fill"></i> Add Boundary 
                    </button>
                    
                  </div>
                  }
                </div>
                <div className="mb-3">
                  <h4>Definitions Boundaries</h4>

                    {!readOnly && 
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="useKnownDefinitions" onChange={handleUseKnownDefinitionsChange} />
                      <label className="form-check-label" htmlFor="useKnownDefinitions">Import definitions I already use</label>
                    </div>
                    }

                    {showUsedDefinitions &&
                      <>
                        <button className="btn btn-primary btn-block" onClick={onImportDefinitionsClicked} disabled={loading}>Import</button>
                        <DefinionSelector
                          setFailureMessage={setFailureMessage} 
                          setSuccessMessage={setSuccessMessage}
                          onDefinitionsPicked={handleDefinitionPicked}
                          initialValue={definitionsPicked}
                        />
                        <button className="btn btn-primary btn-block" onClick={onImportDefinitionsClicked} disabled={loading}>Import</button>
                      </>
                    }

                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Definition</th>
                        <th scope="col">Retest Period</th>
                        <th scope="col">Rule Name</th>
                        {
                          allLevelsLabels.map((label) => (
                            <th scope="col">{label}</th>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody>
                    {group.definitions && group.definitions.map((definition, index) => {
                      let definitionRow = (
                      <tr key={definition.id}>
                        <th scope="row" >{index}</th>
                        <td>{definition.title}</td>
                        <td>{definition.group_definitions ? definition.group_definitions.retest_period_in_days : ''}</td>
                        <td>{definition.rule_name}</td>
                        {
                          allLevelsLabels.map((levelLabel) => {
                            const groupDefinitionLevel = definition.group_definitions.levels ? 
                              Object.keys(definition.group_definitions.levels).find((key) => definition.group_definitions.levels[key].label === levelLabel) :
                              null
                            return (
                              <td>{groupDefinitionLevel ? definition.group_definitions.levels[groupDefinitionLevel].value : ''}</td>
                            )
                          })
                        }
                      </tr>
                      )
                      if (definition.group_definitions && definition.group_definitions.boundaries_by_rules) {
                        const boundariesByRulesRow = (
                      
                          Object.keys(definition.group_definitions.boundaries_by_rules).map((ruleName) => (
                            <tr key={`boundary_${ruleName}`}>
                              <th scope="row" >{index}</th>
                              <td>{definition.title}</td>
                              <td>{definition.group_definitions.boundaries_by_rules[ruleName].retest_period_in_days}</td>
                              <td>{ruleName}</td>
                              {
                                allLevelsLabels.map((levelLabel) => {
                                  let groupDefinitionLevel
                                  
                                  if (definition.group_definitions.boundaries_by_rules && definition.group_definitions.boundaries_by_rules[ruleName] && 
                                    definition.group_definitions.boundaries_by_rules[ruleName].levels) {
                                    groupDefinitionLevel = Object.keys(definition.group_definitions.boundaries_by_rules[ruleName].levels).find((key) => definition.group_definitions.levels[key].label === levelLabel)
                                  } else {
                                    groupDefinitionLevel = null;
                                  }

                                  return (
                                    <td>{groupDefinitionLevel ? groupDefinitionLevel.value : ''}</td>
                                  )
                                })
                              }
                            </tr>
                          )
                        
                        ))

                        definitionRow = (<>{definitionRow}{boundariesByRulesRow}</>)
                      }

                      return definitionRow;
                    })}
                    </tbody>
                  </table>
                </div>
                {!readOnly && 
                <div className="btn-group mb-3" role="group" aria-label="boundaries actions">
                  <a className="btn btn-secondary" href={`/groups/${group.uuid}/definitions/`} role="button">Change Boundaries Values</a>
                </div>
                }

                <div className="mb-3">
                  <h4>Configuration</h4>
                    <pre>
                      <textarea 
                        className="form-control mb-3" 
                        id="configuration" 
                        aria-describedby="configuration" 
                        value={localRules}
                        onChange={onLocalRulesChanged}
                        readOnly={alreadyPublished || readOnly}
                      />
                    </pre>
                </div>

                <div className="form-check mb-3">
                  <input className="form-check-input" 
                    name="published" 
                    type="checkbox" 
                    value={published}
                    onClick={handlePublishedClick} 
                    checked={published}
                    onChange={handlePublishedClick}
                    readOnly={alreadyPublished || readOnly} />
                  <label htmlFor="published">Published</label>
                </div>


              </>}

              <div className="btn-toolbar" role="toolbar">
                <div className="btn-group mr-2" role="group">
                  <button
                    className="btn btn-secondary me-2"
                    disabled={loading || newGroupFlag}
                    onClick={onDuplicateClicked}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Duplicate</span>
                  </button>

                  {!readOnly &&
                  <button
                    className="btn btn-primary me-2"
                    disabled={loading}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Submit</span>
                  </button>
                  }
                  {!readOnly &&
                  <button
                    className="btn btn-danger me-2"
                    disabled={loading || newGroupFlag}
                    onClick={onDeleteClicked}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Delete</span>
                  </button>
                  }
                </div>
              </div>
            </Form>
          </div>)}
    </>
  )
}