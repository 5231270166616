export default function CategoriesList({categories, baseUrl}) {
  return (
    <div className="list-group mb-3" name="categories-list">
      {categories && categories.map((category) => (
        <a key={category.name} href={`${baseUrl}/categories/${category.id}`} className="list-group-item list-group-item-action">
          {category.name}
        </a>
      ))}
    </div>
  )
}