import { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";

import userService from '../services/user.service';

export default function UserProfile() {
  const [user, setUser] = useState();
  const [message, setMessage] = useState("")

  let { userName } = useParams();

  useEffect(() => {
    userService.get(userName).then(
      response => {
        const user = response.data.user;
        setUser(user);
      },
      error => {
        setMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )}, [userName])

  return (
    <>
      {message && <div className="alert alert-danger" role="alert">{message}</div>}
      {user &&
         (
          <div key={user.username}>
            <h2>{user.username}</h2>
            <dl className="row">
              <dt className="col-sm-3">Groups Published:</dt>
              <dd className="col-sm-9">
                <ul>
                  {(user.published_groups && user.published_groups.map((publishedGroup) => (<li><Link to={`/groups/${publishedGroup.name}`}>{publishedGroup.name}</Link></li>)))}
                </ul>
              </dd>
              <dt className="col-sm-3">Categories Published:</dt>
              <dd className="col-sm-9">
                <ul>
                 {(user.published_categories && user.published_categories.map(
                  (category) => (
                    <li><Link to={`/categories/${category.id}`}>{category.name}</Link></li>
                  ))
                )}

                </ul>
              </dd>
            </dl>
          </div>
         )
      }
    </>
  )
}