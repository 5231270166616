import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import Alert from './alert.component';

import userService from "../services/user.service";
import viewService from "../services/view.service";

export default function ViewsPage() {
  const [userViews, setUserViews] = useState([])
  const [allViews, setAllViews] = useState([])
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });

  let { viewUuid } = useParams();

  useEffect(() => {
    setLoading(true);

    Promise.all([
      userService.getViews(viewUuid),
      viewService.getAll()
    ]).then(
      responses => {
        setLoading(false);
        setUserViews(responses[0].data.views);
        setAllViews(responses[1].data.views)
      },
      error => {
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertMessage: (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }));
      }
    )}, [viewUuid])

  const removeView = (viewUuid) => {
    setAlert(alert => ({...alert, show: false}));
    setLoading(true);
    userService.removeView(viewUuid).then(
      response => {
        setUserViews(response.data.views)
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'success',
          alertTitle: 'Success',
          alertMessage: response.message
        }));
      },
      error => {
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertTitle: 'Error',
          alertMessage: (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }));
      }
    )
  }

  const addView = (viewUuid) => {
    setAlert(alert => ({...alert, show: false}));
    setLoading(true);

    userService.addView(viewUuid).then(
      response => {
        setUserViews(response.data.views)
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'success',
          alertTitle: 'Success',
          alertMessage: response.message
        }));
      },
      error => {
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertTitle: 'Error',
          alertMessage: (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }));
      }
    )
  }

  return (
    <>
      <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />
      {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
      <div className="container">
        <div className="row">
          <div className="col-sm-2">View</div>
          <div className="col-sm-2">Installed?</div>
          <div className="col-sm-2">Publisher</div>
        </div>
      {allViews && userViews && allViews.map((view) => {
        const installed = !!(userViews.find((userView) => userView.name === view.name))
        return (
        <div className="row">
          <div className="col-sm-2"><Link to={`/views/${view.uuid}`} >{view.name}</Link></div>
          <div className="col-sm-2">{ installed ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-check" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
  <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
  <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"/>
</svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-x" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M6.146 6.146a.5.5 0 0 1 .708 0L8 7.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 8l1.147 1.146a.5.5 0 0 1-.708.708L8 8.707 6.854 9.854a.5.5 0 0 1-.708-.708L7.293 8 6.146 6.854a.5.5 0 0 1 0-.708"/>
  <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
  <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"/>
</svg>}
          </div>
          
          <div className="col">
            <Link to={`/users/${view.publisher.username}`}>{view.publisher.username}</Link>
          </div>
          <div className="col">
            {installed ? 
              <button type="button" key={`add-button-${view.name}`} className="btn btn-primary" onClick={() => {removeView(view.uuid)}}>
                Remove {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
              </button> :
              <button type="button" key={`remove-button-${view.name}`} className="btn btn-primary" onClick={() => {addView(view.uuid)}}>
                Add {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
              </button>}
              <a href={`/share/view/${view.uuid}`} className="btn btn-info" key={`share-button-${view.name}`}>
                Share {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
              </a>
          </div>
        </div>
        )
      })}
      <a className="btn btn-secondary" href={`/views/add`} role="button">Add View</a>
      </div>
    </>
  )
}