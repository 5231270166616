import moment from 'moment';

import { useState, useEffect, useMemo, useCallback, useRef } from 'react';

import userEntriesService from '../services/user-entries-service';

export default function EntriesSheet({defaultTitle = "Entries Sheet", presetDefinition, unitMeasurementValue, presetEntries, setErrorMessage, setSuccessMessage, allowExtraActions = true}) {
  const [entries, setEntries] = useState({});
  const [loading, setLoading] = useState(false)
  const [entriesDates, setEntriesDates] = useState([]);
  const [allDefinitions, setAllDefinitions] = useState({});
  const [filteredDefinitions, setFilteredDefinitions] = useState([]);
  const [editableCell, setEditableCell] = useState({})
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [newDate, setNewDate] = useState(moment().format('YYYY-MM-DD'))
  const [showMergeCells, setShowMergeCells] = useState(false)
  const [mergeIntoDefinitionId, setMergeIntoDefinitionId] = useState(0)
  const [mergeFromDefinitions, setMergeFromDefinitions] = useState({})
  
  const dateRef = useRef()

  useEffect(() => {
    if (showDatePicker && dateRef && dateRef.current) dateRef.current.focus();
  }, [showDatePicker])


  useEffect(() => {

    let action;
    if (presetEntries && presetEntries.length > 0) {
      action = Promise.resolve({data: {entries: presetEntries}});
    } else if (presetDefinition) {
      action = userEntriesService.getByDefinitionId(presetDefinition.id);
    } else {
      action = userEntriesService.getAll()
    }

    setLoading(true);

    action.then(
      response => {
        const localEntries = {};
        let localEntryDates = [];
        const localDefinitions = {};

        response.data.entries.forEach((entry) => {
          if (!entry.definition) {
            return;
          }
          localDefinitions[entry.definition.id] = entry.definition;
          localDefinitions[entry.definition.id].key = entry.definition.id;
          
          if (!localEntries[entry.definition.id]) {
            localEntries[entry.definition.id] = {};
          }
          const entryDate = moment(entry.date_taken).format('DD-MM-YYYY');
          if (!localEntries[entry.definition.id][entryDate]) {
            localEntries[entry.definition.id][entryDate] = [];
            if (!localEntryDates.includes(entryDate)) {
              localEntryDates.push(entryDate);
            }
          }
          localEntries[entry.definition.id][entryDate].push(entry);
        })
        setEntries(localEntries)
        localEntryDates = localEntryDates.sort((a,b) => {
            return moment(a, 'DD-MM-YYYY').diff(moment(b, 'DD-MM-YYYY'))
        }).reverse();
        setEntriesDates(localEntryDates)
        setAllDefinitions(localDefinitions)
        setFilteredDefinitions(localDefinitions);
        setLoading(false);
      },
      error => {
        setErrorMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
        setLoading(false);
      }
    )
  }, [presetEntries]);

  const setAutoComplete = (e) => {
    if (allDefinitions.length === 0) return;

    setLoading(true);

    let text = e.target.value.toLowerCase();
    if (text === '') {
      setFilteredDefinitions(JSON.parse(JSON.stringify(allDefinitions)))
      setLoading(false);
      return;
    }
    const localFilteredDefinitions = Object.keys(allDefinitions).filter((definitionId) => allDefinitions[definitionId].name.toLowerCase().includes(text))
      .reduce((cur, key) => { return Object.assign(cur, { [key]: allDefinitions[key] })}, {});
    // allDefinitions.filter((searchedName) => searchedName.name.toLowerCase().includes(text.toLowerCase()))
    setFilteredDefinitions(localFilteredDefinitions || [] );
    setLoading(false);
  }

  const handleAddTestDate = () => {
    const newDate = moment().format('DD-MM-YYYY');
    if (entriesDates[0] === newDate) {
      setErrorMessage('Date already exists');
      return;
    }
    setEntriesDates([newDate, ...entriesDates]);
  }

  const handleAddDefinition = () => {
    
  }

  const handleDateClicked = (date) => {
    return (e) => {
      if (showDatePicker) {

      } else {
        setShowDatePicker(true);
      }
    }
  };

  const handleDateBlur = (date) => {
    return (e) => {
      if (entriesDates[0] !== e.target.value) {
        const localNewDate = moment(e.target.value, 'YYYY-MM-DD'); // this is always the format of input date
        setEntriesDates([localNewDate.format('DD-MM-YYYY'), ...entriesDates.slice(1, entriesDates.length)]);
      }
      setShowDatePicker(false)
    }
  }

  const handleDateChange = (e) => {
    setNewDate(e.target.value)
    if (moment(e.target.value, 'YYYY-MM-DD').isValid()) {
      setEntriesDates([e.target.value, ...entriesDates.slice(1, entriesDates.length)]);
    }
  }

  const handleClickedCell = (entryId, definitionId, date) => {
    return (e) => {
      setEditableCell({entryId: entryId, definitionId: definitionId, date: date})
    }
  }

  const handleCellChange = (entryId, definitionId, date) => {
    return (e) => {
      const localEntries = {...entries}
      let doUpdate = entryId && entries[definitionId][date] && entries[definitionId][date].length > 0;
      if (doUpdate) {
        localEntries[definitionId][date].forEach((entry) => {
          if (entry.id === entryId) {
            entry.value = e.target.value
            entry.values = [{value: e.target.value}];
          }
        });
      }

      setEntries(localEntries);
    }
  }

  const updateEntry = async(entryId, value) => {
    if (value === '') return;
    setLoading(true);
    userEntriesService.update(entryId, {value: value}).then(
      response => {
        setSuccessMessage(response.data.message)
        setLoading(false);
      },
      error => {
        setLoading(false);
        setErrorMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )
  }

  const createEntry = async(definitionId, date, value) => {
    if (value === '') return;
    setLoading(true);
    userEntriesService.create({definition_id: definitionId, date_taken: date, value: value}).then(
      response => {
        const localEntries = {...entries}
        localEntries[definitionId][date] = [response.data.entry]; 
        setEntries(localEntries);
        setSuccessMessage(response.data.message)
        setLoading(false);
      },
      error => {
        setLoading(false);
        setErrorMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )
  }

  // idealy only update cells and entries AFTER server was updated successfully
  const handleCellKeyDown = (entryId, definitionId, date) => {
    return (e) => {
      if (e.keyCode === 13 || e.keyCode === 27) {
        let doUpdate = entryId && entries[definitionId][date] && entries[definitionId][date].length > 0;
        setEditableCell({})
        
        if (doUpdate) {
          updateEntry(entryId, e.target.value)
          const localEntries = {...entries}
          localEntries[definitionId][date].forEach((entry) => {
            if (entry.id === entryId) {
              entry.value = e.target.value
              entry.values = [{value: e.target.value}]
            }
          });
          setEntries(localEntries);
        } else {
          createEntry(definitionId, date, e.target.value)
        }
      }
    }
  }

  // idealy only update cells and entries AFTER server was updated successfully
  const handleCellBlur = (entryId, definitionId, date) => {
    return (e) => {
      let doUpdate = entryId && entries[definitionId][date] && entries[definitionId][date].length > 0;
      setEditableCell({})
      
      
      if (doUpdate) {
        updateEntry(entryId, e.target.value)
        const localEntries = {...entries}
        localEntries[definitionId][date].forEach((entry) => {
          if (entry.id === entryId) {
            entry.value = e.target.value
            entry.values = [{value: e.target.value}]
          }
        });
        setEntries(localEntries);
      } else {
        createEntry(definitionId, date, e.target.value)
      }
    }
  }

  const handleMergeDefinitions = (e) => {
    if (window.confirm('Are you sure you want to merge these definitions?')) {
      setLoading(true);
      const mergeFromDefinitionIds = Object.keys(mergeFromDefinitions).filter((definitionId) => mergeFromDefinitions[definitionId]);
      userEntriesService.mergeEntriesByDefinitions(mergeFromDefinitionIds, mergeIntoDefinitionId).then(
        response => {
          setShowMergeCells(false);
          setMergeIntoDefinitionId(0);
          setMergeFromDefinitions({});
          setSuccessMessage(response.data.message)
          setLoading(false);
        },
        error => {
          setLoading(false);
          setErrorMessage(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
          );
        }
      )
    } else {
      alert('Merging aborted')
    }
  }

  const drawHeaders = useMemo(() => (
    <tr>
      <th>Definition</th>
      <th>Unit</th>
      <th>
        <i 
          className="bi bi-calendar-plus"
          onClick={handleAddTestDate}
        >
        </i>
      </th>
      {entriesDates.map((date, index) => {
        return (
          <th 
            key={index} 
            style={{'whiteSpace': 'nowrap'}}
            value={date}
            onClick={handleDateClicked(date)}
            onBlur={handleDateBlur(date)}
            >
              {index === 0 && showDatePicker && 
                <input 
                  ref={dateRef}
                  type="date" 
                  id="new-datepicker" 
                  value={newDate} 
                  onBlur={handleDateBlur(date)} 
                  onChange={handleDateChange} 
                />}
              {(index > 0 || !showDatePicker) && date}
          </th>
        )
      })}
    </tr>
  ), [entriesDates, showDatePicker, showMergeCells]);

  const displayRowCells = useCallback((definition, index) => {
    return entriesDates.map((date, entriesDateIndex) => {
      if (!entries[definition.id][date] || entries[definition.id][date].length === 0) {
        let editable = editableCell.definitionId === definition.id && editableCell.date === date;
        return <td 
            key={`data-${definition.id}-${entriesDateIndex}`} 
            style={{'whiteSpace': 'nowrap'}} 
            value={''}
            onClick={handleClickedCell(null, definition.id, date)}>
              { editable && (
                <input 
                  type="text" 
                  // value={value} 
                  onChange={handleCellChange(null, definition.id, date)}
                  onKeyDown={handleCellKeyDown(null, definition.id, date)}
                  onBlur={handleCellBlur(null, definition.id, date)}
                />
              )}
              { !editable && (
                <span>
                </span>
              )}
          </td>;
      }
      const returnedCells = entries[definition.id][date].map((entry, index) => {
        let value = entry.values.length > 0 ? entry.values[0].value : '';
        let editable = editableCell.entryId === entry.id && editableCell.definitionId === definition.id && editableCell.date === date;
        return (
          <td 
            key={`data-${entry.id}-${index}`} 
            style={{'whiteSpace': 'nowrap'}} 
            value={value}
            onClick={handleClickedCell(entry.id, definition.id, date)}
            >
              { editable && (
                <input 
                  type="text" 
                  value={value} 
                  onChange={handleCellChange(entry.id, definition.id, date)}
                  onKeyDown={handleCellKeyDown(entry.id, definition.id, date)}
                  onBlur={handleCellBlur(entry.id, definition.id, date)}
                />
              )}
              { !editable && (
                <span value={value}>
                  {value} 
                </span>
              )}
          </td>
        )
      })

      if (returnedCells.length > 1) {
        return (
          <td>
            <table className="table table-striped-columns table-hover">
              <tbody>
                {returnedCells.map((cell, index) => (
                  <tr key={`sub-row-${index}`}>
                    {cell}
                  </tr>
                ))}
              </tbody>
            </table>
          </td>
        )
      }
      return returnedCells;
    })
  });

  return (
    <>
      <h3>{defaultTitle}</h3>
      {loading && (
        <span className="spinner-border spinner-border-sm"></span>
      )}
      {allowExtraActions && (
      <div className="input-group mb-3">
        <input type="text" className="form-control" placeholder="Filter" aria-label="Filter" aria-describedby="Filter Definitions" 
          onChange={setAutoComplete}
          />
      </div>
      )}
      <table className="table table-striped-columns table-hover">
        <thead>
          {entriesDates.length > 0 && drawHeaders}
        </thead>
        <tbody>
          {allowExtraActions && (
          <tr>
            <td>
              <i 
                className="bi bi-bag-plus"
                onClick={handleAddDefinition}
              />
            </td>
          </tr>
          )}
          {Object.keys(filteredDefinitions).map((definitionId, index) => {
            const definition = filteredDefinitions[definitionId];
            return (  
              <tr key={`tr-${definitionId}`}>
                <td>
                  {showMergeCells && (
                    <div className="form-check">
                      <input className="form-check-input" 
                        type="checkbox" value="" id={`mergeFromDefinition-${definition.id}`} 
                        checked={mergeFromDefinitions[definition.id]}
                        onClick={(e) => {
                          setMergeFromDefinitions({...mergeFromDefinitions, [definition.id]: !mergeFromDefinitions[definition.id]})
                        }}
                      />
                      <label className="form-check-label" htmlFor="flexCheckIndeterminate">
                        Merge from
                      </label>
                      <input 
                        className="form-check-input" 
                        type="radio" 
                        name="mergeIntoDefinition"
                        id={`mergeIntoDefinition-${definition.id}`}  
                        onClick={(e) => setMergeIntoDefinitionId(definition.id)} />
                      <label className="form-check-label" htmlFor="mergeIntoDefinition">Merge to</label>
                    </div>
                  )}
                  {definition.name}
                </td>
                <td>{definition.measurement_unit_types && definition.measurement_unit_types.length > 0 ? definition.measurement_unit_types[0].name : ''}</td>
                <td></td>
                {displayRowCells(definition, index)}
              </tr>
                
            )
          })}
        </tbody>
      </table>
      {allowExtraActions && (
      <div className="input-group mb-3">
        <div className="form-check form-switch">
          <input className="form-check-input" type="checkbox" id="allowMergeCells" onClick={(e) => setShowMergeCells(!showMergeCells)} />
          <label className="form-check-label" htmlFor="allowMergeCells">Allow cell merging</label>
          {mergeIntoDefinitionId !== 0 && Object.keys(mergeFromDefinitions).length > 0 && (
            <button
              className="btn btn-primary btn-block"
              disabled={loading}
              onClick={handleMergeDefinitions}>
              Merge
            </button>
            )}
        </div>
      </div>
      )}
    </>
  ) 
}