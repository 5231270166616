import api from '../interceptors/auth.interceptor';

class ShareService {

  getByTypeAndName(itemType, itemName) {
    return api.get(process.env.REACT_APP_API_URL + `/api/shares/${itemType}/${itemName}`)
  }
  
  get(shareUuid) {
    return api.get(process.env.REACT_APP_API_URL + `/api/shares/${shareUuid}`)
  }

  getAll() {
    return api.get(process.env.REACT_APP_API_URL + `/api/shares`)
  }

  accept(shareUuid) {
    return api.post(`${process.env.REACT_APP_API_URL}/api/shares/${shareUuid}/accept`)
  }

  reject(shareUuid) {
    return api.post(`${process.env.REACT_APP_API_URL}/api/shares/${shareUuid}/reject`)
  }

  create(itemType, itemName, emailList, itemId, itemUuid, isPublic) {
    const shareData = {
      item_type: itemType,
      emails: emailList,
      public: isPublic
    }

    let idDataExists = false;

    if (itemName) {
      shareData.item_name = itemName;
      idDataExists= true;
    }

    if (itemId) {
      shareData.item_id = itemId;
      idDataExists= true;
    }

    if (itemUuid) {
      shareData.item_uuid = itemUuid;
      idDataExists= true;
    }

    if (!idDataExists) throw new Error("Missing ID");

    return api.post(`${process.env.REACT_APP_API_URL}/api/shares`, {
      share: shareData
    })
  }
}

const shareService = new ShareService();
export default shareService;