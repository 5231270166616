import api from '../interceptors/auth.interceptor';

class DeveloperService {
  getUserDeveloperAccount() {
    return api.get(process.env.REACT_APP_API_URL + '/api/developer/');
  }

  getDeveloperApplications(applicationId) {
    return api.get(process.env.REACT_APP_API_URL + `/api/developer/applications/${applicationId}`)
  }

  updateApplication(applicationId, applicationData) {
    return api.post(process.env.REACT_APP_API_URL + `/api/developer/applications/${applicationId}`, applicationData);
  }

  create(developerAccountName) {
    return api.post(process.env.REACT_APP_API_URL + `/api/developers/`, {
      developer_account: {
        name: developerAccountName
      }
    })
  }
}


const developerService = new DeveloperService();
export default developerService;