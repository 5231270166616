import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import usersAccessRightsService from '../services/user-access-rights.service';

export default function AccessRightsTags() {
  const [failureMessage, setFailureMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState("")
  const [users, setUsers] = useState([])
  

  let { tag } = useParams();

  useEffect(() => {
      usersAccessRightsService.get(tag).then(
        response => {
          const accessRights = response.data.access_rights;
          const users = accessRights.map((accessRight) => accessRight.user_giving_rights)
          setUsers(users);
        },
        error => {
          setFailureMessage(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
          );
        }
    )}, [tag])

  return (
    <>
      {failureMessage && <div className="alert alert-danger" role="alert">{failureMessage}</div>}
      {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
      <h3>Users</h3>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Username</th>
              <th scope="col">Flags</th>
            </tr>
          </thead>
          <tbody>
            {users && users.map((user, index) => (
            <tr key={user.username}>
              <td>
                {index + 1}
              </td>
              <td>
                <a href={`/users/${user.username}`}>{user.username}</a>
              </td>
              <td>
                flags
              </td>
            </tr>
            ))}
          </tbody>
        </table>
        {users && users.length === 0 && <div className="row">No users found</div>}
    </>
  )
}