import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import usersService from "../services/users.service";
import usersAccessRightsService from "../services/user-access-rights.service";
import BoardUser from "./board-user.component";

export default function OtherUser() {
  const [loading, setLoading] = useState(false)
  const [yhpApplications, setYhpApplications] = useState([])
  const [groups, setGroups] = useState([]);
  const [categories, setCategories] = useState([]);
  const [accessRights, setAccessRights] = useState([]);
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState("")

  const {userName} = useParams();

  useEffect(() => {
    setLoading(true);
    usersAccessRightsService.getGivenToMe(userName).then(
      response => {
        const accessRights = response.data.access_rights;
        setAccessRights(accessRights);
        usersService.getAllowedData(userName).then(
          response => {
            const user = response.data.user;

            if (response.data.message) {
              setSuccessMessage(response.data.message);
            }

            if (user) {
              if (user.groups) setGroups(user.groups);
              if (user.categories) setCategories(user.categories);
              if (user.applications) setYhpApplications(user.applications);
            }
            setLoading(false);
          },
          error => {
            setLoading(false);
            setErrorMessage(
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString()
            );
          }
        )
      },
      error => {
        setLoading(false);
        setErrorMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    );
  }, [userName])

  return (
    <>
    {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
    {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
    {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
    <div className="container-fluid">
      <Tabs defaultActiveKey="groups" id="uncontrolled-tab-example">
        {accessRights.allow_viewing_groups && groups.length > 0 && (<Tab eventKey='groups' title='Groups' key='groups'>
         <div className="list-group" name="categories-list">
          {groups.map((group) => (
              <a key={group.id} href={`/users/${userName}/groups/${group.uuid}`} className="list-group-item list-group-item-action">
                {group.name}
              </a>
           ))}
           </div>
        </Tab>)}
        {accessRights.allow_viewing_categories && categories && (<Tab eventKey='categories' title='Categories' key='Categories'>Categories</Tab>)}
        {accessRights.allow_viewing_applications && yhpApplications && (<Tab eventKey='applications' title='Applications' key='Applications'>Applications</Tab>)}
        {accessRights.allow_viewing_results && (
          <Tab eventKey='results' title='Results' key='Results'>
            <BoardUser defaultGroups={groups} defaultCategories={categories} defaultYhpApplications={yhpApplications} userName={userName} />
          </Tab>
        )}
      </Tabs>
    </div>
    </>
  );
}