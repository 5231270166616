import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import Alert from './alert.component.js';
import MultipleTrackingCharts from "./multiple-tracking-charts.component";

import shareService from '../services/share.service';
import publicCategoriesService from '../services/public-categories.service';
import capitalizeFirstLetter from '../utils/capitalize-first-letter';

export default function PublicSharePage() {

  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });
  const [share, setShare] = useState();
  const [category, setCategory] = useState();
  const [loading, setLoading] = useState(false);

  let { shareUuid } = useParams();

  useEffect(() => {
    if (!shareUuid) {
      return;
    }
    setLoading(true);
    shareService.get(shareUuid).then(
      response => {
        setLoading(false);
        if (response.data.success) {
          const share = response.data.share;
          setSuccessMessage(response.message);
          setShare(share);
          switch (share.shared_item_type) {
            case 'category':
              publicCategoriesService.get(share.uuid, share.shared_item_id).then(
                response => {
                  setCategory(response.data.category);
                },
                error => {
                  setFailureMessage(
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString()
                  );
                }
              )
              break;
            default:
              setFailureMessage('Unknown item type');
          }
        } else {
          setFailureMessage(response.data.message)
        }
      },
      error => {
        setLoading(false);
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )}, [shareUuid])

  const setFailureMessage = (message) => {
    setAlert(alert => ({...alert, 
      show: true,
      alertType: 'danger',
      alertTitle: 'Error',
      alertMessage: message
    }));
  }

  const setSuccessMessage = (message) => {
    setAlert(alert => ({...alert, 
      show: true,
      alertType: 'success',
      alertTitle: 'Success',
      alertMessage: message
    }));
  };

  return (
    <>
      <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />
      {loading && (
        <span className="spinner-border spinner-border-sm"></span>
      )}
      {share &&
         (
          <div className="container">
            <div key={share.uuid}>
              <h2>Shared by {share.sharing_user.username}</h2>
            </div>
            {category && shareUuid && (
              <div>
                <h3>Category: {capitalizeFirstLetter(share.shared_item_name)}</h3>
                <MultipleTrackingCharts title={category.name} category={category} shareUuid={shareUuid} />
              </div>
            )}
          </div>
          
         )}
    </>
  )
}