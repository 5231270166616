import { useState, useEffect } from 'react';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";

import inviteService from '../services/invite.service';

export default function AddInvite() {
  const [link, setLink] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [tag, setTag] = useState('');
  const [rulesNames, setRulesNames] = useState([]);
  const [rules, setRules] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  let form;

  useEffect(() => {
    inviteService.getInviteFlags().then(
      result => {
        const flags = result.data.flags;
        setRulesNames(flags)
        const rules = {};
        for (const flag of flags) {
          rules[flag] = false;
        }
        setRules(rules);
      }, error => {
        setErrorMessage(error.message)
      })

  }, []);

  const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };

  const onChangeName = (e) => {
    setName(e.target.value)
  }

  const onChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  const onChangeTag = (e) => {
    setTag(e.target.value)
  }
  
  const handleSubmit= (e) => {
    e.preventDefault();

    setErrorMessage("")
    setSuccessMessage("")
    setLoading(true);

    form.validateAll();

    inviteService.create({name: name, email: email, tag: tag, rules: rules}).then(
      response => {
        if (response.data.success && response.data.message) {
          setSuccessMessage(response.data.message)
        }
        setLink(response.data.link);
        setLoading(false);
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setErrorMessage(resMessage);
        });
    }

  return (
    <>
      {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
      {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
          <div>
            <h2>Invite someone to enjoy YourHealthPlatform!</h2>
            <Form onSubmit={handleSubmit}
              ref={c => {
               form = c;
              }}>

            <div className="mb-3">
              <label htmlFor="name">Name</label>
              <Input
                type="text"
                className="form-control"
                name="name"
                onChange={onChangeName}
                value={name}
                validations={[required]}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="email">Email</label>
              <Input
                type="email"
                className="form-control"
                name="email"
                onChange={onChangeEmail}
                value={email}
                validations={[required]}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="tag">Tag</label>
              <Input
                type="text"
                className="form-control"
                name="tag"
                onChange={onChangeTag}
                value={tag}
                validations={[required]}
              />
            </div>

            {rulesNames && rulesNames.map((ruleName) => (
               <div key={`div_rule_${ruleName}`} className="form-check">
                <input className="form-check-input" 
                  name="published" 
                  type="checkbox" 
                  checked={rules[ruleName]}
                  onClick={(e) => {
                    setRules({...rules, [ruleName]: !rules[ruleName]})
                  }}
                  onChange={(e) => {
                    setRules({...rules, [ruleName]: !rules[ruleName]})
                  }}
                  key={`rule_${ruleName}`}
                  value={rules[ruleName]}
                  validations={[required]}
                />
               <label htmlFor="published">{ruleName}</label>
             </div>
            ))}
            

            <div className="mb-3">
              <button
                className="btn btn-primary btn-block"
                disabled={loading}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Submit</span>
              </button>
            </div>

            {errorMessage && (
              <div className="mb-3">
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              </div>
            )}
{successMessage}
            {successMessage && (
              <div className="mb-3">
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              </div>
            )}
            </Form>
            {link && <span>You can send your invite with the following link: {link}</span>}
          </div>
    </>
  )
}