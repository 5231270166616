import { useState, useEffect } from 'react';

import Alert from './alert.component';
import Markdown from 'react-markdown'

import groupInsightsService from '../services/group-insights.service.js';

export default function InsightsData({definition, group, data, boundary}) {
  const [insights, setInsights] = useState([]);
  const [definitionInfo, setDefinitionInfo] = useState('');
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });

  useEffect(() => {
    if (definition) {
      let groupDefinitionInfo = group && group.definitions_info ? group.definitions_info.find(
        (definitionInfo) => definitionInfo.definition_id === definition.id) : null;

      if (groupDefinitionInfo) {
        setDefinitionInfo(groupDefinitionInfo);
      }
    }

    if (!boundary) return;

    let insightTypes = [];
   
    for (const datum of data) {
      datum.value = parseFloat(datum.value);
      if (isNaN(datum.value)) continue;
      for (const insightType of Object.keys(boundary.group_definitions.levels)) {
        const level = boundary.group_definitions.levels[insightType];
        if (level && level.value) {
          switch (level.comparator) {
            case '<':
            case 'less':
              if (datum.value < level.value) {
                insightTypes.push(insightType);
              }
              break;
            case '>':
            case 'more':
            case 'bigger':
              if (datum.value > level.value) {
                insightTypes.push(insightType);
              }
              break;
            case '=':
            case 'equal':
              if (datum.value === level.value) {
                insightTypes.push(insightType);
              }
              break;
            case '!=':
            case 'not equal':
              if (datum.value !== level.value) {
                insightTypes.push(insightType);
              }
              break;
            case '<=':
            case 'less or equal':
            case 'le':
              if (datum.value <= level.value) {
                insightTypes.push(insightType);
              }
              break;
            case '>=':
            case 'more or equal':
            case 'greater or equal':
            case 'ge':
              if (datum.value >= level.value) {
                insightTypes.push(insightType);
              }
              break;
            default:
              console.error('Unknown comparator: ' + level.comparator);
              break;
          }
        }
      };
    }

    if (insightTypes.length > 0) {
      setLoading(true);
      groupInsightsService.get(group, definition, insightTypes).then(
        response => {
          setLoading(false);
          setInsights(response.data.insights);
        },
        error => {
          setLoading(false);
          if (error.response.status === 404) return;
          setAlert(alert => ({...alert, 
            show: true,
            alertType: 'danger',
            alertMessage: (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
          }));
        }
      )
    }
  }, [data, definition, boundary, group])

  return (
    <>
        <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />
        {loading && (
          <span className="spinner-border spinner-border-sm">Loading Insights...</span>
        )}
        {definitionInfo && (
         <>
            <h4>What is it?</h4>
            <Markdown>
              {definitionInfo.text}
            </Markdown>
          </>
        )}
        {insights.length > 0 && (
          <>
            <h4>Insights</h4>
            <ul>
              {insights.map((insight, index) => (
                <li key={index}>
                  <Markdown>
                    {insight.text}
                  </Markdown>
                </li>
              ))}
            </ul>
          </>
    )}
    </>
   
  )
}