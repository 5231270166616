import api from '../interceptors/auth.interceptor';

class EntriesService {
  getEntriesLastResults(numberOfLastDates) {
    return api.get(process.env.REACT_APP_API_URL + `/api/entries/last_dates/${numberOfLastDates}`)
  }

  addEntry(definition, measurementunitTypeName, dateTaken, value) {
    // in the future support more than one
    const entriesDataValues = [{name: measurementunitTypeName, value: value}].map((typeAndValue) => {
      let unitType;
      if (definition.measurement_unit_types) {
        unitType = definition.measurement_unit_types.find((measurementunitType) => measurementunitType.name === measurementunitTypeName.toLowerCase());
      } else {
        console.log(`${definition.measurement_unit_types}`)
      }

      const retValue = {
        value: typeAndValue.value,
        definition_id: definition.id,
      }

      if (unitType) {
        retValue.measurement_unit_type_id = unitType.id 
      } else {
        retValue.measurement_unit_type_name =measurementunitTypeName
      }

      return retValue;
    })

    const entriesData = {
      date_taken: dateTaken,
      values: entriesDataValues
    }

    return api.post(process.env.REACT_APP_API_URL + `/api/entries/${encodeURIComponent(definition.name)}`,
      entriesData); 
  }

  
}


const entriesService = new EntriesService();
export default entriesService;