import { useState, useEffect } from 'react';

import usersService from "../services/users.service";
import usersAccessRightsService from '../services/user-access-rights.service';
import inviteService from '../services/invite.service';


export default function GiveAccess() {
  const [failureMessage, setFailureMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [usersWithRights, setUsersWithRights] = useState({})
  const [users, setUsers] = useState([])
  const [rulesNames, setRulesNames] = useState([]);
  const [rules, setRules] = useState({});
  const [tag, setTag] = useState('');
  const [currentUserName, setCurrentUserName] = useState({});
  const [currentUserIndex, setCurrentUserIndex] = useState(0);

  useEffect(() => {
    Promise.all([
      usersService.getAll(),
      inviteService.getInviteFlags(),
      usersAccessRightsService.getAllGivenByMe()
    ]).then(
      responses => {
        const users = responses[0].data.users;
        const flags = responses[1].data.flags;
        const accessRights = responses[2].data.access_rights;
        setUsers(users);
        const localShowUserNames = {}
        const localUsersWithRights = {}
        for (const user of users) {
          localShowUserNames[user.username] = true;
          localUsersWithRights[user.username] = accessRights.find((userWithRights) => userWithRights.user_receiving_rights.username === user.username);
        }
        setUsersWithRights(localUsersWithRights);
        setRulesNames(flags)
        const rules = {};
        for (const flag of flags) {
          rules[flag] = false;
        }
        setRules(rules);
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )}, [])

    const handleUsernameClicked = (event, index) => {
        const targetUsername = event.target.value
        setCurrentUserIndex(index)
        setCurrentUserName(targetUsername)
        const user = users.find((user) => user.username === targetUsername);
        if (user && usersWithRights[user.username]) {
          setTag(usersWithRights[user.username].tag);
          setRules(usersWithRights[user.username]);
        } else {
          setTag('');
          setRules({});
        }
    } 

    const handleGiveAccessToUser = (event) => {
      event.preventDefault();
      setFailureMessage('');
      setSuccessMessage('');

      setLoading(true);
      const userName = event.target.value
      usersAccessRightsService.create(userName, rules, tag).then(
        response => {
          setLoading(false);
          setSuccessMessage(response.data.message);
          setUsersWithRights(usersWithRights => ({...usersWithRights, [userName] : response.data.access_rights}));
          setTag( response.data.access_rights.tag);
        }, error => {
          setLoading(false);
          setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
        }
      )
    }

    const handleTagChange = (event) => {
      event.preventDefault();
      setTag(event.target.value);
    }

  return (
    <>
      {failureMessage && <div className="alert alert-danger" role="alert">{failureMessage}</div>}
      {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
      <a className="btn btn-primary" href="/invites/add" role="button">
        Invite a new user
      </a>
      <h3>Users</h3>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">Username</div>
              <div className="col-sm-5">Flags</div>
            </div>
            <div className="row">
              <div className="col-4">
                <div className="list-group" id="list-tab" role="tablist">
                  {users && users.map((user, index) => {
                    return (
                      <button 
                        type="button"
                        className={`list-group-item list-group-item-action ${index===currentUserIndex ? 'active' : ''}`} 
                        id="list-home-list" data-toggle="list" 
                        key={`give-access-button-${user.username}`}
                        onClick={(e) => handleUsernameClicked(e, index)}
                        value={user.username}
                        data-bs-toggle="list"
                        role="tab" aria-controls="choose_user">
                          {user.username}
                          {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                      </button>
                      )
                    })}
                  </div>
                </div>
           
            <div className="col-8">
              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list">
                  <label htmlFor="tag">Tag</label>
                    <input
                      type="text"
                      className="form-control"
                      name="tag"
                      onChange={handleTagChange}
                      value={tag}
                    />
                  {rulesNames && rulesNames.map((ruleName) => (
                    <div className="form-check" key={`form-give-access-${ruleName}`}>
                      <input className="form-check-input" 
                        key={`give-access-${ruleName}`}
                        name="published" 
                        type="checkbox" 
                        checked={rules[ruleName]}
                        onClick={(e) => {
                          setRules({...rules, [ruleName]: !rules[ruleName]})
                        }}
                        onChange={(e) => {
                          setRules({...rules, [ruleName]: !rules[ruleName]})
                        }}
                        value={rules[ruleName]}
                      />
                    <label htmlFor="published">{ruleName}</label>
                    </div>
                  ))}
                  <button className="btn btn-primary" 
                      name={`give-access-button-${currentUserName}`} 
                      onClick={handleGiveAccessToUser}
                      value={currentUserName}
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      Give Access
                  </button>
                </div>
              </div>
            </div>
             </div>
            {users && users.length === 0 && <div className="row">No users found</div>}
      </div>
    </>
  )
}