import api from '../interceptors/auth.interceptor';

class CategoriesService {
  getAll() {
    return api.get(process.env.REACT_APP_API_URL + '/api/categories');
  }

  get(categoryId) {
    return api.get(process.env.REACT_APP_API_URL + `/api/categories/${categoryId}`);
  }

  update(categoryId, categoryData) {
    return api.post(process.env.REACT_APP_API_URL + `/api/categories/${categoryId}`, categoryData);
  }

  create(categoryData) {
    return api.post(process.env.REACT_APP_API_URL + `/api/categories`, 
      {category: categoryData}
    );
  }

  removeDefinition(categoryId, definitionId) {
    return api.delete(process.env.REACT_APP_API_URL + `/api/categories/${categoryId}/definitions/${definitionId}`);
  }

  destroy(categoryId) {
    return api.delete(process.env.REACT_APP_API_URL + `/api/categories/${categoryId}`);
  }



}

const categoriesService = new CategoriesService();
export default categoriesService;