import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import UserService from "../services/user.service";
import groupService from "../services/group.service";
import authService from '../services/auth.service';

export default function GroupsPage() {
  const [failureMessage, setFailureMessage] = useState('');
  const [userGroups, setUserGroups] = useState([])
  const [allGroups, setAllGroups] = useState([])
  const [successMessage, setSuccessMessage] = useState("")
  const [loading, setLoading] = useState(false);

  let { groupName } = useParams();
  const currentUser = authService.getCurrentUser();

  useEffect(() => {
    Promise.all([
      UserService.getGroups(groupName),
      groupService.getAll()
    ]).then(
      responses => {
        setUserGroups(responses[0].data.groups);
        setAllGroups(responses[1].data.groups)
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )}, [groupName])

  const removeGroup = (groupUuid) => {
    setFailureMessage("")
    setSuccessMessage("")
    setLoading(true);
    UserService.removeGroup(groupUuid).then(
      response => {
        if (response.data.groups) {
          setUserGroups(response.data.groups)
        }
        setLoading(false);
        setSuccessMessage(response.data.message);
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
        setLoading(false);
      }
    )
  }

  const addGroup = (groupUuid) => {
    setFailureMessage("")
    setSuccessMessage("")
    setLoading(true);
    UserService.addGroup(groupUuid).then(
      response => {
        setUserGroups(response.data.groups)
        setLoading(false);
        setSuccessMessage(response.message)
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
        setLoading(false);
      }
    )
  }
  return (
    <>
      {failureMessage && <div className="alert alert-danger" role="alert">{failureMessage}</div>}
      {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2">Group</div>
          <div className="col-sm-2">Installed?</div>
          <div className="col-sm-2">Publisher</div>
        </div>
      {allGroups && userGroups && allGroups.map((group) => {
        const installed = !!(userGroups.find((userGroup) => userGroup.name === group.name && userGroup.users && userGroup.users.length > 0 && 
          userGroup.users[0].username === currentUser.username))
        return (
        <div className="row" key={group.uuid || group.id}>
          <div className="col-sm-2"><Link to={`/groups/${group.uuid}`} >{group.name}</Link></div>
          <div className="col-sm-2">{ installed ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-check" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
  <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
  <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"/>
</svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-x" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M6.146 6.146a.5.5 0 0 1 .708 0L8 7.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 8l1.147 1.146a.5.5 0 0 1-.708.708L8 8.707 6.854 9.854a.5.5 0 0 1-.708-.708L7.293 8 6.146 6.854a.5.5 0 0 1 0-.708"/>
  <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
  <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"/>
</svg>}
          </div>
          
          <div className="col">
            <Link to={`/users/${group.publisher.username}`}>{group.publisher.username}</Link>
          </div>
          <div className="col">
            {installed ? 
              <button type="button" key={`add-button-${group.uuid}`} className="btn btn-primary" onClick={() => {removeGroup(group.uuid)}}>
                Remove {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
              </button> :
              <button type="button" key={`remove-button-${group.uuid}`} className="btn btn-primary" onClick={() => {addGroup(group.uuid)}}>
                Add {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
              </button>}
              <a href={`/share/group/${group.uuid}`} className="btn btn-info" key={`share-button-${group.uuid}`}>
                Share {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
              </a>
          </div>
        </div>
        )
      })}
      <a className="btn btn-secondary" href={`/groups/add`} role="button">Add Group</a>
      </div>
    </>
  )
}