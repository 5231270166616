import api from '../interceptors/auth.interceptor';

class GroupDefinitionsService {
  getAll(groupId) {
    return api.get(process.env.REACT_APP_API_URL + `/api/groups/${groupId}/definitions`);
  }

  get(groupName, definitionId) {
    return api.get(process.env.REACT_APP_API_URL + `/api/groups/${encodeURIComponent(groupName)}/definitions/${definitionId}`);
  }

  getById(groupUuid, definitionId) {
    return api.get(process.env.REACT_APP_API_URL + `/api/groups/${groupUuid}/definitions/ids/${definitionId}`);
  }

  updateLevels(groupUuid,levelsData) {
    return api.post(
      process.env.REACT_APP_API_URL + `/api/groups/${groupUuid}/definitions/levels`, {levels: levelsData});
  }

  update(groupUuid, definitionData, definitonInfo) {
    return api.post(
      process.env.REACT_APP_API_URL + `/api/groups/${groupUuid}/definitions/${definitionData.id}`,
      {
        definition: {
          name: definitionData.name || definitionData.definition_name,
          measurement_unit_type: {
            name: definitionData.measurement_unit_type ? definitionData.measurement_unit_type.name : definitionData.measurement_unit_type_name
          },
          group_definitions: definitionData.group_definitions,
          definiton_info: definitonInfo
        }
      });
  }

  createBulk(groupUuid, definitionsData) {
    return api.post(process.env.REACT_APP_API_URL + `/api/groups/${groupUuid}/definitions/bulk`, 
      {
        definitions: definitionsData
      })
  }

  create(groupUuid, definitionData, definitonInfo) {
    return api.post(process.env.REACT_APP_API_URL + `/api/groups/${groupUuid}/definitions`, 
      {
        definition: {
          name: definitionData.name || definitionData.definition_name,
          measurement_unit_type: {
            name: definitionData.measurement_unit_type ? definitionData.measurement_unit_type.name : definitionData.measurement_unit_type_name
          },
          group_definitions: definitionData.group_definitions,
          definiton_info: definitonInfo
        }
      })
  }
}

const groupDefinitionsService = new GroupDefinitionsService();
export default groupDefinitionsService;