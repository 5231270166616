import api from '../interceptors/auth.interceptor';

class DefinitionsService {
  
  getAll() {
    return api.get(process.env.REACT_APP_API_URL + `/api/definitions`)
  }

  get(definitionId) {
    return api.get(`${process.env.REACT_APP_API_URL}/api/definitions/${definitionId}`)
  }

  create(definitionsData) {
    return api.post(`${process.env.REACT_APP_API_URL}/api/definitions/`, {
      definition: definitionsData
    })
  }

  bulkCreate(definitionsData) {
    return api.post(`${process.env.REACT_APP_API_URL}/api/definitions/bulk`, {
      definitions: definitionsData
    })
  }

  merge(definition, definitionsData) {
    return api.put(`${process.env.REACT_APP_API_URL}/api/definitions/merge`, {
      definition:definition, 
      other_definition: definitionsData
    })
  }
}


const definitionsService = new DefinitionsService();
export default definitionsService;