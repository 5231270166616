import { useState } from 'react';
import { useNavigate } from "react-router-dom";

import { useParams } from 'react-router-dom';
import authService from '../services/auth.service';

export default function ResetPassword() {
  const [password, setPassword] = useState('');

  const { token } = useParams();
  const navigate = useNavigate();

  
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  }

  const onSubmit = (e) => {
    e.preventDefault();

    authService.updatePassword(token, password).then(
      response => {
        navigate("/login");
      },
      error => {
        console.log(error);
      }
    )
  }

  return (
    <>
      <div className="col-md-12">
        <div className="card card-container text-center mx-auto">
         <div className="mb-3">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              name="password"
              value={password}
              onChange={onChangePassword}
            />
          </div>
          <div className='button-group'>
            <button className="btn btn-primary" onClick={onSubmit}>Reset Password</button>
          </div>
        </div>
      </div>
    </>
  )
}