import api from '../interceptors/auth.interceptor';

class UserCategoriesService {
  getAll(userId) {
    return api.get(process.env.REACT_APP_API_URL + `/api/user/${userId}categories`);
  }

  get(categoryId) {
    return api.get(process.env.REACT_APP_API_URL + `/api/user/categories/${categoryId}`);
  }

  add(categoryId) {
    return api.post(process.env.REACT_APP_API_URL + `/api/user/categories/${categoryId}`);
  }

  remove(categoryId) {
    return api.delete(process.env.REACT_APP_API_URL + `/api/user/categories/${categoryId}`);
  }

  searchDefinition(definitionName) {
    return api.get(process.env.REACT_APP_API_URL + `/api/user/categories/definitions/${definitionName}`);
  }

}

const userCategoriesService = new UserCategoriesService();
export default userCategoriesService;