import moment from 'moment'
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';

import shareService from '../services/share.service';
import capitalizeFirstLetter from '../utils/capitalize-first-letter';

export default function ShareItem() {
  const [failureMessage, setFailureMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState("")
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [sharedWithUsers, setSharedWithUsers] = useState([]);
  const [sharedWithEmails, setSharedWithEmails] = useState([]);

  let { itemType, itemName } = useParams();

  useEffect(() => {
    shareService.getByTypeAndName(itemType, itemName).then(
      response => {
        const shares = response.data.shares;
        setSharedWithEmails(shares)
      },
      error => {
        if (error.response && error.response.status === 404) {
          return;
        }

        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )}, [])

  const createShare = () => {
    setFailureMessage("")
    setSuccessMessage("")
    setLoading(true);
    shareService.create(itemType, itemName, emails).then(
      response => {
        setLoading(false);
        setSharedWithUsers(response.data.shared_with_users);
        setSharedWithEmails(response.data.shared_with_emails);
        setSuccessMessage(response.successMessage);
        setEmails([]);
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
        setLoading(false);
      }
    )
  }

  return (
    <>
      {failureMessage && <div className="alert alert-danger" role="alert">{failureMessage}</div>}
      {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
      <h2>
        Share {capitalizeFirstLetter(itemType)}: {itemName}
      </h2>
      <h4>Email</h4>
      <ReactMultiEmail
        placeholder='Input your email'
        emails={emails}
        onChange={(_emails) => {
          setEmails(_emails);
        }}
        autoFocus={true}
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag key={index}>
              <div data-tag-item>{email}</div>
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          );
        }}
      />
      <br />
      <button
        className="btn btn-primary btn-block"
        disabled={loading}
        onClick={createShare}
      >
        {loading && (
          <span className="spinner-border spinner-border-sm"></span>
        )}
        <span>Share</span>
      </button>
      {sharedWithEmails && (
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" >#</th>
              <th scope="col" >Email</th>
              <th scope="col" >Shared At</th>
              <th scope="col" >Received At</th>
              <th scope="col" >Accepted At</th>
              <th scope="col" >Rejected At</th>
              <th scope="col" >User</th>
              <th scope="col" >Link</th>
            </tr>
          </thead>
          <tbody>
          {sharedWithEmails.map((sharedWithEmail, index) => (
            <tr key={sharedWithEmail.invited_email}>
              <th scope="row" >{index}</th>
              <td >{sharedWithEmail.invited_email}</td>
              <td >{moment(sharedWithEmail.createdAt).toLocaleString()}</td>
              <td >{sharedWithEmail.received_at ? moment(sharedWithEmail.received_at).toLocaleString() : ''}</td>
              <td >{sharedWithEmail.accepted_at ? moment(sharedWithEmail.accepted_at).toLocaleString() : ''}</td>
              <td >{sharedWithEmail.rejected_at ? moment(sharedWithEmail.rejected_at).toLocaleString() : ''}</td>
              <td >{sharedWithEmail.user}</td>
              <td >{sharedWithEmail.link}</td>
            </tr>
          ))}
          </tbody>
        </table>
      )}
      {sharedWithUsers && (
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" >#</th>
              <th scope="col" >Email</th>
              <th scope="col" >Shared At</th>
              <th scope="col" >Received At</th>
              <th scope="col" >Accepted At</th>
              <th scope="col" >Rejected At</th>
              <th scope="col" >User</th>
              <th scope="col" >Link</th>
            </tr>
          </thead>
          <tbody>
         {sharedWithUsers.map((sharedWithUser, index) => (
          <tr key={sharedWithUser.invited_email}>
          <th scope="row" >{index}</th>
          <td >{sharedWithUser.invited_email}</td>
          <td >{moment(sharedWithUser.createdAt).toLocaleString()}</td>
          <td >{sharedWithUser.received_at ? moment(sharedWithUser.received_at).toLocaleString() : ''}</td>
          <td >{sharedWithUser.accepted_at ? moment(sharedWithUser.accepted_at).toLocaleString() : ''}</td>
          <td >{sharedWithUser.rejected_at ? moment(sharedWithUser.rejected_at).toLocaleString() : ''}</td>
          <td >{sharedWithUser.user}</td>
          <td >{sharedWithUser.link}</td>
        </tr>
         ))}
         </tbody>
       </table>
      )}
    </>
  )
}