import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Markdown from 'react-markdown'

import applicationsService from "../services/application.service";
import UserService from '../services/user.service';

export default function ApplicationsPage() {
  const [failureMessage, setFailureMessage] = useState('');
  const [yhpApplication, setYhpApplication] = useState({})
  const [yhpApplicationInstalled, setYhpApplicationInstalled] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [loading, setLoading] = useState(false);

  let { applicationId } = useParams();

  useEffect(() => {
      applicationsService.get(applicationId).then(
        response => {
          if (!Array.isArray(response.data.application.rules)) {
            response.data.application.rules = [response.data.application.rules];
          }
          setYhpApplication(response.data.application)
          setYhpApplicationInstalled(response.data.application.users && response.data.application.users.length > 0)
          setLoading(false);
        },
        error => {
          setFailureMessage(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
          );
          setLoading(false);
        }
    )}, [applicationId])

  const addApp = (appName) => {
    setFailureMessage("")
    setSuccessMessage("")
    setLoading(true);
    UserService.addApplications(appName).then(
      response => {
        setYhpApplicationInstalled(true)
        setLoading(false);
        setSuccessMessage(response.message)
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
        setLoading(false);
      }
    )
  }
 
  return (
    <>
      {loading && (
      <span className="spinner-border spinner-border-sm"></span>
      )}
      {failureMessage && <div className="alert alert-danger" role="alert">{failureMessage}</div>}
      {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
      <div className="mb-3">
        <h3>
          {yhpApplication.name}
        </h3>
        <div className="mb-3">
          <label htmlFor="description">Description:</label>
          <Markdown>
            {yhpApplication.description}
          </Markdown>
        </div>
        {!yhpApplicationInstalled && (
          <div className="mb-3">
            <button type="button" key={`remove-button-${yhpApplication.name}`} className="btn btn-primary" onClick={() => {addApp(yhpApplication.name)}}>
                Add {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
              </button>
          </div>
        )}
        {yhpApplicationInstalled && yhpApplication.oauth_url && (
          <div className="mb-3">
            <label htmlFor="description">Authorization:</label>
            <a href={yhpApplication.oauth_url} target="_blank" rel="noreferrer">Authorize</a>
          </div>
        )}
        <div className="mb-3">
          <label htmlFor="rules">Rules:</label>
          <p>
            <ul>
            {yhpApplication.rules && yhpApplication.rules.map((rule, index) => (
              <li>{rule.name} ({rule.value})</li>
            ))}
            </ul>
          </p>
        </div>
      </div>
    </>
  )
}