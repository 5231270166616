import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import Alert from './alert.component';

import categoriesService from "../services/categories.service"
import viewService from '../services/view.service';

export default function ViewCategories() {
  const [view, setView] = useState({})
  const [viewCategories, setViewCategories] = useState([])
  const [allCategories, setAllCategories] = useState([])
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });

  let { viewUuid } = useParams();

  useEffect(() => {
    Promise.all([
      viewService.getCategories(viewUuid),
      categoriesService.getAll(),
      viewService.get(viewUuid)
    ]).then(
      responses => {
        setViewCategories(responses[0].data.categories);
        setAllCategories(responses[1].data.categories)
        setView(responses[2].data.view)
      },
      error => {
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertMessage: (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }));
      }
    )}, [viewUuid])

  const removeFromView = (viewUuid, categoryId) => {
    setAlert({show: false});
    setLoading(true);
    viewService.deleteCategory(viewUuid, categoryId).then(
      response => {
        setViewCategories(response.data.view.categories)
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'success',
          alertTitle: 'Success',
          alertMessage: response.message
        }));
      },
      error => {
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertMessage: (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }));
        setLoading(false);
      }
    )
  }

  const addToView = (viewUuid, categoryId) => {
    setAlert({show: false});
    setLoading(true);
    viewService.addCategory(viewUuid, categoryId).then(
      response => {
        setViewCategories(response.data.view.categories)
        setLoading(false);
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'success',
          alertTitle: 'Success',
          alertMessage: response.message
        }));
      },
      error => {
        setAlert(alert => ({...alert, 
          show: true,
          alertType: 'danger',
          alertMessage: (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        }));
        setLoading(false);
      }
    )
  }
  return (
    <>
      <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />
      <div className="container">
        <div className="row">
          <h3>{view.name}</h3>
        </div>
        <div className="row">
          <div className="col-sm-2">Category Name</div>
          <div className="col-sm-2">Installed?</div>
          <div className="col-sm-2">Publisher</div>
        </div>
        {allCategories && viewCategories && allCategories.map((category) => {
          const installed = !!(viewCategories.find((viewCategory) => viewCategory.id === category.id))
        return (
        <div className="row">
          <div className="col-sm-2"><Link to={`/categories/${category.id}`}>
            {category.name}
          </Link>
          </div>
          <div className="col-sm-2">{ installed ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-check" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
  <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
  <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"/>
</svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-x" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M6.146 6.146a.5.5 0 0 1 .708 0L8 7.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 8l1.147 1.146a.5.5 0 0 1-.708.708L8 8.707 6.854 9.854a.5.5 0 0 1-.708-.708L7.293 8 6.146 6.854a.5.5 0 0 1 0-.708"/>
  <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
  <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"/>
</svg>}
          </div>
          <div className="col">
          {category.publisher && 
          (
            <Link to={`/users/${category.publisher.username}`}>{category.publisher.username}</Link>
          )
          }
          </div>
          <div className="col">
            {installed ? 
              <button type="button" key={`add-button-${category.name}`} className="btn btn-primary" onClick={() => {removeFromView(viewUuid, category.id)}}>
                Remove {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
              </button> :
              <button type="button" key={`remove-button-${category.name}`} className="btn btn-primary" onClick={() => {addToView(viewUuid, category.id)}}>
                Add {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
              </button>}
             
          </div>
        </div>
        )
      })}
       <a className="btn btn-secondary" href={`/views/${viewUuid}/categories/add`} role="button">Add New Category</a>
      </div>
    </>
  )
}