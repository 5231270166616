import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import MarkdownEditor from '@uiw/react-markdown-editor';

import DefinitionPicker from "./definition-picker.component"

import groupDefinitionService from '../services/group-definitions.service';
import groupInsightsService from '../services/group-insights.service';
import capitalizeFirstLetter from '../utils/capitalize-first-letter';

export default function GroupDefinitions() {
  let { groupUuid } = useParams();
  let { groupName } = useParams();
  let { groupDefinitionId } = useParams();

  const newGroupDefinition = {
    name: '',
    measurement_unit_type: {
      name: null,
    },
    group_definitions: {
      levels: {
        min: {name: 'min', value: null, comparator: '<', label: 'Dangerous if lower', color: 'red'},
        max: {name: 'max', value: null, comparator: '>', label: 'Dangerous if higher', color: 'red'},
        warning_min: {name: 'warning_min', value: null, comparator: '<', label: 'Should not get lower than this', color: 'orange'},
        warning_max: {name: 'warning_max', value: null, comparator: '>', label: 'Should not get higher than this', color: 'orange'},
        ideal_min: {name: 'ideal_min', value: null, comparator: '<', label: 'Ideal if higher than this', color: 'green'},
        ideal_max: {name: 'ideal_max', value: null, comparator: '>', label: 'Ideal if lower than this', color: 'green'},
      }
    }
  }

  const emptyLocalInsights = {
    min: '',
    max: '',
    warning_min: '',
    warning_max: '',
    ideal_min: '',
    ideal_max: '',
  };

  const [group, setGroup] = useState({});
  const [definitonInfo, setDefinitonInfo] = useState("");
  const [newGroupDefinitionFlag, setNewGroupDefinitionFlag] = useState(!!!groupUuid);
  const [definition, setDefinition] = useState(newGroupDefinition);
  const [insights, setInsights] = useState(emptyLocalInsights);
  const [allGroupDefinitions, setAllGroupDefinitions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [chosenRule, setChosenRule] = useState('');

  useEffect(() => {
    if (!groupDefinitionId) return;
    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    groupDefinitionService.getById(groupUuid, groupDefinitionId).then(
      response => {
        const localGroupDefinition = response.data.definition;
        if (localGroupDefinition.group_definitions.levels === null || localGroupDefinition.group_definitions.levels === '') {
          localGroupDefinition.group_definitions.levels = newGroupDefinition.group_definitions.levels;
        }
        localGroupDefinition.measurement_unit_type = localGroupDefinition.measurement_unit_types[0];
        setDefinition(localGroupDefinition);
      },
      error => {
        setErrorMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )
  }, [groupUuid, groupDefinitionId]);

  useEffect(() => {
    if (!group || !group.id) return;
    if (!definition.name) return;
    if (!definition.measurement_unit_type || !definition.measurement_unit_type.name) return;

    // this is a new group definition
    if (!definition.id) return;

    setLoading(true);
    groupInsightsService.getAll(group, definition).then(
      response => {
        const localInsights = response.data.insights.reduce((acc, insight) => {
          if (insight.text.length) acc[insight.insight_type] = insight.text;
          return acc;
        }, emptyLocalInsights);
        setInsights(localInsights);
        setLoading(false);
      }, 
      error => {
        setLoading(false);
        if (error.response.status === 404) return;
        setErrorMessage(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        );
      }
    )
  }, [group, definition]);

  useEffect(() => {
    groupDefinitionService.getAll(groupUuid).then(
      response => {
        const groupDefinitions = response.data.definitions;
        setAllGroupDefinitions(groupDefinitions);
        setGroup(response.data.group);
      },
      error => {
        setErrorMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )
  }, [groupUuid]);

  const handleSubmit= (e) => {
    e.preventDefault();

    setErrorMessage("")
    setSuccessMessage("")

    setLoading(true);

    let remoteAction;
    let localDefinition = JSON.parse(JSON.stringify(definition));

    if (chosenRule) {
      localDefinition.group_definitions = { 
        boundaries_by_rule: {
          levels: {...localDefinition.group_definitions.levels},
          rule_name: chosenRule,
        }
      }
    }

    if (newGroupDefinitionFlag) {
      remoteAction = groupDefinitionService.create(groupName, localDefinition, definitonInfo);
    } else {
      remoteAction = groupDefinitionService.update(groupUuid, localDefinition,definitonInfo);
    }

    remoteAction.then(
      (response) => {
        if (response.data.success) {
          if (response.data.message) {
            setSuccessMessage(response.data.message)
          } else {
            setSuccessMessage('Updated sucecesfully!')
          }
        }
        const localInsights = Object.keys(insights).map((key) => ({
          text: insights[key],
          insight_type: key,
          definition_id: definition.id || response.data.definition.id, // the second part if this is a new definition, only the response has it
          group_id: group.id
        }))
        groupInsightsService.create(group, localInsights).then(
          response => {
            setSuccessMessage('Updated sucecesfully!')
            setLoading(false);
          },
          error => {
            setLoading(false);
            setErrorMessage(
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
            );
          }
        )

        const localGroupDefinition = response.data.definition.group_definitions;
        setDefinition(previousGroupDefinition => ({...previousGroupDefinition, group_definitions: localGroupDefinition}));
        setLoading(false);
        setNewGroupDefinitionFlag(false);
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setErrorMessage(resMessage);
        });
  }

  return (
    <>
      {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
      {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
      {allGroupDefinitions && <div className='col-xs-12 col-sm-12 mb-3'>
        <DefinitionPicker 
          allDefinitionsToPickFrom={allGroupDefinitions}
          setErrorMessage={setErrorMessage} 
          setSuccessMessage={setSuccessMessage} 
          onDefinitionPicked={(newDefinition) => setDefinition(previousGroupDefinition => ({...previousGroupDefinition, name: newDefinition.name, id: newDefinition.id}))}
          onMeasurementUnitTypePicked={(newName) => {
            setDefinition(previousGroupDefinition => ({...previousGroupDefinition, measurement_unit_type: {name: newName}}))
          }}
          definitionValue={definition.name}
          unitMeasurementValue={definition.measurement_unit_type ? definition.measurement_unit_type.name : null}
            />
      </div>}
      <div className="col-xs-3 text-right mb-3">
        <label htmlFor='retest_period_in_days'>Retest Period (days)</label>
      </div>
      <div className="col-xs-3 text-right mb-3">
        <input
          key='retest_period_in_days'
          id='retest_period_in_days'
          type="text"
          className="form-control"
          name='retest_period_in_days'
          onChange={(e) => setDefinition({...definition, group_definitions: {...definition.group_definitions, retest_period_in_days: e.target.value}})}

          value={definition.group_definitions.retest_period_in_days}
        />
      </div>
      <div className="col-xs-3 text-right mb-3">
        <label htmlFor='definition_info'>More Info</label>
      </div>
      <div className="col-xs-3 text-right mb-3">
          <MarkdownEditor
            type="text"
            className="form-control"
            id="definition_info"
            key={`definition-info-for-${definition.id}`}
            name={`insight-for-${definition.id}`}
            onChange={(value) => setDefinitonInfo(value)} 
            value={definitonInfo}
          />
      </div>
      <div className="col-xs-3 text-right mb-3">
        <label htmlFor='rule_name'>Rule Name</label>
      </div>
      <div className="btn-group btn-group-sm mb-3" role="group" aria-label="choose rule name">
        <input 
          type="radio" 
          key='rule-none' 
          className="btn-check" 
          name="btnradio" 
          id='rule-none' 
          autoComplete="off" 
          checked={chosenRule === null} 
          onClick={(e) => setChosenRule('')}
          onChange={(e) => {}}
        />
        <label className="btn btn-outline-primary" htmlFor='rule-none'>No rule</label>
        {group.id && group.group_definitions_rules && group.group_definitions_rules.map((rule, index) => (
          <> 
            <input 
              type="radio" 
              key={`rule-${rule.id}`} 
              className="btn-check" 
              name="btnradio" 
              id={`rule-${rule.id}`} 
              autoComplete="off" 
              checked={chosenRule === rule.name}
              onClick={(e) => setChosenRule(rule.name)}
              onChange={(e) => {}}
            />
            <label className="btn btn-outline-primary" htmlFor={`rule-${rule.id}`}>{capitalizeFirstLetter(rule.name)}</label>
          </>
        ))
        }
      </div>
      {definition.name && definition.measurement_unit_type && definition.measurement_unit_type.name && (
        <>
          { Object.keys(definition.group_definitions.levels).map((levelKey, index) => {
            const level = definition.group_definitions.levels[levelKey];
            return (
              <>
                <div className="col-xs-3 text-right mb-3">
                  <label htmlFor={level.name}>
                    {capitalizeFirstLetter(level.name)}
                    ({capitalizeFirstLetter(level.label)})
                  </label>
                </div>
                <div className="col-xs-3 text-right mb-3">
                  <input
                    key={`boundary-for-${level.name}`}
                    type="text"
                    className="form-control"
                    name={level.name}
                    onChange={(e) => setDefinition({...definition, group_definitions: 
                      {levels: 
                        {...definition.group_definitions.levels, [level.name]: {...definition.group_definitions.levels[level.name], value: e.target.value}}
                      }
                  })}

                    value={level.value}
                  />
                </div>
                <div className="col-xs-3 text-right mb-3">
                  <MarkdownEditor
                    type="text"
                    className="form-control"
                    key={`insight-for-${level.name}`}
                    name={`insight-for-${level.name}`}
                    onChange={(value) => setInsights({...insights,[level.name]: value})}
                    value={insights[level.name]}
                  />
                </div>
              </>
            
            )})}
          
          <button className="btn btn-primary btn-block" onClick={handleSubmit} disabled={loading} >
            Add
          </button>
        </>
      )}
    </>
  )
}