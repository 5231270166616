import { useState, useEffect } from 'react';

import Alert from './alert.component.js';

import userEntriesService from '../services/user-entries-service.js';
import userConfigurationService from '../services/user-configuration.service.js';

export default function UploadResultsCSV() {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [csvConfiguration, setCsvConfiguration] = useState({});
  const [alert, setAlert] = useState({
    alertType: 'danger',
    alertTitle: 'Error',
    alertMessage: '',
    show: false
  });
  
  useEffect(() => {
    userConfigurationService.get().then(
      response => {
        if (response.data.user_configuration.configuration.csv_configuration) {
          setCsvConfiguration(JSON.parse(response.data.user_configuration.configuration.csv_configuration));
        } else {
          setCsvConfiguration({
            usage_type_row_index: 0,
            measurement_unit_type_mame_row_index: 1,
            category_row_index: 2,
            group_boundry_row_index: 3
          
          })
        }
      },
      error => {
        setFailureMessage((error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString());
      }
    );
  }, [] )


  const handleUpload= (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", file);
    formData.append("csv_configuration", JSON.stringify(csvConfiguration));

    setLoading(true);

    // You can write the URL of your server or any other endpoint used for file upload
    userEntriesService.uploadCSV(formData).then(
      response => {
        setLoading(false);
        setSuccessMessage(response.data.message)
      }, 
      error => {
        setLoading(false);
        setFailureMessage((error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString())
      }
    )
  } 

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };


  const setFailureMessage = (message) => {
    setAlert(alert => ({...alert, 
      show: true,
      alertType: 'danger',
      alertTitle: 'Error',
      alertMessage: message
    }));
  }

  const setSuccessMessage = (message) => {
    setAlert(alert => ({...alert, 
      show: true,
      alertType: 'success',
      alertTitle: 'Success',
      alertMessage: message
    }));
  };
  
  return (
    <>
      {loading && (
        <span className="spinner-border spinner-border-sm"></span>
      )}
      <Alert variant={alert.alertType} title={alert.alertTitle} text={alert.alertMessage} position="top-end" initialShow={alert.show} />
      <div className="mb-3">
        <label htmlFor="groupName" className="form-label">
          <h3>CSV File</h3>
        </label>
        </div>
        <div className="mb-3">
        <div className="input-group">
            <span className="input-group-text">Marker Header #</span>
            <input
              type="text"
              className="form-control"
              name="usage_type_row_index"
              onChange={(e) => setCsvConfiguration({...csvConfiguration, usage_type_row_index: e.target.value})}
              value={csvConfiguration.usage_type_row_index}
            />
            <span className="input-group-text">Marker Type Header #</span>
            <input
              type="text"
              className="form-control"
              name="measurement_unit_type_mame_row_index"
              onChange={(e) => setCsvConfiguration({...csvConfiguration, measurement_unit_type_mame_row_index: e.target.value})}
              value={csvConfiguration.measurement_unit_type_mame_row_index}
            />
            <span className="input-group-text">Category Header #</span>
            <input
              type="text"
              className="form-control"
              name="category_row_index"
              onChange={(e) => setCsvConfiguration({...csvConfiguration, category_row_index: e.target.value})}
              value={csvConfiguration.category_row_index}
            />
            <span className="input-group-text">Marker Boundary Header #</span>
            <input
              type="text"
              className="form-control"
              name="group_boundry_row_index"
              onChange={(e) => setCsvConfiguration({...csvConfiguration, group_boundry_row_index: e.target.value})}
              value={csvConfiguration.group_boundry_row_index}
            />
        </div>
      </div>
      <div>
        <input id="file" type="file" onChange={handleFileChange} />
      </div>
      {file && (
        <div className="card">
          <div className="card-body">
          <h5 className="card-title">File details:</h5>
            <p className="card-text">
              <ul>
                <li>Name: {file.name}</li>
                <li>Type: {file.type}</li>
                <li>Size: {file.size} bytes</li>
              </ul>
            </p>
          </div>
        </div>
      )}

      {file && <button onClick={handleUpload}>Upload a file</button>}
        
    </>
  )
}