import { useState, useEffect } from 'react';

import UserService from "../services/user.service";
import applicationsService from "../services/application.service";
import { Link } from "react-router-dom";

export default function ApplicationsPage() {
  const [failureMessage, setFailureMessage] = useState('');
  const [userApplications, setUserApplications] = useState([])
  const [allApplications, setAllApplications] = useState([])
  const [successMessage, setSuccessMessage] = useState("")
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    Promise.all([
      UserService.getApplications(),
      applicationsService.getAll()
    ]).then(
      responses => {
        setUserApplications(responses[0].data.applications);
        setAllApplications(responses[1].data.applications)
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
    )}, [])

  const removeApp = (appName) => {
    setFailureMessage("")
    setSuccessMessage("")
    setLoading(true);
    UserService.removeApplication(appName).then(
      response => {
        setUserApplications(response.data.applications)
        setLoading(false);
        setSuccessMessage(response.successMessage);
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
        setLoading(false);
      }
    )
  }

  const addApp = (appName) => {
    setFailureMessage("")
    setSuccessMessage("")
    setLoading(true);
    UserService.addApplications(appName).then(
      response => {
        setUserApplications(response.data.applications)
        setLoading(false);
        setSuccessMessage(response.message)
      },
      error => {
        setFailureMessage(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        );
        setLoading(false);
      }
    )
  }
  return (
    <>
      {failureMessage && <div className="alert alert-danger" role="alert">{failureMessage}</div>}
      {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
      <div className="container">
        <div className="row">
          <div className="col-sm-2">Application Name</div>
          <div className="col-sm-2">Published?</div>
          <div className="col-sm-2">Installed?</div>
          <div className="col-sm-2">Publisher</div>
        </div>
        {allApplications && userApplications && allApplications.map((application) => {
          const installed = !!(userApplications.find((userApplication) => userApplication.name === application.name))
        return (
        <div className="row">
          <div className="col-sm-2"><Link to={`/applications/${application.id}`}>
            {application.name}
          </Link>
          </div>
          <div className="col-sm-1">
            {application.published ? 'Yes' : 'No'}
          </div>
          <div className="col-sm-2">{ installed ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-check" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
  <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
  <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"/>
</svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-x" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M6.146 6.146a.5.5 0 0 1 .708 0L8 7.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 8l1.147 1.146a.5.5 0 0 1-.708.708L8 8.707 6.854 9.854a.5.5 0 0 1-.708-.708L7.293 8 6.146 6.854a.5.5 0 0 1 0-.708"/>
  <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
  <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"/>
</svg>}
          </div>
          <div className="col">
          {application.publisher && 
          (
            <Link to={`/users/${application.publisher.username}`}>{application.publisher.username}</Link>
          )
          }
          </div>
          <div className="col">
            {installed ? 
              <button type="button" key={`add-button-${application.name}`} className="btn btn-primary" onClick={() => {removeApp(application.name)}}>
                Remove {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
              </button> :
              <button type="button" key={`remove-button-${application.name}`} className="btn btn-primary" onClick={() => {addApp(application.name)}}>
                Add {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
              </button>}
             
          </div>
        </div>
        )
      })}
      </div>
    </>
  )
}