import api from '../interceptors/auth.interceptor';

class ApplicationService {
  getAll() {
    return api.get(process.env.REACT_APP_API_URL + '/api/applications');
  }

  get(applicationId) {
    return api.get(process.env.REACT_APP_API_URL + `/api/applications/${applicationId}`);
  }

  create(name, rules) {
    return api.post(process.env.REACT_APP_API_URL + '/api/applications', 
    { 
      name: name,
      rules: rules
    });
  }


}

const applicationService = new ApplicationService();
export default applicationService;